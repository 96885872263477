import React from 'react';
import {ChartInput} from 'components/forecast/forecastCharts/chart-input-selector';
import {formatValue} from 'lib/value-formatter';
import {DataGridPremium, GridValueFormatterParams} from '@mui/x-data-grid-premium';

export function AppTableChart(props: { input: ChartInput }) {
  const tableData = props.input.tableData;

  const isPrintView = props.input.isPrintView;

  const valueFormatter = (params: GridValueFormatterParams<number>) => {
    if (params.value == null) {
      return '0';
    }
    return formatValue(params.value);
  };
  const cols = [];
  for (let c of tableData.columns) {
    const copy = {...c};
    if (c.field !== tableData.gridRowId)
      copy.valueFormatter = valueFormatter;
    cols.push(copy);
  }

  const sx: any = {
    width: '100%',
  };
  if (isPrintView)
    sx['height'] = '500px';

  return <DataGridPremium
    sx={ {
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
      },
    } }
    columns={ cols }
    rows={ tableData.rows }
    pagination={ false }
    hideFooter={ true }
    disableColumnReorder={ true }
    disableColumnPinning={ true }
    disableRowGrouping={ true }
    getRowId={ (row) => row[tableData.gridRowId] }
  />;
}

export default AppTableChart;
