import React from 'react';
import * as s from './summary-area-styles';
import {ForecastChartsInput} from 'components/forecast/forecastCharts/chart-input-selector';
import {mapChartColor} from 'components/forecast/forecastCharts/charts-color-mapper';

export function SummaryArea(props: { input: ForecastChartsInput }) {
  const input = props.input;
  if (!input?.metrics || input?.isCalcPending)
    return <></>;
  return <s.SummaryAreaContainer>
    {
      input?.metrics?.map((m, n) =>
        <s.SummaryMetric key={ n }>
          <s.MetricValue color={ mapChartColor(m.color) }>
            { m.value }
          </s.MetricValue>
          <s.MetricName>
            { m.name }
          </s.MetricName>
        </s.SummaryMetric>,
      )
    }
  </s.SummaryAreaContainer>;
}

export default SummaryArea;