import React, {ChangeEventHandler, KeyboardEventHandler} from 'react';
import {InputAdornment, TextField} from '@mui/material';
import styled from 'styled-components';

export interface CustomInputProps {
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined> | null;
  Icon?: any,
  placeholder?: string,
  onChange: ChangeEventHandler<HTMLElement>,
  value?: string,
  label?: string,
  type?: string,
  error?: boolean,
  children?: any,
  select?: boolean,
  disabled?: boolean,
  autoFocus?: boolean,
  size?: 'small' | 'medium' | undefined,
  iconPosition?: 'start' | 'end',
  onKeyUp?: KeyboardEventHandler<HTMLElement>,
  multiline?: boolean,
  rows?: number,
}

const CustomInput = (props: CustomInputProps) => {
  return (
    <TextFieldThemed
      value={ props.value }
      label={ props.label || '' }
      error={ props.error }
      size={ props.size || 'small' }
      type={ props.type || 'text' }
      select={ props.select ?? false }
      variant="outlined"
      placeholder={ props.placeholder }
      disabled={ props.disabled }
      multiline={ props.multiline ?? false }
      InputProps={ {
        startAdornment: (
          <InputAdornment position={ props.iconPosition || 'start' }>
            <CredentialIconWrapper>
              { props.Icon }
            </CredentialIconWrapper>
          </InputAdornment>
        ),
        inputRef: props.inputRef,
      } }
      onChange={ props.onChange }
      onKeyUp={ props.onKeyUp }
      rows={ props.rows ?? '' }
    >
      { props.children && props.children }
    </TextFieldThemed>
  );
};

export default CustomInput;

const TextFieldThemed = styled(TextField)`
  width: 100%;
  & input {
    font-size: 14px;
  }
  & .MuiSelect-root {
    font-size: 14px;
  }
  & label.Mui-focused {
    color: ${ props =>
  props.error ? 'var(--color-theme-error)' : 'var(--color-theme-primary)' };
  }
  & .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline {
    border-color: ${ props =>
  props.error ? 'var(--color-theme-error)' : 'var(--color-theme-primary)' };
  }
  & .MuiInput-underline {
    &:after {
      border-bottom-color: ${ props =>
  props.error
    ? 'var(--color-theme-error)'
    : 'var(--color-theme-primary)' };
    }
`;

const CredentialIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  & svg {
    font-size: 18px;
  }
`;