export const getTenantForecastsRoute = (tenantId: string) => `/app/tenants/${ tenantId }/forecast-list`;
export const getForecastRecycleBinRoute = (tenantId: string) => `/app/tenants/${ tenantId }/forecast-recycle`;
export const getForecastRoute = (tenantId: string, forecastId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }`;
export const getForecastChartsRoute = (tenantId: string, forecastId: string, scenarioId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/charts/${ scenarioId }`;
export const getForecastBudgetExplorerRoute = (tenantId: string, forecastId: string, scenarioId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/budget-explorer/${ scenarioId }`;
export const getForecastTransactionsRoute = (tenantId: string, forecastId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/transactions`;
export const getForecastBudgetBuilder = (tenantId: string, forecastId: string, scenarioId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/budget-builder/${ scenarioId }`;
export const getForecastReports = (tenantId: string, forecastId: string, scenarioId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/reports/${ scenarioId }`;
export const getTenantsRoute = () => `/app/tenants`;
export const getForecastSettingsRoute = (tenantId: string, forecastId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/settings`;
export const getForecastWelcomeRoute = (tenantId: string, forecastId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/welcome`;
export const getForecastValidationRoute = (tenantId: string, forecastId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/validation`;

export const getForecastDataRoute = (tenantId: string, forecastId: string) => `/app/tenants/${ tenantId }/forecasts/${ forecastId }/data`;
export const getDataChartOfAccounts = (tenantId: string, forecastId: string) => `${ getForecastDataRoute(tenantId, forecastId) }/chart-of-accounts`;
export const getDataAccountDetail = (tenantId: string, forecastId: string) => `${ getForecastDataRoute(tenantId, forecastId) }/account-detail`;
export const getDataBudgets = (tenantId: string, forecastId: string) => `${ getForecastDataRoute(tenantId, forecastId) }/budgets`;
export const getDataVendorList = (tenantId: string, forecastId: string) => `${ getForecastDataRoute(tenantId, forecastId) }/vendor-list`;
export const getDataFundBalances = (tenantId: string, forecastId: string) => `${ getForecastDataRoute(tenantId, forecastId) }/fund-balances`;
export const getDataFundList = (tenantId: string, forecastId: string) => `${ getForecastDataRoute(tenantId, forecastId) }/fund-list`;
