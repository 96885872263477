import {useAppSelector} from 'app/hooks';
import AppButton from 'components/appButton/AppButton';
import React from 'react';
import * as s from './year-selector-styles';

function formatYear(y: number): string {
  return 'FY' + y.toString().slice(2);
}

export const YearSelectorBar = (props: {
  year: number | undefined,
  setYear: (y: number | undefined) => void,
  years: number[]
}) => {
  const forecast = useAppSelector(s => s.ui.activeForecast);
  const scenario = useAppSelector(s => s.ui.activeScenario);
  const {year, setYear, years} = props;

  if (!forecast || !scenario)
    return <></>;

  const selectYear = (year: number) => setYear(year);

  return <s.YearSelectorBar>
    {
      years.map(y =>
        <s.YearSelectorItem key={ y } active={ year === y }>
          <AppButton text={ formatYear(y) } onClick={ () => selectYear(y) }/>
        </s.YearSelectorItem>,
      )
    }
  </s.YearSelectorBar>;
};