import React from 'react';
import {Route, Routes, useParams} from 'react-router-dom';
import styled from 'styled-components';
import ForecastDataHeader from './forecastDataHeader/ForecastDataHeader';
import ChartOfAccountsDataTab from './dataTabs/ChartOfAccountsDataTab';
import FundListAccountDataTab from './dataTabs/FundListDataTab';
import AccountDetailDataTab from './dataTabs/AccountDetailDataTab';
import FundBalancesDataTab from './dataTabs/FundBalancesDataTab';
import BudgetsDataTab from './dataTabs/BudgetsDataTab';
import VendorListDataTab from './dataTabs/VendorListDataTab';

export function ForecastData() {
  const params = useParams();
  const forecastId = params.forecastId;
  const tenantId = params.tenantId;
  if (!forecastId || !tenantId)
    return <></>;

  return <Container>
    <Routes>
      <Route path="*" element={ <ForecastDataHeader forecastId={ forecastId } tenantId={ tenantId }/> }/>
    </Routes>
    <Routes>
      <Route path="chart-of-accounts" element={ <ChartOfAccountsDataTab/> }/>
      { false && <Route path="fund-list" element={ <FundListAccountDataTab/> }/> }
      <Route path="account-detail" element={ <AccountDetailDataTab/> }/>
      <Route path="fund-balances" element={ <FundBalancesDataTab/> }/>
      <Route path="budgets" element={ <BudgetsDataTab/> }/>
      { false && <Route path="vendor-list" element={ <VendorListDataTab/> }/> }
    </Routes>
  </Container>;
}

const Container = styled.div`
  display:flex;
  flex-direction: column;
  padding: 10px 30px;
  margin: 0 auto;
  width: 1100px;
`;

export default ForecastData;
