import React from 'react';
import styled from 'styled-components';
import appConfig from 'lib/config';
import Button from 'components/appButton/AppButton';
import * as icons from 'style/Icons';

function HomeButton() {
  const homeUrl = appConfig.homeUrl;
  return (
    <HomeButtonContainer>
      <a href={ homeUrl }>
        <Button icon={ <icons.Home/> }/>
      </a>
    </HomeButtonContainer>
  );
}

export default HomeButton;

const HomeButtonContainer = styled.div`
`;
