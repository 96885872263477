import {DateTime} from 'luxon';

export const getCurrentYear = () => {
  const dt = DateTime.now();
  return dt.year;
};

export const getEpocSeconds = () => {
  return DateTime.now().toSeconds();
};

export const getLocalDateAndTime = () => {
  const dt = DateTime.now();
  return dt.toLocaleString(DateTime.DATETIME_FULL);
};

export const validateDate = (src: string): boolean => {
  const dt = _parseDateWithSlashes(src);
  return dt.isValid;
};

const _parseDateWithSlashes = (src: string): DateTime => {
  return DateTime.fromFormat(src, 'M/d/yyyy');
};

const _parseDateWithDashes = (src: string): DateTime => {
  return DateTime.fromFormat(src, 'MM-dd-yyyy');
};

export const format = (src: string): string => {
  const dt = _parseDateWithSlashes(src);
  if (!dt.isValid)
    throw new Error('Invalid source format trying to format date.');
  return dt.toFormat('MM-dd-yyyy');
};

export const getFyYearYY = (date: string): string => {
  const dt = _parseDateWithDashes(date);
  if (dt.day === 1 && dt.month === 1)
    return dt.toFormat('yy');
  const nextYear = dt.year + 1;
  return nextYear.toString().substring(2, 4);
};
