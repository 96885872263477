import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Menu, MenuItem} from '@mui/material';
import * as s from './add-filter-drop-down-styles';
import {ForecastChartsInput} from 'components/forecast/forecastCharts/chart-input-selector';
import {ChartFilterState, filterAdded} from 'storeSlices/charts-slice';
import AppButton from 'components/appButton/AppButton';
import * as icons from 'style/Icons';

export function AddFilterDropDown(props: { input: ForecastChartsInput }) {
  const dispatch = useDispatch();
  const input = props.input;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);

  const handleIconClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleAddFilterClick = (f: ChartFilterState) => {
    setAnchorEl(null);
    dispatch(filterAdded(f.dimension.id));
  };

  const filters = input?.mainChartFilters.filters.filter(x => !x.added);
  return <s.AddFilterContainer>
    <AppButton
      icon={ <icons.Filter/> }
      onClick={ handleIconClick }
      text="add filter"
      disabled={ filters.length === 0 || input?.isCalcPending }
    />
    <Menu
      id="basic-menu"
      anchorEl={ anchorEl }
      open={ showMenu }
      onClose={ handleMenuClose }
      MenuListProps={ {
        'aria-labelledby': 'basic-appButton',
      } }
    >
      {
        !!filters && filters.map(f => (
          <MenuItem key={ f.dimension.id } value={ f.dimension.id }
                    onClick={ () => handleAddFilterClick(f) }>{ f.dimension.name }</MenuItem>
        ))
      }
    </Menu>
  </s.AddFilterContainer>;
}

export default AddFilterDropDown;
