import {configureStore} from '@reduxjs/toolkit';
import reduxReporter from './lib/redux-reporter';
import profileSlice from './storeSlices/profile-slice';
import uiSlice from 'storeSlices/ui-slice';
import chartsSlice from 'storeSlices/charts-slice';
import {apiSlice} from 'apiSlices/forecasting-api-slice';
import {listenerMiddleware} from './app/listenerMiddleware';

export const store = configureStore({
  reducer: {
    profile: profileSlice,
    ui: uiSlice,
    charts: chartsSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false})
      .prepend(listenerMiddleware.middleware)
      .concat(reduxReporter)
      .concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>
