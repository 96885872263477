import React, {useEffect, useRef, useState} from 'react';
import * as s from './budget-builder-styles';
import * as cs from 'components/forecast/forecast-common-styles';
import StepsList from './stepsList/StepsList';
import BaseBudgetSelector from './BaseBudgetSelector';
import {useAppSelector} from 'app/hooks';
import {YearSelectorBar} from './yearSelector/YearSelectorBar';
import {FilterBar} from './filterBar/FilterBar';
import {BudgetGrid} from './budgetGrid/BudgetGrid';
import ErrorDialog from 'components/ErrorDialog';
import {CommandErrorResult} from 'lib/command-client';
import ModalSpinner from 'components/modalSpinner/ModalSpinner';
import {Forecast} from 'apiSlices/api-types';

function makeYears(forecast: Forecast | undefined): number[] {
  if (!forecast)
    return [];
  const firstYear = forecast.firstFiscalYear;
  const years = Array.from(
    {length: forecast.numForecastYears},
    (_, i) => firstYear + i);
  return years;
}

export const BudgetBuilder = () => {
  const forecastId = useAppSelector(s => s.ui.activeForecastId);
  const forecast = useAppSelector(s => s.ui.activeForecast);
  const scenario = useAppSelector(s => s.ui.activeScenario);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedStep, setSelectedStep] = useState<string | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | undefined>(undefined);

  const stepList = useRef<string[]>([]);
  const steps = scenario?.steps;

  stepList.current.splice(0, Infinity, ...((steps?.map(m => m.id) ?? []).sort()));

  useEffect(() => {
    const stepIds = stepList.current;
    const stepToSelect = stepIds.length > 0 ? stepIds[0] : undefined;
    setSelectedStep(stepToSelect);
  }, [forecastId, scenario?.id]);

  const yearList = useRef<number[]>([]);
  yearList.current.splice(0, Infinity, ...(makeYears(forecast)));

  useEffect(() => {
    const years = yearList.current;
    const yearToSelect = years.length > 0 ? years[0] : undefined;
    setSelectedYear(yearToSelect);
  }, [forecastId, scenario?.id]);

  const selectStep = (step: string | undefined) => setSelectedStep(step);
  const selectYear = (year: number | undefined) => setSelectedYear(year);

  if (!forecastId || !scenario)
    return <></>;

  return <s.BudgetBuilderContainer>
    <s.TitleSection>
      <s.TitleSectionLeft>
        <cs.PageTitle>
          Budget Builder
        </cs.PageTitle>
      </s.TitleSectionLeft>
      <s.TitleSectionRight>
      </s.TitleSectionRight>
    </s.TitleSection>
    <s.MainArea>
      <s.LeftMainArea>
        <BaseBudgetSelector/>
        <StepsList step={ selectedStep }
                   setStep={ selectStep }
        />
      </s.LeftMainArea>
      <s.RightMainArea>
        <YearSelectorBar year={ selectedYear }
                         setYear={ selectYear }
                         years={ yearList.current }
        />
        <FilterBar stepId={ selectedStep }
                   saving={ saving }
                   setSaving={ setSaving }
                   setErrorDialogState={ setErrorDialogState }
        />
        <BudgetGrid stepId={ selectedStep }
                    year={ selectedYear }
        />
        {
          (!!errorDialogState) &&
          <ErrorDialog dialogState={ errorDialogState }
                       open={ !!errorDialogState }
                       onClose={ () => setErrorDialogState(undefined) }/>
        }
        {
          (saving) &&
          <ModalSpinner show={ saving } message="Saving your changes..."/>
        }
      </s.RightMainArea>
    </s.MainArea>
  </s.BudgetBuilderContainer>;
};

export default BudgetBuilder;
