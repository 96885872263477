import React, {MouseEventHandler, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import {styled, ThemeProvider} from '@mui/material/styles';
import {colors} from 'style/colors';
import {fontFamily} from 'style/vars';
import {muiTheme} from 'style/mui-theme';
import * as icons from 'style/Icons';

const StyledLoadingButton = styled(LoadingButton)({
  textTransform: 'none',
  fontFamily: fontFamily,
});

const StyledTextButton = styled(Button)({
  textTransform: 'none',
  fontFamily: fontFamily,
});

const StyledButton = styled(Button)({
  textTransform: 'none',
  color: colors.secondary,
  fontFamily: fontFamily,
  textAlign: 'left',
});

const StyledIconButton = styled(IconButton)({
  textTransform: 'none',
  fontFamily: fontFamily,
});

const HighlightedIconButton = styled(Button)({
  textTransform: 'none',
  fontFamily: fontFamily,
  color: colors.themePrimary,
  fontWeight: 600,
});

export interface AppButtonProps {
  text?: string,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  linkTo?: string,
  icon?: ReactNode,
  size?: 'small' | 'medium' | 'large',
  variant?:
    'outlinedInfo'
    | 'filledRed'
    | 'outlinedPrimary'
    | 'outlinedSecondary'
    | 'disabledSpinner'
    | 'disabledOutline'
    | 'filledPrimary'
    | 'filledSecondary'
    | null,
  disabled?: boolean,
  highlighted?: boolean,
}

const AppButton = (props: AppButtonProps) => {
  const filledRed = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledTextButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        variant="contained"
        color="error"
        startIcon={ props.icon }
        disabled={ props.disabled }
      >
        { props.text }
      </StyledTextButton>
    </ThemeProvider>;
  const outlinedPrimary = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledTextButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        variant="outlined"
        color="primary"
        disabled={ props.disabled }
      >
        { props.text }
      </StyledTextButton>
    </ThemeProvider>;
  const outlinedSecondary = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledTextButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        variant="outlined"
        color="secondary"
        disabled={ props.disabled }
      >
        { props.text }
      </StyledTextButton>
    </ThemeProvider>;
  const outlinedInfo = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledTextButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        variant="outlined"
        color="info"
        disabled={ props.disabled }
      >
        { props.text }
      </StyledTextButton>
    </ThemeProvider>;
  const filledPrimary = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledTextButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        color="primary"
        variant="contained"
        disabled={ props.disabled }
        startIcon={ props.icon }
      >
        { props.text }
      </StyledTextButton>
    </ThemeProvider>;
  const filledSecondary = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledTextButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        color="secondary"
        variant="contained"
        disabled={ props.disabled }
      >
        { props.text }
      </StyledTextButton>
    </ThemeProvider>;
  const disabledSpinner = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledLoadingButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        variant="outlined"
        color="info"
        loadingPosition="start"
        loading
        startIcon={ <icons.Add/> }
        disabled={ props.disabled }
      >
        { props.text }
      </StyledLoadingButton>
    </ThemeProvider>;
  const disabledOutline = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledTextButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        variant="outlined"
        color="info"
        disabled
      >
        { props.text }
      </StyledTextButton>
    </ThemeProvider>;
  const highlightedIconTextButt = () =>
    <HighlightedIconButton
      size={ props.size ?? 'medium' }
      onClick={ props.onClick }
      disabled={ props.disabled }
    >
      { props.text }
    </HighlightedIconButton>;
  const iconTextButt = () =>
    <StyledButton
      size={ props.size ?? 'medium' }
      onClick={ props.onClick }
      startIcon={ props.icon }
      disabled={ props.disabled }
    >
      { props.text }
    </StyledButton>;
  const iconButt = () =>
    <ThemeProvider theme={ muiTheme }>
      <StyledIconButton
        size={ props.size ?? 'medium' }
        onClick={ props.onClick }
        disabled={ props.disabled }
        color={ props.highlighted ? 'primary' : 'secondary' }
      >
        { props.icon }
      </StyledIconButton>
    </ThemeProvider>;

  const butt = () => {
    if (props.variant === 'disabledSpinner')
      return disabledSpinner();
    if (props.variant === 'filledRed')
      return filledRed();
    if (props.variant === 'outlinedInfo')
      return outlinedInfo();
    if (props.variant === 'outlinedSecondary')
      return outlinedSecondary();
    if (props.variant === 'outlinedPrimary')
      return outlinedPrimary();
    if (props.variant === 'filledPrimary')
      return filledPrimary();
    if (props.variant === 'filledSecondary')
      return filledSecondary();
    if (props.variant === 'disabledOutline')
      return disabledOutline();
    if (!!props.text && props.highlighted)
      return highlightedIconTextButt();
    if (!!props.text)
      return iconTextButt();
    return iconButt();
  };

  if (!!props.linkTo)
    return <Link to={ props.linkTo }>{ butt() }</Link>;

  return butt();
};
export default AppButton;