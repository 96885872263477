import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {MenuItem} from '@mui/material';
import CustomInput from 'components/customInput/CustomInput';
import {mainChartSelectionChanged, selectMainChartId} from 'storeSlices/charts-slice';
import {ChartsList} from 'apiSlices/chart-types';

export function MainChartSelector(props: { chartsList: ChartsList }) {
  const dispatch = useDispatch();
  const chartsList = props.chartsList;
  const handleSelectionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(mainChartSelectionChanged(e.target.value));
  };
  const mainChartId = useSelector(selectMainChartId);
  const charts = chartsList.charts;

  if (!charts)
    return <></>;

  return <SelectorContainer>
    <CustomInput
      select={ true }
      label="Chart"
      value={ mainChartId }
      onChange={ handleSelectionChanged }
    >
      {
        charts.map(c => (
          <MenuItem key={ c.id } value={ c.id } onClick={ () => {
          } }>{ c.name }</MenuItem>
        ))
      }
    </CustomInput>
  </SelectorContainer>;
}

export default MainChartSelector;

const SelectorContainer = styled.div`
`;
