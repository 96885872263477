import {useAppSelector} from 'app/hooks';
import {Adjustment} from 'components/forecast/forecastBudgetBuilder/budget-builder-types';
import React, {useEffect, useRef, useState, KeyboardEvent} from 'react';
import {CommandErrorResult, usePostCommand} from 'lib/command-client';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import {fontSizes} from 'style/vars';
import AppButton from 'components/appButton/AppButton';
import {SetAdjustmentApiCommand} from 'apiCommands/api-commands';
import ErrorDialog from 'components/ErrorDialog';
import {isNumber} from 'lib/util';
import {AdjustmentType} from 'apiSlices/api-types';

interface ChangeAdjustmentDialogProps {
  stepId: string;
  year: number;
  account: string | undefined;
  existingAdj: Adjustment | undefined;

  open: boolean,
  onClose: (saved: boolean) => void,
}

export const ChangeAdjustmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 350px;
  padding: 15px;
`;

export const ChangeAdjustmentRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

export const ChangeAdjustmentSpace = styled.div`
  flex-grow: 1;
`;

export const ChangeAdjustmentButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: bottom;
  justify-content: space-around;
`;

export function ChangeAdjustmentDialog(props: ChangeAdjustmentDialogProps) {
  const {open, onClose, stepId, year, account, existingAdj} = props;

  const postCommand = usePostCommand();

  const [hasValue, setHasValue] = useState(false);
  const [type, setType] = useState<AdjustmentType>(0);
  const [value, setValue] = useState('0');
  const [saving, setSaving] = useState(false);

  const valueInputBox = useRef<HTMLInputElement>();

  const forecastId = useAppSelector(s => s.ui.activeForecastId);
  const scenarioId = useAppSelector(s => s.ui.activeScenarioId);

  const scale = (type: AdjustmentType) => {
    switch(type) {
      case AdjustmentType.Blank:
      case AdjustmentType.Zero:
      case AdjustmentType.DollarAmount:
      case AdjustmentType.DollarChange:
      case AdjustmentType.BaseDollarChange:
      case AdjustmentType.TempDollarAmount:
      case AdjustmentType.TempDollarChange:
        return 1.0;
      case AdjustmentType.PercentChange:
      case AdjustmentType.BasePercentChange:
      case AdjustmentType.TempPercentChange:
        return 100.0;
      default:
        throw Error("invalid adjustment type: " + type);
    }
  }

  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | undefined>(undefined);

  useEffect(() => {
    setTimeout(() => {
      if (existingAdj === undefined) {
        setHasValue(true);
        setType(AdjustmentType.PercentChange);
        setValue((0.0).toString());
      } else {
        setHasValue(true);
        setType(existingAdj.type);
        setValue(Math.round(1e14 * ((existingAdj.value ?? 0.0) * scale(existingAdj.type)) / 1e14).toString());
      }
      setTimeout(() => {
        const current = valueInputBox.current;
        if (current) {
          current.focus();
          current.select();
        }
      });
    }, 0);
  }, [forecastId, scenarioId, stepId, year, existingAdj, account]);

  function hasValueChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const newHasValue = e.target.value === 'true';
    setHasValue(newHasValue);
    if (newHasValue)
      setType(AdjustmentType.Zero);
  }

  function typeChanged(e: SelectChangeEvent<AdjustmentType>) {
    const newType = e.target.value as AdjustmentType;
    const oldType = type;
    setType(newType);
    if (scale(oldType) !== scale(newType)) {
      setValue((0).toString());
    }
  }

  function valueChanged(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  async function handleSaveClicked() {
    if (!enableSave || !forecastId || !scenarioId || !stepId)
      return;
    setSaving(true);
    const parsedValue = parseFloat(value);
    const cmd: SetAdjustmentApiCommand = {
      type: 'SetAdjustmentApiCommand',
      forecastId: forecastId,
      scenarioId: scenarioId,
      stepId: stepId,
      year: year,
      account: account,
      adjustmentType: hasValue ? type : AdjustmentType.Blank,
      adjustmentValue: hasValue ? parsedValue / scale(type) : undefined,
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      onClose(true);
    }
  }

  const enableSave = (!hasValue || (type === AdjustmentType.Zero || isNumber(value)));

  async function handleKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      await handleSaveClicked();
    } else if (event.key === 'Esc') {
      onClose(false);
    }
  }

  return <Dialog open={ open } onClose={ () => onClose(false) } onKeyDown={handleKeyDown}>
    <ChangeAdjustmentContainer>
      <div style={ {fontSize: fontSizes.l} }>
        { 'Change Adjustment (' + (account ?? ' all matching accounts ') + ')' }
      </div>
      <ChangeAdjustmentRow>
        <FormControl>
          <FormLabel>Use Adjustment?</FormLabel>
          <RadioGroup row={ true } value={ hasValue } onChange={ hasValueChanged }>
            <FormControlLabel value={ 'false' } control={ <Radio/> } label="Do not adjust"/>
            <FormControlLabel value={ 'true' } control={ <Radio/> } label="Use adjustment"/>
          </RadioGroup>
        </FormControl>
      </ChangeAdjustmentRow>
      <ChangeAdjustmentRow>
        {
          hasValue &&
          <FormControl fullWidth>
            <InputLabel id="select-label">Type of Adjustment</InputLabel>
            <Select value={ type } labelId="select-label" label="Type of Adjustment" onChange={ typeChanged }>
              <MenuItem value={ AdjustmentType.Zero }>Zero</MenuItem>
              <MenuItem value={ AdjustmentType.DollarAmount }>Dollar Amount</MenuItem>
              <MenuItem value={ AdjustmentType.DollarChange }>Dollar Change</MenuItem>
              <MenuItem value={ AdjustmentType.PercentChange }>Percent Change</MenuItem>
              <MenuItem value={ AdjustmentType.TempDollarAmount }>Dollar Amount (Temporary)</MenuItem>
              <MenuItem value={ AdjustmentType.TempDollarChange }>Dollar Change (Temporary)</MenuItem>
              <MenuItem value={ AdjustmentType.TempPercentChange }>Percent Change (Temporary)</MenuItem>
              <MenuItem value={ AdjustmentType.BasePercentChange }>Percent Change (Base Value)</MenuItem>
              <MenuItem value={ AdjustmentType.BaseDollarChange }>Dollar Change (Base Value)</MenuItem>
            </Select>
          </FormControl>
        }
      </ChangeAdjustmentRow>
      <ChangeAdjustmentRow>
        {
          hasValue && type !== AdjustmentType.Zero &&
          <FormControl fullWidth>
            <TextField inputRef={valueInputBox} label="Adjustment Value" inputProps={ {inputMode: 'numeric', pattern: '[0-9]*'} }
                       value={ value } onChange={ valueChanged }/>
          </FormControl>
        }
      </ChangeAdjustmentRow>
      <ChangeAdjustmentSpace/>
      <ChangeAdjustmentButtonBar>
        <AppButton text="Save" variant="filledPrimary" onClick={ handleSaveClicked } disabled={ !enableSave }/>
        <AppButton text="Cancel" variant="outlinedSecondary" onClick={ () => onClose(false) } disabled={ saving }/>
      </ChangeAdjustmentButtonBar>
    </ChangeAdjustmentContainer>
    {
      (!!errorDialogState) &&
      <ErrorDialog dialogState={ errorDialogState }
                   open={ !!errorDialogState }
                   onClose={ () => setErrorDialogState(undefined) }
      />
    }
  </Dialog>;
}