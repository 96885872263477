import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {colors} from 'style/colors';
import {Badge} from '@mui/material';

export interface SidebarButtonProps {
  text?: string,
  text2?: string,
  linkTo?: string,
  icon?: ReactNode,
  highlighted?: boolean,
  disabled?: boolean,
  errorCount?: number,
  warningCount?: number,
}

const SidebarButton = (props: SidebarButtonProps) => {
  if (props.disabled) {
    return <ContainerDisabled highlighted={ false } title={ 'Disabled' }>
      { props.icon }
      <ButtonTextDisabled>
        { props.text }
        {
          props.text2 && (
            <>
              <br/>{ props.text2 }
            </>
          )
        }
      </ButtonTextDisabled>
    </ContainerDisabled>;
  }
  return <Link to={ props.linkTo ?? '' }>
    <Container highlighted={ props.highlighted ?? false }>
      <Badge color={ props.errorCount ? 'error' : 'warning' }
             badgeContent={ props.errorCount ? props.errorCount : props.warningCount }
             invisible={ !props.errorCount && !props.warningCount } overlap="circular">
        { props.icon }
      </Badge>
      <ButtonText>
        { props.text }
        {
          props.text2 && (
            <>
              <br/>{ props.text2 }
            </>
          )
        }
      </ButtonText>
    </Container>
  </Link>;
};
const Container = styled.div<{ highlighted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: large;
  cursor: pointer;
  color: ${ p => p.highlighted ? colors.green : colors.secondary };
  &:hover {
    background: #ECF0F3;
  }
  width: 100%;
  padding: 4px 0px;
`;
const ButtonText = styled.div`
  font-size: 11px;
  color: var(--color-secondary);
  text-align: center;
  width: 100%;
`;
const ContainerDisabled = styled.div<{ highlighted: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: large;
  cursor: default;
  color: #c0c0c0;
  width: 100%;
  padding: 4px 0px;
`;
const ButtonTextDisabled = styled.div`
  font-size: 11px;
  color: #c0c0c0;
  text-align: center;
  width: 100%;
`;

export default SidebarButton;
