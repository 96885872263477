import React, {useState} from 'react';
import AppButton from 'components/appButton/AppButton';
import * as icons from 'style/Icons';
import {Menu, MenuItem} from '@mui/material';
import {AddFilterContainer} from './filter-styles';
import {StepFilters} from 'apiSlices/api-types';

export const AddFilterButton = (props: {
  addedFilters: string[],
  stepFilters: StepFilters,
  addFilter: (id: string) => void
}) => {
  const {addedFilters, stepFilters} = props;

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const showMenu = Boolean(anchorEl);

  const handleIconClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(undefined);
  };
  const handleAddFilterClick = (id: string) => {
    setAnchorEl(undefined);
    props.addFilter(id);
  };

  const added = (id: string) => addedFilters.find(x => x === id);
  const filters = stepFilters.filterIds.map((id, i) => ({
    id,
    name: stepFilters.filterNames[i],
    added: added(id),
  })).filter(x => !x.added);

  return <AddFilterContainer>
    <AppButton
      icon={ <icons.Filter/> }
      onClick={ handleIconClick }
      text="add filter"
      disabled={ filters.length === 0 }
    />
    <Menu
      id="basic-menu"
      anchorEl={ anchorEl }
      open={ showMenu }
      onClose={ handleMenuClose }
      MenuListProps={ {
        'aria-labelledby': 'basic-appButton',
        dense: true,
      } }
    >
      {
        !!filters && filters.map(f => (
          <MenuItem key={ f.id } value={ f.id }
                    onClick={ () => handleAddFilterClick(f.id) }>{ f.name }</MenuItem>
        ))
      }
    </Menu>
  </AddFilterContainer>;
};
