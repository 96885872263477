import styled from 'styled-components';

export const StepListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px solid var(--color-grey);
  border-radius: 4px;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 5px;
  font-weight: 600;
`;

export const TitleRowLeft = styled.div`
`;

export const Title = styled.div`
`;

export const TitleRowRight = styled.div`
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow:1;
  overflow-y: auto;
  height: calc(100vh - 247px)
`;

interface StepProps {
  active: boolean,
}

export const Step = styled.div<StepProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid var(--color-grey);
  font-size: small;
  background-color: ${ props => props.active ? 'lightgreen' : undefined }
`;
export const StepLeft = styled.div`
  flex-grow: 1;
  > button {
    width: 100%;
    justify-content: left;
  }
`;
export const StepRight = styled.div`
`;

