export interface Tenant {
  id: string,
  name: string,
}

export interface Forecast {
  id: string,
  tenantId: string,
  name: string,
  description: string,
  cyStartDate: string,
  activeDataFileIds: { [dataFileId: string]: string },
  scenarios: Array<Scenario>,
  updateSequence: number,
  firstFiscalYear: number,
  numForecastYears: number,
  lastUpdate: string,
}

export interface Scenario {
  id: string,
  name: string,
  firstFiscalYear: number,
  accountMap: string[],
  adjustmentTypeMap: AdjustmentType[],
  steps: Step[],
  baseBudgetName: string,
}

export const enum AdjustmentType {
  Blank = 0,
  Zero,
  PercentChange,
  DollarChange,
  DollarAmount,
  TempPercentChange,
  TempDollarChange,
  TempDollarAmount,
  BasePercentChange,
  BaseDollarChange,
}

export interface Step {
  id: string,
  name: string,
  order: number,
  filters?: Filter[],
  years: StepYear[]
}

export interface Filter {
  name: string,
  values: string[],
}

export interface StepYear {
  fiscalYearIndex: number,
  accountIndex: number[],
  adjTypeIdx: number[],
  amount: number[],
}

export interface AccountFilters {
  accounts: string[],
  accountValues: number[][],
  filterIds: string[],
  filterNames: string[],
  filterValues: string[][],
}

export interface StepFilters {
  filterIds: string[],
  filterNames: string[],
  filterValues: string[][],
  selectedFilterIds: string[],
  selectedFilterValues: string[][],
}

export interface DataFile {
  id: string,
  tenantId: string,
  forecastId: string,
  name: string,
  description: string,
  type: string,
  state: string,
  createdUnixSeconds: string,
}

export interface CommandStatus {
  commandId: string,
  status: 'Error' | 'Success' | 'Running' | 'Submitted',
  error: string,
  errorDescription: string,
}

export interface ForecastValidationResult {
  forecastId: string,
  validationId: string,
  tenantId: string,
  success: boolean,
  completionTimeUnixSeconds: number,
  validationMessages: ForecastValidationMessage[],
}

export interface ForecastValidationMessage {
  level: 'Info' | 'Error' | 'Warning',
  message: string,
  category?: string,
  detail?: string,
  rowNumber: number,
  table?: string[][],
}

export interface Calculation {
  id: string,
  forecastId: string,
  tenantId: string,
  state: 'Created' | 'Running' | 'Complete' | 'Error',
}

export interface BudgetBuilderWorkspaceDto {
  baseBudgetNames: string[],
}

export interface ForecastStatusFetched {
  forecastId: string,
  tenantId: string,
  updateSequence: number,
  lastCalculationId: string,
  lastValidationId: string,
  validationMessages: ForecastValidationMessage[],
  lastValidationUnixTime: number,
  lastCalculationState: string,
  forecastName: string,
}

export interface StepInput {
  stepFilters: Filter[],
  accounts: string[],
  objects: string[],
  funds: string[],
  departments: string[],
  divisions: string[],
  baseValues: number[],
  beforeValues: number[],
  afterValues: number[],
  nextValues: number[],
  adjustType: number[],
  adjustValues: number[],
  allAdjustType: number,
  allAdjustValue: number,
}

export interface WelcomeStatus {
  forecastSequence: number,
  needsChartOfAccounts: boolean,
  needsAccountDetail: boolean,
  needsFundList: boolean,
  needsFundBalances: boolean,
  needsBudgets: boolean,
  needsVendorList: boolean,
}

export interface ReportsAvailable {
  forecastSequence: number,
  detailedForecastReport: string | undefined,
  stepDetailReport: string | undefined,
}

export interface BudgetExplorerData {
  noData: boolean,
  forecastSequence: number,
  rowCount: number,
  dimensions: BudgetExplorerDimension[],
  measures: BudgetExplorerMeasure[],
}

export interface BudgetExplorerDimension {
  initiallyVisible: boolean,
  name: string,
  columnHeading: string,
  nominalWidth: number,
  valueMap: string[],
  valueIdx: number[],
}

export interface BudgetExplorerMeasure {
  initiallyVisible: boolean,
  name: string,
  columnHeading: string,
  values: number[],
}