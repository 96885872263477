import {AdjustmentType} from 'apiSlices/api-types';
import {invalidateTags} from 'apiCommands/tag-invalidation';

export interface CreateForecastApiCommand {
  type: 'CreateForecast',
  tenantId: string,
  name: string,
  description: string,
  cyStartDate: string,
}

invalidateTags<CreateForecastApiCommand>('CreateForecast',
  c => ({type: 'ForecastList', tenantId: c.tenantId}),
);

export interface DeleteForecastApiCommand {
  type: 'DeleteForecast',
  tenantId: string,
  forecastId: string,
}

invalidateTags<DeleteForecastApiCommand>('DeleteForecast', c => ({type: 'ForecastList', tenantId: c.tenantId}));

export interface DeleteAllForecastsFromRecycleBinApiCommand {
  type: 'DeleteAllForecastsFromRecycleBin',
  tenantId: string,
}

invalidateTags<DeleteAllForecastsFromRecycleBinApiCommand>('DeleteAllForecastsFromRecycleBin',
  c => ({type: 'ForecastList', tenantId: c.tenantId}));

export interface DeleteForecastFromRecycleBinApiCommand {
  type: 'DeleteForecastFromRecycleBin',
  tenantId: string,
  forecastId: string,
}

invalidateTags<DeleteForecastFromRecycleBinApiCommand>('DeleteForecastFromRecycleBin',
  c => ({type: 'ForecastList', tenantId: c.tenantId}));


export interface RestoreForecastFromRecycleBinApiCommand {
  type: 'RestoreForecastFromRecycleBin',
  tenantId: string,
  forecastId: string,
}

invalidateTags<RestoreForecastFromRecycleBinApiCommand>('RestoreForecastFromRecycleBin',
  c => ({type: 'ForecastList', tenantId: c.tenantId}));

export interface RenameForecastApiCommand {
  type: 'RenameForecast',
  tenantId: string,
  forecastId: string,
  name: string,
}

invalidateTags<RenameForecastApiCommand>('RenameForecast',
  c => ({type: 'ForecastList', tenantId: c.tenantId}),
  c => ({type: 'Forecast', forecastId: c.forecastId}));

export interface UpdateForecastDescriptionApiCommand {
  type: 'UpdateForecastDescription',
  tenantId: string,
  forecastId: string,
  description: string,
}

invalidateTags<UpdateForecastDescriptionApiCommand>('UpdateForecastDescription',
  c => ({type: 'ForecastList', tenantId: c.tenantId}),
  c => ({type: 'Forecast', forecastId: c.forecastId}));

export interface DuplicateForecastApiCommand {
  type: 'DuplicateForecast',
  tenantId: string,
  forecastId: string,
  name: string,
}

invalidateTags<DuplicateForecastApiCommand>('DuplicateForecast',
  c => ({type: 'ForecastList', tenantId: c.tenantId}));

export interface CopyForecastToTenantApiCommand {
  type: 'CopyForecastToTenant',
  sourceTenantId: string,
  sourceForecastId: string,
  destinationTenantId: string,
  name: string,
}

invalidateTags<CopyForecastToTenantApiCommand>('CopyForecastToTenant',
  c => ({type: 'ForecastList', tenantId: c.destinationTenantId}));

export interface ImportFileApiCommand {
  type: 'ImportFileApiCommand',
  tenantId: string,
  forecastId: string,
  importFileId: string,
  name: string,
  description: string,
  dataFileType: string,
  originalFilename: string,
}

invalidateTags<ImportFileApiCommand>('ImportFileApiCommand', 'Forecast', 'StepInput', 'StepFilters', 'Welcome');

export interface DeleteDataFileApiCommand {
  type: 'DeleteDataFileApiCommand',
  id: string,
  tenantId: string,
}

invalidateTags<DeleteDataFileApiCommand>('DeleteDataFileApiCommand', 'Forecast', 'StepInput', 'StepFilters', 'Welcome');

export interface ActivateDataFileApiCommand {
  type: 'ActivateDataFileApiCommand',
  dataFileId: string,
  tenantId: string,
  forecastId: string,
}

invalidateTags<ActivateDataFileApiCommand>('ActivateDataFileApiCommand', 'Forecast', 'StepInput', 'StepFilters', 'Welcome');

export interface StartCalculationApiCommand {
  type: 'StartCalculationApiCommand',
  tenantId: string,
  forecastId: string,
}

export interface UploadForecastApiCommand {
  type: 'UploadForecastApiCommand',
  tenantId: string,
  forecast: any,
}

invalidateTags<UploadForecastApiCommand>('UploadForecastApiCommand',
  c => ({type: 'ForecastList', tenantId: c.tenantId}),
);

export interface CreateScenarioApiCommand {
  type: 'CreateScenarioApiCommand',
  forecastId: string,
  name: string,
  baseBudgetName: string,
}

invalidateTags<CreateScenarioApiCommand>('CreateScenarioApiCommand', 'Forecast');

export interface RenameScenarioApiCommand {
  type: 'RenameScenarioApiCommand',
  forecastId: string,
  scenarioId: string,
  name: string,
}

invalidateTags<RenameScenarioApiCommand>('RenameScenarioApiCommand', 'Forecast');

export interface AddRemoveFilterApiCommand {
  type: 'AddRemoveFilterApiCommand',
  forecastId: string,
  scenarioId: string,
  stepId: string,
  addFilter: boolean,
  filterId: string,
}

invalidateTags<AddRemoveFilterApiCommand>('AddRemoveFilterApiCommand', 'Forecast', 'StepFilters', 'StepInput');

export interface ChangeFilterApiCommand {
  type: 'ChangeFilterApiCommand',
  forecastId: string,
  scenarioId: string,
  stepId: string,
  filterId: string,
  selectedValues: string[],
}

invalidateTags<ChangeFilterApiCommand>('ChangeFilterApiCommand', 'Forecast', 'StepFilters', 'StepInput');

export interface SetAdjustmentApiCommand {
  type: 'SetAdjustmentApiCommand',
  forecastId: string,
  scenarioId: string,
  stepId: string,
  year: number,
  account: string | undefined,
  adjustmentType?: AdjustmentType,
  adjustmentValue?: number,
}

invalidateTags<SetAdjustmentApiCommand>('SetAdjustmentApiCommand', 'Forecast', 'StepFilters', 'StepInput');

export interface DeleteScenarioApiCommand {
  type: 'DeleteScenarioApiCommand',
  forecastId: string,
  scenarioId: string,
}

invalidateTags<DeleteScenarioApiCommand>('DeleteScenarioApiCommand', 'Forecast', 'Welcome');

export interface CopyScenarioApiCommand {
  type: 'CopyScenarioApiCommand',
  forecastId: string,
  scenarioId: string,
  name: string,
}

invalidateTags<CopyScenarioApiCommand>('CopyScenarioApiCommand', 'Forecast');

export interface AddStepApiCommand {
  type: 'AddStepApiCommand',
  forecastId: string,
  scenarioId: string,
  name: string,
}

invalidateTags<AddStepApiCommand>('AddStepApiCommand', 'Forecast', 'StepFilters', 'StepInput');

export interface DeleteStepApiCommand {
  type: 'DeleteStepApiCommand',
  forecastId: string,
  scenarioId: string,
  stepId: string,
}

invalidateTags<DeleteStepApiCommand>('DeleteStepApiCommand', 'Forecast', 'StepFilters', 'StepInput');

export interface ReorderStepApiCommand {
  type: 'ReorderStepApiCommand',
  forecastId: string,
  scenarioId: string,
  stepId: string,
  order: number,
}

invalidateTags<ReorderStepApiCommand>('ReorderStepApiCommand', 'Forecast', 'StepFilters', 'StepInput');

export interface RenameStepApiCommand {
  type: 'RenameStepApiCommand',
  forecastId: string,
  scenarioId: string,
  stepId: string,
  name: string,
}

invalidateTags<RenameStepApiCommand>('RenameStepApiCommand', 'Forecast', 'StepFilters', 'StepInput');

export interface SelectScenarioBaseBudgetApiCommand {
  type: 'SelectScenarioBaseBudgetApiCommand',
  forecastId: string,
  scenarioId: string,
  baseBudgetName: string,
}

invalidateTags<SelectScenarioBaseBudgetApiCommand>('SelectScenarioBaseBudgetApiCommand', 'Forecast', 'StepFilters', 'StepInput');

export interface RequestReportApiCommand {
  type: 'RequestReportApiCommand',
  forecastId: string,
  scenarioId: string,
  forecastSequence: number,
  reportId: string,
}

invalidateTags<RequestReportApiCommand>('RequestReportApiCommand', 'ReportsAvailable');

export default function init() {
  // leave this here... this is used to force execution of the imperative code above...
}