import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import ErrorDialog from 'components/ErrorDialog';
import {validateName} from 'lib/validators';
import {fontSizes} from 'style/vars';
import CustomInput from 'components/customInput/CustomInput';
import {CommandErrorResult, IdPayload, pollForCommandStatus, postAsyncCommand} from 'lib/command-client';
import {useGetTenantsQuery} from 'apiSlices/forecasting-api-slice';
import {CopyForecastToTenantApiCommand} from 'apiCommands/api-commands';
import AppButton from 'components/appButton/AppButton';
import {MenuItem} from '@mui/material';
import {sortAlphabeticallyByField} from 'lib/util';
import * as routeBuilder from 'lib/route-builder';
import {Forecast, Tenant} from 'apiSlices/api-types';

export interface CopyForecastToTenantDialogProps {
  tenantId: string,
  forecast: Forecast | null,
  open: boolean,
  onClose: (event?: object, reason?: string) => void;
}

function CopyForecastToTenantDialog(props: CopyForecastToTenantDialogProps) {
  const {open, onClose, tenantId, forecast} = props;
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const [selectedDestinationTenantId, setSelectedDestinationTenantId] = useState('');
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | null>(null);
  const tenantsQuery = useGetTenantsQuery();
  const {data: tenants} = tenantsQuery;
  const navigate = useNavigate();

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleTenantSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDestinationTenantId(e.target.value);
  };

  const handleTenantSelectionClick = (t: Tenant) => {
    setSelectedDestinationTenantId(t.id);
  };

  const handleCopyClicked = async () => {
    if (!forecast)
      return;
    setSaving(true);
    const cmd: CopyForecastToTenantApiCommand = {
      type: 'CopyForecastToTenant',
      sourceTenantId: tenantId,
      sourceForecastId: forecast.id,
      name: name,
      destinationTenantId: selectedDestinationTenantId,
    };
    const result = await postAsyncCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      if (!result.commandId)
        throw new Error('Null command Id');
      const status = await pollForCommandStatus(result.commandId);
      if (status.status === 'Error' || !!status.error) {
        setSaving(false);
        setErrorDialogState({error: status.error, errorDescription: status.errorDescription});
        return;
      }

      setSaving(false);
      setName('');
      onClose();
      const idPayload = status.payload as IdPayload;
      const newForecastId = idPayload.id;
      navigate(routeBuilder.getForecastSettingsRoute(selectedDestinationTenantId, newForecastId));
    }
  };

  const handleCancelClicked = () => {
    setName('');
    onClose();
  };

  let nameValid = validateName(name);

  if (name === '') {
    nameValid = true; //done to handle the starting case
  }

  const enableSave = nameValid
    && name !== ''
    && selectedDestinationTenantId !== ''
    && !saving;

  if (!tenants) {
    console.log('tenants null');
    return <></>;
  }
  if (tenants.length === 0) {
    console.log('no tenants');
    return <></>;
  }
  const copiedTenants = tenants.filter(t => t.id !== tenantId);
  const sortedTenants = sortAlphabeticallyByField(copiedTenants, 'name') as Tenant[];

  return (
    <Dialog open={ open } onClose={ onClose }>
      <div style={ {padding: '15px'} }>
        <div style={ {padding: '10px', fontSize: fontSizes.l, width: '300px', marginRight: '600px'} }>Copy Forecast to
          Tenant
        </div>

        <div style={ {padding: '15px'} }>
          <CustomInput
            value={ name }
            label="New Forecast Name"
            error={ !nameValid }
            onChange={ handleNameChange }
            disabled={ saving }
          />
        </div>
        <div style={ {padding: '15px'} }>
          <CustomInput
            select={ true }
            label="Tenant"
            disabled={ saving }
            value={ selectedDestinationTenantId }
            onChange={ handleTenantSelection }
          >
            {
              !!sortedTenants && sortedTenants.map(t => (
                <MenuItem key={ t.id } value={ t.id }
                          onClick={ () => handleTenantSelectionClick(t) }>{ t.name }</MenuItem>
              ))
            }
          </CustomInput>
        </div>

        <div style={ {display: 'flex', flexDirection: 'row-reverse', gap: '20px', paddingRight: '15px'} }>
          <AppButton
            text="Copy to Tenant"
            variant="filledPrimary"
            onClick={ handleCopyClicked }
            disabled={ !enableSave }
          />
          <AppButton
            text="Cancel"
            variant="outlinedSecondary"
            onClick={ handleCancelClicked }
            disabled={ saving }
          />
        </div>
      </div>
      { (!!errorDialogState) && <ErrorDialog dialogState={ errorDialogState } open={ !!errorDialogState }
                                             onClose={ () => setErrorDialogState(null) }/> }
    </Dialog>
  );
}

export default CopyForecastToTenantDialog;