import React, {useEffect, useRef, useState} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {store} from 'store';
import App from './App';
import Auth from './lib/auth.js';
import Callback from './components/callback/callback';
import CssBaseline from '@mui/material/CssBaseline';
import LoginFailed from './components/loginFailed/LoginFailed';
import './style/base.css';
import AppInfo from './components/appInfo/AppInfo';
import TenantsList from './components/tenantsList/TenantsList';
import ForecastsList from './components/forecastsList/ForecastsList';
import Forecast from './components/forecast/Forecast';
import ForecastRecycleBin from './components/forecastRecycleBin/ForecastRecycleBin';

const notFound = () => <div>Page not found</div>;

const AppRoutes = () => {
  const [started, setStarted] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authorizedScope, setAuthorizedScope] = useState(false);
  const [authStartResult, setAuthStartResult]: any = useState(null);
  const startedRef = useRef(false);

  useEffect(() => {
    const setupAuth = async () => {
      if (window.location.pathname === '/callback') {
        const startResult = await new Auth().handleStart();
        console.log('setting auth start result');
        setAuthStartResult(startResult);
      } else {
        const a = new Auth();
        const loggedIn = await a.testCookieCredentials();
        setLoggedIn(loggedIn);
        const authorizedScope = a.hasAuthorizedScope();
        setAuthorizedScope(authorizedScope);
      }
      setStarted(true);
    };
    if (!startedRef.current) {
      startedRef.current = true;
      // noinspection JSIgnoredPromiseFromCall
      setupAuth();
    }
  }, [started]);

  const auth = new Auth();
  if (window.location.pathname === '/logout_callback') {
    // noinspection JSIgnoredPromiseFromCall
    auth.handleStart();
    return <Callback/>;
  }

  if (window.location.pathname === '/callback') {
    if (!authStartResult) {
      console.log('waiting for auth start result');
      return <Callback/>;
    }
    console.log('auth start result available');
    if (authStartResult.error) {
      return <LoginFailed error={ authStartResult.error } errorDescription={ authStartResult.errorDescription }/>;
    }
  }

  if (started && loggedIn && !authorizedScope) {
    console.log('No authorized scope');
    return <LoginFailed error="not_authorized"
                        errorDescription="You do not have access to this module.  Please contact GovInvest support or your local administrator."/>;
  }

  if (started && !loggedIn && window.location.pathname !== '/callback') {
    auth.initiateLogin();
    return <Callback/>;
  }

  if (!started) {
    console.log('Waiting to start');
    return <Callback/>;
  }

  const isCallback = window.location.pathname === '/callback';

  return (
    <Provider store={ store }>
      <React.Fragment>
        <CssBaseline/>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={ <Navigate to="/app/tenants" replace={ true }/> }/>
            { isCallback && (
              <Route path="/callback" element={ <Navigate to={ auth.getRequestedUrl() as string }/> }/>) }
            <Route path="/app" element={ <App auth={ auth }/> }>
              <Route index element={ <Navigate to="tenants" replace={ true }/> }/>
              <Route path="tenants/:tenantId/forecast-recycle" element={ <ForecastRecycleBin/> }/>
              <Route path="tenants/:tenantId/forecast-list" element={ <ForecastsList/> }/>
              <Route path="tenants/:tenantId/forecasts/:forecastId/*" element={ <Forecast/> }/>
              <Route path="tenants" element={ <TenantsList/> }/>
              <Route path="info" element={ <AppInfo/> }/>
            </Route>
            <Route path="*" element={ notFound() }/>
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    </Provider>
  );
};

export default AppRoutes;
