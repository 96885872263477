import React from 'react';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import {Outlet} from 'react-router-dom';
import Auth from 'lib/auth';

interface MainPageProps {
  auth: Auth;
}

function MainPage(props: MainPageProps) {
  return (
    <div id="main-page-container" style={{display:'block', position: 'absolute', height: '100vh', width: '100vw', padding:0, margin:0 }}>
      <div style={{display: 'flex', flexFlow: 'column', height:'100%' }}>
        <div>
          <Header auth={ props.auth }/>
        </div>
        <div style={{flexGrow: 1, overflowY: 'clip' }}>
          <Outlet/>
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default MainPage;