import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as s from './forecast-charts-styles';
import * as cs from 'components/forecast/forecast-common-styles';
import AppButton from 'components/appButton/AppButton';
import MainChart from './MainChart';
import SummaryArea from './summaryArea/SummaryArea';
import {
  chartDataUpdated,
  chartListUpdated,
  selectCharts,
  selectMainChartId,
  selectSummaryExpanded,
  summaryAreaExpandedToggled,
} from 'storeSlices/charts-slice';
import * as icons from 'style/Icons';
import {apiSlice, useGetChartDataQuery, useGetChartListQuery} from 'apiSlices/forecasting-api-slice';
import {buildChartInput} from './chart-input-selector';
import MainChartSelector from './MainChartSelector';
import ChartTypeSelector from './ChartTypeSelector';
import AddFilterDropDown from './addFilterDropDown/AddFilterDropDown';
import Filters from './filters/Filters';
import {showPrintView} from './print-helper';
import {useAppSelector} from 'app/hooks';
import {Badge, BadgeProps} from '@mui/material';
import {styled} from '@mui/material/styles';
import {selectCalculationInProgress, selectForecastName, selectLatestCalcId} from 'storeSlices/ui-slice';
import Spinner from 'components/Spinner';

interface RefreshButtonProps {
  refetch: () => void;
}

const RefreshButton = (props: RefreshButtonProps) => {
  const refetch = props.refetch;
  const chartCalcId = useAppSelector(s => s.charts.mainChart?.calcId);
  const lastCalcId = useSelector(selectLatestCalcId);
  const calcInProgress = useSelector(selectCalculationInProgress);
  const isLoading = useAppSelector(s => !s.ui.activeForecastStatus);

  const showBadge = !isLoading && !calcInProgress && (lastCalcId !== chartCalcId);

  const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
      right: 6,
      top: 18,
      border: `1px solid ${ theme.palette.background.paper }`,
      borderRadius: 8,
      padding: '0 4px',
      height: 10,
    },
  }));

  const icon =
    <StyledBadge color="primary" variant="dot" overlap="circular" invisible={ !showBadge }>
      <icons.Refresh/>
    </StyledBadge>;

  return <AppButton
    onClick={ refetch }
    size="small"
    disabled={ false }
    icon={ icon }
  />;
};

export const ForecastCharts = () => {
  const dispatch = useDispatch();
  const forecastId = useAppSelector(s => s.ui.activeForecastId);
  const scenarioId = useAppSelector(s => s.ui.activeScenarioId);

  const chartsListQuery = useGetChartListQuery(forecastId ?? '', {skip: forecastId === ''});
  const {data: chartsList} = chartsListQuery;

  const mainChartId = useSelector(selectMainChartId);

  const chartDataQueryOptions = {skip: !mainChartId};
  const getChartDataQuery = useGetChartDataQuery({
    forecastId: forecastId ?? '',
    chartId: mainChartId ?? '',
    scenarioId: scenarioId ?? '',
  }, chartDataQueryOptions);

  useEffect(() => {
    if (chartsListQuery.isSuccess)
      dispatch(chartListUpdated(chartsListQuery.data));
  }, [dispatch, chartsListQuery.isSuccess, chartsListQuery.data, forecastId]);

  useEffect(() => {
    if (getChartDataQuery.isSuccess)
      dispatch(chartDataUpdated(getChartDataQuery.data));
  }, [dispatch, getChartDataQuery.isSuccess, getChartDataQuery.data, forecastId, mainChartId, scenarioId]);

  const isSummaryExpanded = useSelector(selectSummaryExpanded);
  const hideShowSummaryIcon = isSummaryExpanded ? <icons.UpChevron/> : <icons.DownChevron/>;

  const charts = useSelector(selectCharts);

  const forecastName = useAppSelector(selectForecastName);
  const tenantName = useAppSelector(s => s.ui.activeTenant?.name);

  const forecastChartsInput = buildChartInput(charts, forecastName ?? '', tenantName ?? '');

  const handleHideShowSummaryClicked = () => {
    dispatch(summaryAreaExpandedToggled());
  };

  const handlePdfClick = async () => {
    await showPrintView(forecastChartsInput);
  };

  const refetch = () => {
    try {
      getChartDataQuery.refetch();
    } catch {
    }
    try {
      chartsListQuery.refetch();
    } catch {
    }
    dispatch(apiSlice.util.invalidateTags(['Forecast']));
  };

  if (!forecastChartsInput.chartsAvailable || !chartsList)
    return <Spinner retryInterval={ 1000 } retryAction={ refetch }/>;
  const isLoading = getChartDataQuery.isLoading || getChartDataQuery.isFetching || forecastChartsInput.isCalcPending;

  return <s.ForecastChartsContainer>
    <s.TitleSection>
      <s.TitleSectionLeft>
        <cs.PageTitle>Charts</cs.PageTitle>
      </s.TitleSectionLeft>
      <s.TitleSectionRight>
        <s.TitleButtonsContainer>
          <RefreshButton refetch={ refetch }/>
          { false && <AppButton
            icon={ hideShowSummaryIcon }
            onClick={ handleHideShowSummaryClicked }
            size="small"
            disabled={ false }
          /> }
        </s.TitleButtonsContainer>
      </s.TitleSectionRight>
    </s.TitleSection>
    <s.SummaryCharts visible={ isSummaryExpanded }>
      <SummaryArea input={ forecastChartsInput }/>
    </s.SummaryCharts>
    <s.ChartMiddleRow>
      <s.ChartMiddleRowLeft>
        <MainChartSelector chartsList={ chartsList }/>
        <AddFilterDropDown input={ forecastChartsInput }/>
        <Filters input={ forecastChartsInput }/>
      </s.ChartMiddleRowLeft>
      <s.ChartMiddleRowRight>
        <ChartTypeSelector input={ forecastChartsInput }/>
        <AppButton
          icon={ <icons.Pdf/> }
          onClick={ handlePdfClick }
          size="small"
          disabled={ isLoading }
        />
      </s.ChartMiddleRowRight>
    </s.ChartMiddleRow>
    <s.MainChart summaryVisible={ isSummaryExpanded }>
      { isLoading
        ? <Spinner retryInterval={ 1000 } retryAction={ refetch }/>
        : <MainChart input={ forecastChartsInput }/>
      }
    </s.MainChart>
  </s.ForecastChartsContainer>;
};
export default ForecastCharts;
