import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import ForecastData from './ForecastData';

export const ForecastDataIndex = () => {
  return <>
    <Routes>
      <Route index element={ <Navigate to="chart-of-accounts" replace={ true }/> }/>
    </Routes>
    <ForecastData/>
  </>;
};
export default ForecastDataIndex;
