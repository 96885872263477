import styled from 'styled-components';
import {colors} from 'style/colors';

export const SelectFileText = styled.div`
  padding-bottom: 5px;
`;

export const FileErrorDiv = styled.div`
  color: red;
  padding: 10px 0;
`;

export const SelectFileSection = styled.div`
  padding: 15px;
`;

export const ImportLogSection = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 800px;
  max-height: 45vh;
`;

export const ImportLogSectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImportLogSectionTitleLeft = styled.div`
`;

export const ImportLogSectionTitleRight = styled.div`
`;

export const ImportLogTextBox = styled.div`
  border: 0.5px solid lightgray;
  background-color: #eeeeee;
  height: 100%;
  overflow-y: scroll;
`;

export const ImportLogText = styled.div`
  padding: 5px;
  font-family: 'monospace';
  font-size: 10pt;
`;

export const ImportLogTextLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: 'monospace';
  font-size: 10pt;
`;

export const Level = styled.div<{ level: string }>`
  font-family: 'monospace';
  font-size: 10pt;
  width: 80px;
  color: ${ p => p.level === 'Error'
  ? colors.red
  : p.level === 'Warning'
    ? colors.orange
    : colors.black };
`;

export const Message = styled.div`
  font-family: 'monospace';
  font-size: 10pt;
`;
