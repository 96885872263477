import React from 'react';
import {useLocation} from 'react-router-dom';
import * as s from './sidebar-styles';
import * as icons from 'style/Icons';
import SidebarButton from './sidebarButton/SidebarButton';
import * as routeBuilder from 'lib/route-builder';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from 'react-redux';
import {useAppSelector} from 'app/hooks';
import {selectErrorCount, selectWarningCount} from 'storeSlices/ui-slice';

export const ForecastSidebar = () => {
  const location = useLocation();

  const forecastLoading = useAppSelector(s => s.ui.activeForecast === undefined || s.ui.activeForecastStatus === undefined);
  const errorCount = useSelector(selectErrorCount);
  const warningCount = useSelector(selectWarningCount);
  const hasErrors = errorCount > 0;
  const disableButtons = forecastLoading || hasErrors;

  const forecastId = useAppSelector(s => s.ui.activeForecastId);
  const tenantId = useAppSelector(s => s.ui.activeTenantId);
  const scenarioId = useAppSelector(s => s.ui.activeScenarioId);

  if (forecastLoading || !tenantId || !forecastId)
    return <></>;
  const pathname = location.pathname;

  return <s.SidebarContainer>
    <SidebarButton
      icon={ <icons.Tasks fontSize="large"/> }
      text="Welcome"
      highlighted={ pathname.includes('/welcome') }
      linkTo={ routeBuilder.getForecastWelcomeRoute(tenantId, forecastId) }
    />
    <SidebarButton
      icon={ <icons.Chart fontSize="large"/> }
      text="Charts"
      disabled={ disableButtons || !scenarioId }
      highlighted={ pathname.includes('/charts') }
      linkTo={ routeBuilder.getForecastChartsRoute(tenantId, forecastId, scenarioId || '') }
    />
    <SidebarButton
      icon={ <icons.Explore fontSize="large"/> }
      text="Budget"
      text2="Explorer"
      disabled={ disableButtons || !scenarioId }
      highlighted={ pathname.includes('/budget-explorer') }
      linkTo={ routeBuilder.getForecastBudgetExplorerRoute(tenantId, forecastId, scenarioId || '') }
    />
    <SidebarButton
      icon={ <icons.Report fontSize="large"/> }
      text="Reports"
      disabled={ disableButtons || !scenarioId }
      highlighted={ pathname.includes('/reports') }
      linkTo={ routeBuilder.getForecastReports(tenantId, forecastId, scenarioId || '') }
    />
    <SidebarButton
      icon={ <icons.Build fontSize="large"/> }
      text="Budget"
      text2="Builder"
      disabled={ !scenarioId }
      highlighted={ pathname.includes('/budget-builder') }
      linkTo={ routeBuilder.getForecastBudgetBuilder(tenantId, forecastId, scenarioId || '') }
    />
    <SidebarButton
      icon={ <FontAwesomeIcon icon="database" size="xl" style={ {padding: '2px'} }/> }
      text="Data"
      highlighted={ pathname.includes('/data') }
      linkTo={ routeBuilder.getForecastDataRoute(tenantId, forecastId) }
    />
    <SidebarButton
      icon={ <icons.Setting fontSize="large"/> }
      text="Settings"
      highlighted={ pathname.includes('/settings') }
      linkTo={ routeBuilder.getForecastSettingsRoute(tenantId, forecastId) }
    />
    <SidebarButton
      icon={ <icons.Error fontSize="large"/> }
      text="Validation"
      highlighted={ pathname.includes('/validation') }
      linkTo={ routeBuilder.getForecastValidationRoute(tenantId, forecastId) }
      errorCount={ errorCount }
      warningCount={ warningCount }
    />
  </s.SidebarContainer>;
};

export default ForecastSidebar;
