import React, {ChangeEvent, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorDialog from 'components/ErrorDialog';
import {validateName} from 'lib/validators';
import {fontSizes} from 'style/vars';
import CustomInput from 'components/customInput/CustomInput';
import {CommandErrorResult} from 'lib/command-client';
import AppButton from 'components/appButton/AppButton';
import {dataSizes} from 'lib/constants';
import {
  FileErrorDiv,
  ImportLogSection,
  ImportLogSectionTitle,
  ImportLogSectionTitleLeft,
  ImportLogSectionTitleRight,
  ImportLogText,
  ImportLogTextBox,
  ImportLogTextLine,
  Level,
  Message,
  SelectFileSection,
  SelectFileText,
} from './data-import-dialog-styles';
import {
  FileImportResult,
  FileImportValidationMessage,
  ImportWorkflow,
  ImportWorkflowParameters,
} from './ImportWorkflow';
import {colors} from 'style/colors';
import * as icons from 'style/Icons';
import {useDispatch} from 'react-redux';

export interface DataImportDialogProps {
  tenantId: string,
  forecastId: string,
  dataLabel: string,
  dataType: string,
  open: boolean,
  onClose: (event?: object, reason?: string) => void;
}

//todo: warnings (waiting to have a real warning)
function DataImportDialog(props: DataImportDialogProps) {
  const dispatch = useDispatch();
  const {open, onClose, tenantId, forecastId, dataLabel, dataType} = props;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | null>(null);
  const [importState, setImportState] = useState('not_started');
  const [importResult, setImportResult] = useState<FileImportResult | null>(null);

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleImportClicked = async () => {
    setImportState('started');
    if (!selectedFile) {
      console.log('selected file null');
      return;
    }

    const importParameters: ImportWorkflowParameters = {
      name: name,
      description: description,
      file: selectedFile,
      tenantId: tenantId,
      forecastId: forecastId,
      dataFileType: dataType,
    };

    const importWorkflow = new ImportWorkflow(importParameters);
    await importWorkflow.Execute(dispatch);
    setImportResult(importWorkflow.importResult);
    if (!importWorkflow.succeeded) {
      setErrorDialogState(importWorkflow.error);
      setImportState('error');
    } else {
      setImportState('completed');
    }
  };

  const handleCancelClicked = () => {
    setName('');
    onClose();
  };

  const handleCloseClicked = () => {
    setName('');
    onClose();
  };

  const handleFileSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files)
      return;
    if (files.length === 0)
      return;
    if (!files[0])
      return;
    const file = files[0];
    if (file.size > dataSizes.size100MB) {
      setFileError('This file is too large to import.  The maximum size is 100MiB.  Please select another file.');
      setSelectedFile(null);
      return;
    }
    setSelectedFile(file);
    setFileError(null);
  };

  const handleOnClose = (e: Object, reason: string) => {
    if (reason === 'backdropClick')
      return;
    onClose();
  };

  const handleCopyClicked = async (e: React.MouseEvent<HTMLElement>) => {
    let text = '';
    for (let msg of importResult!.validationMessages) {
      const rn = (msg.rowNumber !== undefined && msg.rowNumber !== -1) ? msg.rowNumber + 1 : 'None';
      text = text.concat(`${ msg.level } - ${ msg.message } - Row: ${ rn }${ !!msg.detail ? ' - ' + msg.detail : '' }\n`);
    }
    await navigator.clipboard.writeText(text);
  };

  const rowText = (m: FileImportValidationMessage) => {
    return m.rowNumber !== -1 ? ' (Row: ' + m.rowNumber + ')' : '';
  };

  let nameValid = validateName(name);

  if (name === '') {
    nameValid = true; //done to handle the starting case
  }

  const enableImportButton = nameValid
    && name !== ''
    && importState === 'not_started'
    && !!selectedFile;

  const cancelEnabled = importState !== 'started';

  const getNumDescriptionRows = (description: string) => {
    if (description == null)
      return 1;
    return Math.min(Math.ceil(description.length / 153), 4);
  };

  const importMessage = () => {
    if (importState === 'started')
      return 'Your import has started, please wait...';
    if (importState === 'completed')
      return <><label style={ {color: colors.green, fontWeight: 'bold', paddingRight: '10px'} }>Import
        successful</label> Please review the log messages below for more info.</>;
    if (importState === 'error')
      return <><label style={ {color: colors.red, fontWeight: 'bold', paddingRight: '10px'} }>Import
        failed</label> Please review the log messages below for more info.</>;
    return <></>;
  };

  const detail = (detail: string | undefined) => {
    if (!detail)
      return '';
    return ` Detail: ${ detail }`;
  };

  const importLogText = () => {
    if (importState === 'not_started') {
      return '';
    }
    return <>
      { importResult?.validationMessages.map((m, i) => {
        return <ImportLogTextLine key={ i }>
          <Level level={ m.level }>{ m.level }</Level>
          <Message>{ m.message }{ rowText(m) }{ detail(m.detail) }</Message>
        </ImportLogTextLine>;
      }) }
    </>;
  };

  const progress = () => {
    if (importState === 'started')
      return <CircularProgress color="inherit"
                               style={ {height: '20px', width: '20px', marginLeft: '10px', marginTop: '10px'} }/>;
    return <></>;
  };

  return (
    <Dialog
      fullWidth={ true }
      maxWidth="lg"
      open={ open }
      onClose={ handleOnClose }
    >
      <div style={ {padding: '15px'} }>
        <div style={ {padding: '10px', fontSize: fontSizes.l} }>
          Import { dataLabel }
          { importState === 'completed' &&
            <label style={ {color: colors.green, fontStyle: 'italic'} }> - Import Complete</label> }
          { importState === 'error' &&
            <label style={ {color: colors.red, fontStyle: 'italic'} }> - Import Failed { importState }</label> }
        </div>

        <div style={ {padding: '15px', maxWidth: '700px'} }>
          <CustomInput
            value={ name }
            label="Name"
            error={ !nameValid }
            onChange={ handleNameChange }
            disabled={ importState !== 'not_started' }
          />
        </div>

        <div style={ {padding: '15px'} }>
          <CustomInput
            value={ description }
            label="Description"
            onChange={ handleDescriptionChange }
            multiline={ true }
            disabled={ importState !== 'not_started' }
            rows={ getNumDescriptionRows(description) }
          />
        </div>

        <SelectFileSection>
          {
            !selectedFile && <SelectFileText>Please select an XLSX file to import...</SelectFileText>
          }
          {
            !!selectedFile && <SelectFileText>File selected</SelectFileText>
          }
          <input
            type="file"
            onChange={ handleFileSelectionChange }
            accept=".xlsx"
            multiple={ false }
            disabled={ importState !== 'not_started' }
          />
          { fileError && <FileErrorDiv>{ fileError }</FileErrorDiv> }
        </SelectFileSection>

        <ImportLogSection>
          { importState !== 'not_started' &&
            <>
              <ImportLogSectionTitle>
                <ImportLogSectionTitleLeft>
                  { importMessage() }
                  { progress() }
                </ImportLogSectionTitleLeft>
                <ImportLogSectionTitleRight>
                  <AppButton
                    text="Copy"
                    icon={ <icons.Copy/> }
                    onClick={ handleCopyClicked }
                    disabled={ !importResult }
                  />
                </ImportLogSectionTitleRight>
              </ImportLogSectionTitle>
              <ImportLogTextBox>
                <ImportLogText>
                  { importLogText() }
                </ImportLogText>
              </ImportLogTextBox>
            </>
          }
        </ImportLogSection>

        <div style={ {display: 'flex', flexDirection: 'row-reverse', gap: '20px', paddingRight: '15px'} }>
          {
            (importState === 'completed' || importState === 'error') &&
            <AppButton
              text="Close"
              variant="filledPrimary"
              onClick={ handleCloseClicked }
            />
          }
          {
            importState !== 'completed' &&
            <>
              <AppButton
                text="Import"
                variant="filledPrimary"
                onClick={ handleImportClicked }
                disabled={ !enableImportButton }
              />
              <AppButton
                text="Cancel"
                variant="outlinedSecondary"
                onClick={ handleCancelClicked }
                disabled={ !cancelEnabled }
              />
            </>
          }
        </div>
      </div>
      { (!!errorDialogState) && <ErrorDialog dialogState={ errorDialogState } open={ !!errorDialogState }
                                             onClose={ () => setErrorDialogState(null) }/> }
    </Dialog>
  );
}

export default DataImportDialog;
