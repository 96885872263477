import styled from 'styled-components';

export const SettingsContainer = styled.div`
  padding: 10px 30px;
  width: 1000px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const SettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const SettingsBlurb = styled.div`
`;
export const InputSettingsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const SectionInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const InputLabel = styled.div`
  font-weight: 600;
`;
export const NameInputSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const DescriptionInputSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const CyStartDateSetting = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 600px;
  line-height: 2;
`;
