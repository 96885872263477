import styled from 'styled-components';

export const SummaryAreaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 130px;
  padding: 0 4%;
`;
export const SummaryMetric = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;
export const MetricValue = styled.div<{ color: string }>`
  font-size: 32pt;
  font-weight: 600;
  color: ${ p => p.color };
`;
export const MetricName = styled.div`
  font-size: 14pt;
`;

