import React from 'react';
import * as s from './forecast-validation-styles';
import * as cs from 'components/forecast/forecast-common-styles';
import Spinner from 'components/Spinner';
import * as icons from 'style/Icons';
import {useAppSelector} from 'app/hooks';
import TimeAgo from 'react-timeago';
import {
  selectLastValidationTime,
  selectValidationErrors,
  selectValidationInfo,
  selectValidationWarnings,
} from 'storeSlices/ui-slice';
import {ForecastValidationMessage} from 'apiSlices/api-types';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

export const ForecastValidation = () => {
  const forecastLoading = useAppSelector(s => !s.ui.activeForecastStatus);

  const errorMsgs = useAppSelector(selectValidationErrors);
  const warningMsgs = useAppSelector(selectValidationWarnings);
  const infoMsgs = useAppSelector(selectValidationInfo);

  const lastValidationTime = useAppSelector(selectLastValidationTime);

  if (forecastLoading)
    return <div style={ {margin: 'auto'} }><Spinner/></div>;

  const allMsgs = [...errorMsgs, ...warningMsgs, ...infoMsgs];

  const shorten = (src: string | undefined) => {
    if (!src)
      return '';
    const len = src.length;
    let shortened = src.substring(0, Math.min(len, 400));
    if (len > 400)
      shortened += '...';
    return shortened;
  };

  const messageText = (message: ForecastValidationMessage) => {
    if (!!message.category)
      return message.category + ': ' + message.message;
    return message.message;
  };

  const anyErrors = errorMsgs.length > 0;

  const title = !anyErrors
    ? 'Validation Successful'
    : 'Validation Failed';

  const detail = (message: ForecastValidationMessage) => {
    if (message.detail)
      return shorten(message.detail);
    else if (message.table) {
      let [header, ...rest] = message.table;

      const detailRow = (row: string[], i: number) => {
        return <TableRow key={i}>
          { row.map((c, i) => <TableCell key={i}>{c}</TableCell>) }
        </TableRow>
      }

      return <>
        <Table>
          <TableHead>
            <TableRow>
              { header.map(v => <TableCell key={v}>{v}</TableCell>) }
            </TableRow>
          </TableHead>
          <TableBody>
            { rest.map((r, i) => detailRow(r, i)) }
          </TableBody>
        </Table>
      </>
    }
  }

  const validationMessageView = (message: ForecastValidationMessage) => {
    switch (message.level) {
      case 'Info':
        return <s.ValidationMessage>
          <s.InfoMessageIcon>
            <icons.Info/>
          </s.InfoMessageIcon>
          <s.InfoLabel>
            Info
          </s.InfoLabel>
          <s.ValidationMessageText>{ messageText(message) }</s.ValidationMessageText>
          <s.ValidationMessageDetail>
            { detail(message) }
          </s.ValidationMessageDetail>
        </s.ValidationMessage>;
      case 'Warning':
        return <s.ValidationMessage>
          <s.WarningMessageIcon>
            <icons.Warning/>
          </s.WarningMessageIcon>
          <s.WarningLabel>
            Warning
          </s.WarningLabel>
          <s.ValidationMessageText>{ messageText(message) }</s.ValidationMessageText>
          <s.ValidationMessageDetail>
            { shorten(message.detail) ?? '' }
          </s.ValidationMessageDetail>
        </s.ValidationMessage>;
      case 'Error':
        return <s.ValidationMessage>
          <s.ErrorMessageIcon>
            <icons.Error/>
          </s.ErrorMessageIcon>
          <s.ErrorLabel>
            Error
          </s.ErrorLabel>
          <s.ValidationMessageText>{ messageText(message) }</s.ValidationMessageText>
          <s.ValidationMessageDetail>
            { shorten(message.detail) ?? '' }
          </s.ValidationMessageDetail>
        </s.ValidationMessage>;
    }
  };

  const timeAgo = lastValidationTime
    ? <TimeAgo date={ lastValidationTime }/>
    : 'never';

  return <s.ValidationContainer>
    <s.TitleSection>
      <cs.PageTitle>{ title }</cs.PageTitle>
      <s.ValidationBlurb>
        Use this page to investigate configuration problems in your forecast.
      </s.ValidationBlurb>
    </s.TitleSection>
    <s.ValidationSection>
      <cs.SectionHeading>Validation Messages</cs.SectionHeading>
      <s.ValidationBlurb>
        {
          <>Last Validation: <label style={ {fontStyle: 'italic'} }>{ timeAgo }</label></>
        }
      </s.ValidationBlurb>
      {
        anyErrors &&
        <s.ValidationBlurb>
          { 'Please resolve all errors shown below.' }
        </s.ValidationBlurb>
      }
      <s.ValidationMessageList>
        {
          allMsgs.map((msg, n) => <div key={ n }> { validationMessageView(msg) } </div>)
        }
      </s.ValidationMessageList>

    </s.ValidationSection>
  </s.ValidationContainer>;
};
export default ForecastValidation;
