import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '@fortawesome/fontawesome-free-solid';
import Button from 'components/appButton/AppButton';
import * as icons from 'style/Icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon, ListItemText} from '@mui/material';

function SysAdminMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleAppInfoClick = (_: any) => {
    setAnchorEl(null);
    navigate('/app/info');
  };

  return (
    <>
      <Button
        icon={ <icons.Admin/> }
        onClick={ handleMenuClick }
      />
      <Menu
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ showMenu }
        style={ {zIndex: 3000} }
        onClose={ handleMenuClose }
        MenuListProps={ {
          'aria-labelledby': 'basic-appButton',
        } }
      >
        <MenuItem onClick={ handleAppInfoClick }>
          <ListItemIcon>
            <icons.Info fontSize="small"/>
          </ListItemIcon>
          <ListItemText>
            App Info
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default SysAdminMenu;