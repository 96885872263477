import {useAppSelector} from 'app/hooks';
import {selectScenarioList, selectScenarioName} from 'storeSlices/ui-slice';
import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Button from 'components/appButton/AppButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Divider, ListItemIcon, ListItemText} from '@mui/material';
import * as icons from 'style/Icons';
import AddScenarioDialog from 'components/forecast/addScenarioDialog/AddScenarioDialog';
import useConfirmDialog from 'components/confirmDialog/ConfirmDialogHook';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';
import {CopyScenarioApiCommand, DeleteScenarioApiCommand, RenameScenarioApiCommand} from 'apiCommands/api-commands';
import {usePostCommand, usePostIdCommand} from 'lib/command-client';
import {SingleInputDialog} from 'components/SingleInputDialog';
import {validateName} from 'lib/validators';

function ScenarioName(props: { scenarioId: string | undefined }) {
  const name = useAppSelector(selectScenarioName(props.scenarioId));
  return <>{ name }</>;
}

export function ScenarioSelector(props: { style: React.CSSProperties, scenarioId: string | undefined }) {
  const scenarioIds = useAppSelector(selectScenarioList);
  const navigate = useNavigate();
  const location = useLocation();
  const postCommand = usePostCommand();
  const postIdCommand = usePostIdCommand();

  const activeForecastId = useAppSelector(s => s.ui.activeForecastId);
  const activeScenarioId = useAppSelector(s => s.ui.activeScenarioId);
  const activeScenarioName = useAppSelector(selectScenarioName(activeScenarioId));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const confirmDeleteDialog = useConfirmDialog();

  const showMenu = Boolean(anchorEl);

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  function navigateToScenario(id: string) {
    if (props.scenarioId) {
      const newLocation = location.pathname.replace(props.scenarioId, id);
      navigate(newLocation);
    }
  }

  const handleItemClick = (id: string) => {
    setAnchorEl(null);
    navigateToScenario(id);
  };

  const handleAddClick = () => {
    if (!activeForecastId)
      return;
    setAnchorEl(null);
    setShowAddDialog(true);
  };

  const handleAddDialogClosed = (id: string | undefined) => {
    setShowAddDialog(false);
    if (!id)
      return;

    navigateToScenario(id);
  };

  const handleDeleteClick = () => {
    if (!activeForecastId || !activeScenarioId)
      return;
    setAnchorEl(null);
    confirmDeleteDialog.open();
  };

  const handleCopyClick = () => {
    if (!activeForecastId || !activeScenarioId)
      return;
    setAnchorEl(null);
    setShowCopyDialog(true);
  };

  const handleRenameClick = () => {
    if (!activeForecastId || !activeScenarioId)
      return;
    setAnchorEl(null);
    setShowRenameDialog(true);
  };

  const handleCopyDialogClosed = (id: string | undefined) => {
    setShowCopyDialog(false);
    if (!id)
      return;
    navigateToScenario(id);
  };

  const handleRenameDialogClosed = () => {
    setShowRenameDialog(false);
  };

  const handleScenarioDeleted = async () => {
    if (!activeForecastId || !activeScenarioId || (scenarioIds?.length ?? 0) < 2)
      return;
    const firstNotDeletedScenario = scenarioIds.filter(id => id !== activeScenarioId)[0];
    const cmd: DeleteScenarioApiCommand = {
      type: 'DeleteScenarioApiCommand',
      scenarioId: activeScenarioId,
      forecastId: activeForecastId,
    };
    await postCommand(cmd);
    navigateToScenario(firstNotDeletedScenario);
  };

  const buttonTitle = activeScenarioName
    ? `Scenario: ${ activeScenarioName }`
    : 'Scenario';

  const disableDelete = (scenarioIds?.length ?? 0) < 2;

  if (!props.scenarioId)
    return <></>;

  const chooseIcon = (id: string) => id === activeScenarioId ? <icons.SingleChecked/> : <icons.SingleUnchecked/>;

  function buildCopyCommand(name: string): CopyScenarioApiCommand | undefined {
    if (!activeForecastId || !activeScenarioId)
      return undefined;
    return {
      type: 'CopyScenarioApiCommand',
      forecastId: activeForecastId,
      scenarioId: activeScenarioId,
      name: name,
    };
  }

  function buildRenameCommand(newName: string): RenameScenarioApiCommand | undefined {
    if (!activeForecastId || !activeScenarioId)
      return undefined;
    return {
      type: 'RenameScenarioApiCommand',
      forecastId: activeForecastId,
      scenarioId: activeScenarioId,
      name: newName,
    };
  }

  return (
    <div style={ {paddingLeft: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center'} }>
      <Button
        text={ buttonTitle }
        onClick={ handleMenuClick }
      />
      <Menu
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ showMenu }
        style={ {zIndex: 3000} }
        onClose={ handleMenuClose }
        MenuListProps={ {
          'aria-labelledby': 'basic-appButton',
        } }
      >
        {
          scenarioIds.map(id =>
            <MenuItem key={ id } onClick={ () => handleItemClick(id) }>
              <ListItemIcon>{ chooseIcon(id) }</ListItemIcon>
              <ListItemText><ScenarioName scenarioId={ id }/></ListItemText>
            </MenuItem>,
          )
        }
        <Divider/>
        <MenuItem key="add" onClick={ handleAddClick }>
          <ListItemIcon>
            <icons.Add/>
          </ListItemIcon>
          <ListItemText>Add new scenario...</ListItemText>
        </MenuItem>
        <MenuItem key="delete" onClick={ handleDeleteClick } disabled={ disableDelete }>
          <ListItemIcon>
            <icons.Trash/>
          </ListItemIcon>
          <ListItemText>{ 'Delete ' + activeScenarioName }</ListItemText>
        </MenuItem>
        <MenuItem key="copy" onClick={ handleCopyClick }>
          <ListItemIcon>
            <icons.Copy/>
          </ListItemIcon>
          <ListItemText>{ 'Copy ' + activeScenarioName }</ListItemText>
        </MenuItem>
        <MenuItem key="rename" onClick={ handleRenameClick }>
          <ListItemIcon>
            <icons.Rename/>
          </ListItemIcon>
          <ListItemText>{ 'Rename ' + activeScenarioName }</ListItemText>
        </MenuItem>
      </Menu>
      {
        showAddDialog && activeForecastId &&
        <AddScenarioDialog forecastId={ activeForecastId } open={ showAddDialog } onClose={ handleAddDialogClosed }/>
      }
      {
        showCopyDialog && activeForecastId && activeScenarioId &&
        <SingleInputDialog<CopyScenarioApiCommand>
          open={ showCopyDialog }
          onClose={ handleCopyDialogClosed }
          buildCommand={ buildCopyCommand }
          postCommand={ postIdCommand }
          title={ 'Copy Scenario' }
          buttonText={ 'Copy' }
          textInputLabel={ 'Scenario Name' }
          validateName={ validateName }
        />
      }
      {
        showRenameDialog && activeForecastId && activeScenarioId &&
        <SingleInputDialog<RenameScenarioApiCommand>
          open={ showRenameDialog }
          onClose={ handleRenameDialogClosed }
          buildCommand={ buildRenameCommand }
          postCommand={ postCommand }
          title={ 'Rename Scenario' }
          buttonText={ 'Rename' }
          textInputLabel={ 'New Scenario Name' }
          validateName={ validateName }
        />
      }
      {
        confirmDeleteDialog.isOpen &&
        <ConfirmDialog
          open={ confirmDeleteDialog.isOpen }
          confirmState={ {
            confirmTitle: 'DELETE',
            title: 'Confirm Delete',
            message: 'Are you sure you want to PERMANENTLY delete scenario ' + activeScenarioName + '?',
          } }
          onConfirm={ confirmDeleteDialog.handleClose }
          onClose={ confirmDeleteDialog.handleClose }
          confirmAction={ handleScenarioDeleted }
          confirmIcon={ <icons.Trash/> }
        />
      }
    </div>
  );
}