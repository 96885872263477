import styled from 'styled-components';

export const ForecastReportsContainer = styled.div`
  padding: 10px 30px;
  display: flex;
  width: 1000px;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
`;
export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TitleSectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const TitleSectionRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const WelcomeBlurb = styled.div`
`;
export const MainArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const LeftMainArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 240px;
`;
export const RightMainArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const SectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 20px;
`;
export const SectionTitleLeft = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
export const SectionTitleRight = styled.div`
  font-size: 18pt;
`;
export const ReportDownloadRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 800px;
  padding-bottom: 10px;
`;
export const ReportDownloadName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ReportDownloadLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const ReportDownloadLink = styled.div`
`;
