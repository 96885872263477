import React from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {ForecastChartsInput} from './chart-input-selector';
import AppButton from 'components/appButton/AppButton';
import * as icons from 'style/Icons';
import {mainChartTypeSelectionChanged} from 'storeSlices/charts-slice';
import {ChartType} from 'apiSlices/chart-types';

export function ChartTypeSelector(props: { input: ForecastChartsInput }) {
  const dispatch = useDispatch();
  const input = props.input;
  if (input.isCalcPending)
    return <></>;

  const iconForChartType = (type: string) => {
    switch (type) {
      case 'line':
        return <icons.LineChart/>;
      case 'bar':
        return <icons.BarChart/>;
      case 'stacked-bar':
        return <icons.StackedBarChart/>;
      case 'stacked-area':
        return <icons.Area selected={ input.mainChartType === 'stacked-area' }/>;
      case 'vertical-bar':
        return <icons.BarChart style={ {transform: 'rotate(90deg)'} }/>;
      case 'table':
        return <icons.Table/>;
      default:
        return <icons.Gear/>;
    }
  };
  const handleChartTypeClicked = (t: ChartType) => {
    dispatch(mainChartTypeSelectionChanged(t));
  };
  const chartTypes: ChartType[] = ['bar', 'line', 'stacked-bar', 'stacked-area', 'vertical-bar', 'table'];
  const supportedChartTypes = chartTypes.filter(x => input.mainChart?.chart?.supportedChartTypes.includes(x));
  return <SelectorContainer>
    {
      supportedChartTypes.map((t, n) =>
        <TypeSelection key={ n }>
          <AppButton
            highlighted={ t === input.mainChartType }
            icon={ iconForChartType(t) }
            onClick={ () => handleChartTypeClicked(t) }
          />
        </TypeSelection>,
      )
    }
  </SelectorContainer>;
}

export default ChartTypeSelector;

const SelectorContainer = styled.div`
  display:flex;
  flex-direction: row;
`;
const TypeSelection = styled.div`
`;
