import {triggerInvalidate} from 'apiCommands/tag-invalidation';
import {useDispatch} from 'react-redux';

export function usePostCommand(): (<T extends CommandType>(cmd: T) => Promise<CommandErrorResult>) {
  const dispatch = useDispatch();
  return c => postCommand(c, dispatch);
}

export function usePostIdCommand(): (<T extends CommandType>(cmd: T) => Promise<CommandErrorResult>) {
  const dispatch = useDispatch();
  return c => postIdCommand(c, dispatch);
}

export type PostCommandType = ReturnType<typeof usePostCommand>;
export type PostIdCommandType = ReturnType<typeof usePostIdCommand>;

export interface CommandErrorResult {
  error?: string,
  errorDescription?: string,
  id?: string,
}

interface CommandType {
  type: unknown,
}

async function postCommandInternal<TCommand extends CommandType, TResponse>(cmd: TCommand, mapResult: ((r: Response) => Promise<TResponse>) | undefined = undefined) {
  const envelope = {
    payload: cmd,
    type: cmd.type,
  };
  const bodyText = JSON.stringify(envelope);
  const result = await fetch(`/api/commands`, {
    method: 'POST',
    body: bodyText,
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
  if (result.status >= 300) {
    try {
      const content = await result.json();
      return {error: content.error, errorDescription: content.errorDescription} as CommandErrorResult;
    } catch (e) {
      return {
        error: 'bad_http_status',
        errorDescription: 'Unexpected status code result: ' + result.status,
      } as CommandErrorResult;
    }
  }

  if (mapResult)
    return mapResult(result);
  return {} as CommandErrorResult;
}

const postCommand = async <T extends CommandType>(cmd: T, dispatch: ((action: any) => void)) => {
  const result = await postCommandInternal<T, CommandErrorResult>(cmd);
  triggerInvalidate(cmd, dispatch);
  return result;
};

const postIdCommand = async <T extends CommandType>(cmd: T, dispatch: ((action: any) => void)) => {
  return await postCommandInternal(cmd, async r => {
    const resBody = await r.json();
    triggerInvalidate(cmd, dispatch);
    return {id: resBody.id} as CommandErrorResult;
  });
};

export interface AsyncCommandResult {
  commandId?: string,
  error?: string,
  errorDescription?: string,
}

export const postAsyncCommand = async <T extends CommandType>(cmd: T) => {
  return await postCommandInternal(cmd, async r => {
    const resBody = await r.json();
    if (!resBody.commandId)
      throw new Error('Expected commandId on command response');
    return {commandId: resBody.commandId} as AsyncCommandResult;
  }) as AsyncCommandResult;
};

export interface CommandStatus {
  commandId?: string,
  status?: string,
  error?: string,
  errorDescription?: string,
  payload?: any,
}

export interface IdPayload {
  id: string,
}

export const getCommandStatus = async (commandId: string) => {
  if (!commandId)
    throw new Error('commandId required');
  const result = await fetch(`/api/commands/status/${ commandId }`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
  if (result.status >= 300) {
    try {
      const content = await result.json();
      return {error: content.error, errorDescription: content.errorDescription} as CommandStatus;
    } catch (e) {
      return {error: 'bad_http_status', errorDescription: 'Unexpected status code result: ' + result.status};
    }
  }

  const content = await result.json();
  if (!content.commandId)
    throw new Error('Expected commandId on command response');
  return {
    commandId: content.commandId,
    status: content.status,
    error: content.error,
    errorDescription: content.errorDescription,
    payload: content.payload,
  } as CommandStatus;
};

export const pollForCommandStatus = async (commandId: string) => {
  if (!commandId)
    throw new Error('commandId required');
  let i = 0;
  while (true) {
    const status = await getCommandStatus(commandId);
    if (status.status === 'Error' || status.status === 'Success')
      return status;
    if (!!status.error)
      return status;
    await new Promise(p => setTimeout(p, 500));
    if (i++ > 120)
      return {error: 'Timed out waiting for command to complete.'};
  }
};
