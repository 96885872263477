import React from 'react';
import {ForecastChartsInput} from 'components/forecast/forecastCharts/chart-input-selector';
import * as s from './filters-styles';
import Filter from './filter/Filter';

export function Filters(props: { input: ForecastChartsInput }) {
  const filters = props.input.mainChartFilters.filters.filter(x => x.added);

  return <s.FiltersContainers>
    {
      filters.map((f, n) =>
        <Filter input={ f } key={ n }/>,
      )
    }
  </s.FiltersContainers>;
}

export default Filters;