import {useAppSelector} from 'app/hooks';
import {apiSlice, useGetStepInputQuery} from 'apiSlices/forecasting-api-slice';
import {DataGridPremium, GridRenderCellParams, GridRowModel} from '@mui/x-data-grid-premium';
import {GridContainer} from './budget-grid-styles';
import React, {useState} from 'react';
import AppButton from 'components/appButton/AppButton';
import {Adjustment} from 'components/forecast/forecastBudgetBuilder/budget-builder-types';
import {
  ChangeAdjustmentDialog,
} from 'components/forecast/forecastBudgetBuilder/changeAdjustmentDialog/ChangeAdjustmentDialog';
import {useDispatch} from 'react-redux';
import {formatAdjustment} from './formatters';
import {buildGridData} from './build-grid-data';

export const BudgetGrid = (props: { stepId: string | undefined, year: number | undefined }) => {
  const dispatch = useDispatch();
  const {stepId, year} = props;

  const forecastId = useAppSelector(s => s.ui.activeForecastId);
  const scenarioId = useAppSelector(s => s.ui.activeScenarioId);
  const actScenarioId = useAppSelector(s => s.ui.activeScenario?.id);
  const actScenarioHasStep = useAppSelector(s => s.ui.activeScenario?.steps?.find(x => x.id === stepId));

  const query = {
    forecastId: forecastId ?? '',
    scenarioId: scenarioId ?? '',
    stepId: stepId ?? '',
    year: year ?? 0,
  };
  const skip = !forecastId || !scenarioId || !stepId || !year ||
    actScenarioId !== scenarioId || !actScenarioHasStep;

  const stepInputsQuery = useGetStepInputQuery(query, {skip: skip});
  const stepInputs = stepInputsQuery.data;

  const [changeAdjParams, setChangeAdjParams] = useState<{
    account: string | undefined,
    existing: Adjustment | undefined
  } | undefined>(undefined);

  if (!stepId || !year || !forecastId || !scenarioId || !stepInputs)
    return <></>;

  function changeAdjustment(row: GridRowModel<any>, value: Adjustment) {
    setChangeAdjParams({account: row.account, existing: value});
  }

  const adjustmentRenderer = (params: GridRenderCellParams<Adjustment>) => {
    if (params.value === undefined) {
      return <AppButton text="click to adjust" onClick={ () => changeAdjustment(params.row, params.value) }/>;
    } else {
      return <AppButton text={ formatAdjustment(params.value) }
                        onClick={ () => changeAdjustment(params.row, params.value) }/>;
    }
  };

  const {cols, rows} = buildGridData(adjustmentRenderer, stepInputs);

  function changeAdjParamsClosed(saved: boolean) {
    setChangeAdjParams(undefined);
    if (saved)
      dispatch(apiSlice.util.invalidateTags(['StepInput']));
  }

  return (
    <GridContainer>
      <DataGridPremium
        columns={ cols }
        rows={ rows }
        rowHeight={ 27 }
        columnHeaderHeight={ 30 }
        pagination={ false }
        hideFooter={ true }
        disableColumnReorder={ true }
        disableColumnPinning={ true }
        disableRowGrouping={ true }
        isRowSelectable={_ => false}
        sx={ {
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        } }
        initialState={{
          columns: {
            columnVisibilityModel: {
              "account": true,
              "object": true,
              "fund": false,
              "department": false,
              "division": false,
              "baseVal": true,
              "beforeVal": true,
              "adj": true,
              "afterVal": true,
            },
          },
        }}
        getRowId={ (r) => r.account ?? '_all' }
      />
      <ChangeAdjustmentDialog
        stepId={ stepId }
        account={ changeAdjParams?.account }
        year={ year }
        existingAdj={ changeAdjParams?.existing }
        onClose={ changeAdjParamsClosed }
        open={ changeAdjParams !== undefined }/>
    </GridContainer>
  );
};