import React from 'react';
import {useParams} from 'react-router-dom';
import AvailableData from './availableData/AvailableData';
import {availableDataTypes} from 'lib/constants';

export function FundBalancesDataTab() {
  const params = useParams();
  const tenantId = params.tenantId;
  const forecastId = params.forecastId;
  if (!tenantId || !forecastId)
    return <></>;

  return <AvailableData
    label="Fund Balances"
    dataType={ availableDataTypes.FundBalances }
    tenantId={ tenantId }
    forecastId={ forecastId }
  />;
}

export default FundBalancesDataTab;