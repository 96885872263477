import React, {useEffect, useState} from 'react';
import * as s from './forecast-budget-explorer-styles';
import * as cs from 'components/forecast/forecast-common-styles';
import AppButton from 'components/appButton/AppButton';
import {useGetBudgetExplorerDataQuery} from 'apiSlices/forecasting-api-slice';
import {useParams} from 'react-router-dom';
import {useAppSelector} from 'app/hooks';
import {styled} from '@mui/material/styles';
import {Badge, BadgeProps} from '@mui/material';
import * as icons from 'style/Icons';
import {
  DataGridPremium,
  GridAggregationModel,
  GridColDef,
  GridColumnVisibilityModel,
  GridGroupingValueGetterParams,
  GridRowGroupingModel,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import {BudgetExplorerData, BudgetExplorerDimension, BudgetExplorerMeasure} from 'apiSlices/api-types';
import {formatValue} from 'lib/value-formatter';

interface RefreshButtonProps {
  refetch: () => void;
  forecastSequence: number;
}

const RefreshButton = (props: RefreshButtonProps) => {
  const {refetch, forecastSequence} = props;
  const latestSequence = useAppSelector(s => s.ui.activeForecast?.updateSequence ?? -1);
  const showBadge = forecastSequence !== latestSequence && latestSequence !== -1;

  const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
      right: 6,
      top: 18,
      border: `1px solid ${ theme.palette.background.paper }`,
      borderRadius: 8,
      padding: '0 4px',
      height: 10,
    },
  }));

  const icon =
    <StyledBadge color="primary" variant="dot" overlap="circular" invisible={ !showBadge }>
      <icons.Refresh/>
    </StyledBadge>;

  return <AppButton
    onClick={ refetch }
    size="small"
    disabled={ false }
    icon={ icon }
  />;
};

export const ForecastBudgetExplorer = () => {
  const params = useParams();

  const forecastId = params.forecastId ?? '';
  const scenarioId = params.scenarioId ?? '';

  const budgetExplorerDataQuery = useGetBudgetExplorerDataQuery({
    forecastId,
    scenarioId,
  }, {skip: forecastId === '' || scenarioId === ''});
  const data = budgetExplorerDataQuery.data;

  const apiRef = useGridApiRef();
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [aggregationModel, setAggregationModel] = useState<GridAggregationModel>({});
  const [rowGroupingModel, setRowGroupingModel] = useState<GridRowGroupingModel>([]);

  if (Object.keys(visibilityModel).length === 0 && data !== undefined && !data.noData && data.dimensions.length > 0)
    setVisibilityModel(makeInitiallyVisibleColumns(data));

  if (Object.keys(aggregationModel).length === 0 && data !== undefined && !data.noData && data.measures.length > 0)
    setAggregationModel(makeInitialAggregations(data));

  useEffect(() => {
    if (Object.keys(visibilityModel).length === 0 && data !== undefined && !data.noData)
      setVisibilityModel(makeInitiallyVisibleColumns(data));
  }, [data, visibilityModel]);

  useEffect(() => {
    if (Object.keys(aggregationModel).length === 0 && data !== undefined && !data.noData && data.measures.length > 0)
      setAggregationModel(makeInitialAggregations(data));
  }, [data, aggregationModel]);

  function makeInitiallyVisibleColumns(data: BudgetExplorerData | undefined): GridColumnVisibilityModel {
    if (data === undefined)
      return {};

    const ret: GridColumnVisibilityModel = {};
    for (const dim of data.dimensions) {
      ret[dim.name] = dim.initiallyVisible;
    }

    for (const m of data.measures) {
      ret[m.name] = m.initiallyVisible;
    }

    return ret;
  }

  function makeInitialAggregations(data: BudgetExplorerData | undefined): GridAggregationModel {
    if (data === undefined)
      return {};

    const ret: GridAggregationModel = {};
    for (const m of data.measures) {
      ret[m.name] = 'sum';
    }

    return ret;
  }

  function refetch() {
    budgetExplorerDataQuery.refetch();
  }

  function makeRows(data: BudgetExplorerData | undefined): readonly any[] {
    if (data === undefined || data.noData)
      return [];

    const acctDim = data.dimensions.find(d => d.name === 'Account');
    if (acctDim === undefined)
      return [];

    return Array.from(
      {length: data.rowCount},
      (_, i) => ({id: acctDim.valueMap[acctDim.valueIdx[i]], row: i}));
  }

  const valueFormatter = (params: GridValueFormatterParams<number>) => {
    return formatValue(params.value, 2);
  };

  function makeCols(data: BudgetExplorerData | undefined): GridColDef<any>[] {
    if (data === undefined || data.noData)
      return [];

    let makeGetter = (d: BudgetExplorerDimension) => (p: GridValueGetterParams<any, any> | GridGroupingValueGetterParams<any, any>) => d.valueMap[d.valueIdx[p.row.row]];
    let makeMeasureGetter = (m: BudgetExplorerMeasure) => (p: GridValueGetterParams<any, any> | GridGroupingValueGetterParams<any, any>) => m.values[p.row.row];

    const dims = Array.from(
      {length: data.dimensions.length},
      (_, i): GridColDef<any> => {
        let dim = data.dimensions[i];
        return {
          type: 'string',
          align: 'left',
          description: dim.columnHeading,
          headerName: dim.columnHeading,
          field: dim.name,
          width: 200 * (dim.nominalWidth / 23.0),
          valueGetter: makeGetter(dim),
          groupingValueGetter: makeGetter(dim),
        };
      },
    );

    const vals = Array.from(
      {length: data.measures.length},
      (_, i): GridColDef<any> => {
        let m = data.measures[i];
        return {
          type: 'number',
          align: 'right',
          description: m.columnHeading,
          headerName: m.columnHeading,
          field: m.name,
          valueGetter: makeMeasureGetter(m),
          groupingValueGetter: makeMeasureGetter(m),
          valueFormatter: valueFormatter,
          width: 125,
        };
      },
    );

    return [...dims, ...vals];
  }

  const rows = makeRows(data);
  const cols = makeCols(data);

  function handleRowGroupingChanged(model: GridRowGroupingModel) {
    const inNew: string[] = model.filter(x => !rowGroupingModel.includes(x));
    const inOld: string[] = rowGroupingModel.filter(x => !model.includes(x));

    if (inNew.length === 0 && inOld.length === 0) {
      return;
    }

    const newVisibility = {...visibilityModel};
    for (const newItem of inNew) {
      newVisibility[newItem] = false;
    }
    for (const oldItem of inOld) {
      newVisibility[oldItem] = true;
    }

    setVisibilityModel(newVisibility);
    setRowGroupingModel(model);
  }

  return <s.BudgetExplorerContainer>
    <s.TitleSection>
      <s.TitleSectionLeft>
        <cs.PageTitle>Budget Explorer</cs.PageTitle>
      </s.TitleSectionLeft>
      <s.TitleSectionRight>
        <s.TitleButtonsContainer>
          <RefreshButton refetch={ refetch } forecastSequence={ data?.forecastSequence ?? -1 }/>
        </s.TitleButtonsContainer>
      </s.TitleSectionRight>
    </s.TitleSection>
    <s.MainArea>
      <s.InnerMainArea>
        <DataGridPremium
          columns={ cols }
          rows={ rows }
          rowHeight={ 27 }
          pagination={ false }
          hideFooter={ true }
          rowCount={ data?.rowCount ?? 0 }
          density="standard"
          rowGroupingColumnMode="multiple"
          sx={ {
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          } }
          apiRef={ apiRef }
          columnVisibilityModel={ visibilityModel }
          onColumnVisibilityModelChange={ setVisibilityModel }
          aggregationModel={ aggregationModel }
          onAggregationModelChange={ setAggregationModel }
          rowGroupingModel={ rowGroupingModel }
          onRowGroupingModelChange={ handleRowGroupingChanged }
          isRowSelectable={_ => false}
          slots={ {toolbar: GridToolbar} }
        />
      </s.InnerMainArea>
    </s.MainArea>
  </s.BudgetExplorerContainer>;
};
export default ForecastBudgetExplorer;
