import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import * as c from './forecast-recycle-styles';
import AppButton from 'components/appButton/AppButton';
import * as icons from 'style/Icons';
import Spinner from 'components/Spinner';
import {useGetDeletedForecastsByTenantIdQuery} from 'apiSlices/forecasting-api-slice';
import * as routeBuilder from 'lib/route-builder';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';
import {
  DeleteAllForecastsFromRecycleBinApiCommand,
  DeleteForecastFromRecycleBinApiCommand,
  RestoreForecastFromRecycleBinApiCommand,
} from 'apiCommands/api-commands';
import {usePostCommand} from 'lib/command-client';
import {Forecast} from 'apiSlices/api-types';

export const ForecastRecycleBin = () => {
  const params = useParams();
  const postCommand = usePostCommand();
  const tenantId = params.tenantId;
  if (!tenantId)
    throw new Error('Invalid tenantId');

  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showDeleteOne, setShowDeleteOne] = useState(false);
  const [currentForecast, setCurrentForecast] = useState<Forecast | null>(null);
  const [saving, setSaving] = useState(false);

  const query = useGetDeletedForecastsByTenantIdQuery(tenantId, {refetchOnMountOrArgChange: true});

  const forecasts = query.data;
  if (query.isLoading || !forecasts)
    return <Spinner/>;

  const handleDeleteForecast = (fc: Forecast) => {
    setCurrentForecast(fc);
    setShowDeleteOne(true);
  };

  const handleRestoreForecast = async (fc: Forecast) => {
    setSaving(true);
    const cmd: RestoreForecastFromRecycleBinApiCommand = {
      type: 'RestoreForecastFromRecycleBin',
      tenantId: fc.tenantId,
      forecastId: fc.id,
    };
    await postCommand(cmd);
    await query.refetch();
    setCurrentForecast(null);
    setSaving(false);
  };

  const deleteOne = async () => {
    if (!currentForecast)
      return;
    const cmd: DeleteForecastFromRecycleBinApiCommand = {
      type: 'DeleteForecastFromRecycleBin',
      tenantId: currentForecast.tenantId,
      forecastId: currentForecast.id,
    };
    await postCommand(cmd);
    await query.refetch();
    setCurrentForecast(null);
  };

  const deleteAll = async () => {
    const cmd: DeleteAllForecastsFromRecycleBinApiCommand = {
      type: 'DeleteAllForecastsFromRecycleBin',
      tenantId: tenantId,
    };
    await postCommand(cmd);
    await query.refetch();
    setCurrentForecast(null);
  };

  return <c.PageContainer>
    <c.HeaderSection>
      <c.HeaderLeft>
        <c.Title>Forecasts Recycle Bin</c.Title>
      </c.HeaderLeft>
      <c.HeaderRight>
        <AppButton
          text="Back to Forecasts List"
          linkTo={ routeBuilder.getTenantForecastsRoute(tenantId) }
        />
        {
          forecasts.length > 0 &&
          <AppButton
            text="Delete All"
            icon={ <icons.Trash/> }
            onClick={ () => setShowDeleteAll(true) }
            disabled={ saving }
          />
        }
      </c.HeaderRight>
    </c.HeaderSection>
    <c.RecycleList>
      {
        forecasts.length === 0 &&
        <c.RecycleListItem>
          There are no items in the recycle bin
        </c.RecycleListItem>
      }
      {
        forecasts.map(f =>
          <c.RecycleListItem key={ f.id }>
            <AppButton
              text="Delete"
              variant="filledSecondary"
              size="small"
              onClick={ () => handleDeleteForecast(f) }
              disabled={ saving }
            />
            <AppButton
              text="Restore"
              variant="outlinedInfo"
              size="small"
              onClick={ () => handleRestoreForecast(f) }
              disabled={ saving }
            />
            { f.name }
          </c.RecycleListItem>,
        )
      }
    </c.RecycleList>
    {
      showDeleteAll &&
      <ConfirmDialog
        open={ showDeleteAll }
        onClose={ () => setShowDeleteAll(false) }
        onConfirm={ () => setShowDeleteAll(false) }
        confirmState={ {
          title: 'Delete All?', confirmTitle: 'DELETE ALL',
          message: 'Are you sure you want to delete all recycled forecasts?  WARNING: This cannot be undone.',
        } }
        confirmAction={ deleteAll }
        confirmIcon={ <icons.Trash/> }
      />
    }
    {
      showDeleteOne &&
      <ConfirmDialog
        open={ showDeleteOne }
        onClose={ () => setShowDeleteOne(false) }
        onConfirm={ () => setShowDeleteOne(false) }
        confirmState={ {
          title: 'Delete Forecast?', confirmTitle: 'DELETE',
          message: `Are you sure you want to delete forecast ${ currentForecast?.name }?  WARNING: This cannot be undone.`,
        } }
        confirmAction={ deleteOne }
        confirmIcon={ <icons.Trash/> }
      />
    }
  </c.PageContainer>;
};
export default ForecastRecycleBin;