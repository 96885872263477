import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function InlineSpinner(props: any) {
  return (
    <CircularProgress color="primary" size="1rem"/>
  );
}

export default InlineSpinner;
