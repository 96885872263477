import React, {ChangeEventHandler} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CustomInput from 'components/customInput/CustomInput';

export interface SearchBoxProps {
  onChange: ChangeEventHandler<HTMLInputElement>,
}

function SearchBox(props: SearchBoxProps) {
  const {onChange} = props;
  return (
    <div>
      <CustomInput
        onChange={ onChange }
        placeholder="Search"
        Icon={ <SearchIcon/> }
      />
    </div>
  );
}

export default SearchBox;
