import React, {useEffect} from 'react';
import Button from 'components/appButton/AppButton';
import {getTenantsRoute} from 'lib/route-builder';
import * as icons from 'style/Icons';
import {fontSizes} from 'style/vars';
import InlineSpinner from 'components/InlineSpinner';
import {useAppSelector} from 'app/hooks';
import {tenantsFetched} from 'storeSlices/ui-slice';
import {useGetTenantsQuery} from 'apiSlices/forecasting-api-slice';
import {useDispatch} from 'react-redux';

export function TenantsButton(props: { style: React.CSSProperties }) {
  let dispatch = useDispatch();
  let getTenantsQuery = useGetTenantsQuery();

  useEffect(() => {
    if (getTenantsQuery.isSuccess)
      dispatch(tenantsFetched(getTenantsQuery.data || []));
  }, [dispatch, getTenantsQuery.isSuccess, getTenantsQuery.data]);

  const showTenantsLink = useAppSelector(s => (s.ui.allTenants?.length ?? 1) > 1);
  const tenantNameLoading = getTenantsQuery.isLoading;
  const tenantName = useAppSelector(s => s.ui.activeTenant?.name);

  return <>
    {
      showTenantsLink &&
      <div style={ props.style }>
        <Button
          linkTo={ getTenantsRoute() }
          text="Tenants"
          icon={ <icons.Building/> }
        />
      </div>
    }
    {
      tenantName &&
      <div style={ {
        ...props.style,
        paddingLeft: '15px',
        paddingTop: '5px',
        paddingRight: '15px',
        fontSize: fontSizes.s,
        fontStyle: 'italic',
      } }>
        { tenantName }
      </div>
    }
    {
      tenantNameLoading &&
      <InlineSpinner/>
    }
  </>;
}