import styled from 'styled-components';
import {colors} from 'style/colors';

export const WelcomeContainer = styled.div`
  padding: 10px 30px;
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const WelcomeBlurb = styled.div`
`;
export const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: solid #e4e4e4 0.5px;
  background: #f4f4f4;
  padding: 10px 10px 25px 10px;
  border-radius: 3px;
`;
export const TaskSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 15px;
`;
export const TaskRow = styled.div<{ completed?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
  align-items: center;
  color: ${ p => p.completed ? colors.themePrimary : colors.black }
`;
export const TaskIcon = styled.div<{ completed?: boolean }>`
  cursor: ${ p => p.completed ? null : 'pointer' };
`;
export const TaskLabel = styled.div<{ completed?: boolean }>`
  cursor: ${ p => p.completed ? null : 'pointer' };
  &:hover {
    color: var(--color-theme-primary);
  }
`;
export const ButtonContainer = styled.div`
  width: 140px;
  display:flex;
  flex-direction: row;
  justify-content: left;
`;
