import React, {useEffect} from 'react';
import {useMatch, useParams} from 'react-router-dom';
import HeaderProfile from 'components/header/HeaderProfile';
import SysAdminMenu from 'components/header/SysAdminMenu';
import Auth from 'lib/auth';
import {useSelector} from 'react-redux';
import {Profile, selectProfile} from 'storeSlices/profile-slice';
import * as constants from 'lib/constants';
import HomeButton from './HomeButton';
import {useAppDispatch, useAppSelector} from 'app/hooks';

import {
  notViewingTenant,
  selectIsInvalidTenant,
  viewingForecast,
  viewingScenario,
  viewingTenant,
} from 'storeSlices/ui-slice';

import {TenantsButton} from './TenantsButton';
import {ForecastsButton} from './ForecastsButton';
import {AppLogo} from './AppLogo';
import {ScenarioSelector} from './ScenarioSelector';

interface HeaderProps {
  auth: Auth;
}

function Header(props: HeaderProps) {
  const dispatch = useAppDispatch();
  const params = useParams();
  const match = useMatch('/app/tenants/:tenantId/forecasts/:forecastId/:forecastRegion/:scenarioId');
  const tenantId = params.tenantId;
  const forecastId = params.forecastId;

  let scenarioId = match?.params?.scenarioId;
  if (!['charts', 'budget-explorer', 'budget-builder', 'reports'].includes(match?.params?.forecastRegion ?? ''))
    scenarioId = undefined;

  // this effect MUST come before the query effects below that tell us we fetched things...  Otherwise, things get wonky.
  useEffect(() => {
    if (scenarioId)
      dispatch(viewingScenario({scenarioId, forecastId: forecastId ?? '', tenantId: tenantId ?? ''}));
    else if (forecastId)
      dispatch(viewingForecast({forecastId, tenantId: tenantId ?? ''}));
    else if (tenantId)
      dispatch(viewingTenant(tenantId));
    else
      dispatch(notViewingTenant());
  }, [dispatch, tenantId, forecastId, scenarioId]);

  const isInvalidTenant = useSelector(selectIsInvalidTenant);
  const isInvalidForecast = useAppSelector(s => s.ui.activeForecastInvalid);
  const profile = useSelector(selectProfile) as Profile;

  if (!profile)
    return <div className="header-container">
      <span>Invalid / Unauthorized User Account</span>
    </div>;

  if (isInvalidTenant)
    return <div className="header-container">
      <span>Invalid Tenant</span>
    </div>;

  if (isInvalidForecast)
    return <div className="header-container">
      <span>Invalid Forecast</span>
    </div>;

  const isSysAdmin = profile.role === constants.roles.SysAdmin;
  const itemStyle: React.CSSProperties = {
    padding: '3px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };
  return (
    <>
      <div id="header-row" className="header-container">
        <div className="logo-title-container">
          <AppLogo style={ itemStyle }/>
          <div style={ {paddingLeft: '15px', display: 'flex', flexDirection: 'row'} }>
            <TenantsButton style={ itemStyle }/>
            <ForecastsButton style={ itemStyle }/>
            <ScenarioSelector style={ itemStyle } scenarioId={ scenarioId }/>
          </div>
        </div>

        <div style={ {display: 'flex'} }>
          <div style={ itemStyle }>
            <HomeButton/>
          </div>
          {
            isSysAdmin && (
              <div style={ itemStyle }>
                <SysAdminMenu/>
              </div>
            )
          }
          <div style={ itemStyle }>
            <HeaderProfile auth={ props.auth } currentTenantId={ tenantId }/>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
