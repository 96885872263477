import React from 'react';
import Dialog from '@mui/material/Dialog';
import {fontSizes} from 'style/vars';
import {convertErrorToTitle} from 'lib/util';
import AppButton from './appButton/AppButton';

export interface ErrorDialogProps {
  open: boolean,
  onClose: Function,
  dialogState: { error?: string, errorDescription?: string },
}

function ErrorDialog(props: ErrorDialogProps) {
  const {open, onClose, dialogState} = props;
  const {error, errorDescription} = dialogState;

  const handleOnClose = (e: any, r: any) => {
    if (r === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <Dialog open={ open } onClose={ handleOnClose }>
      <div style={ {padding: '15px', fontSize: fontSizes.r, width: '550px'} }>
        <div style={ {padding: '10px', fontSize: fontSizes.l} }>An Error Has Occurred</div>

        <div style={ {
          display: 'flex',
          flexDirection: 'column',
          fontSize: fontSizes.r,
          padding: '5px',
          marginBottom: '5px',
        } }>
          <div style={ {padding: '5px', lineHeight: '1.4em', marginBottom: '8px'} }><label
            style={ {fontWeight: 'bold'} }>Error:</label> { convertErrorToTitle(error) }</div>
          <div style={ {padding: '5px', lineHeight: '1.4em', marginBottom: '8px'} }><label
            style={ {fontWeight: 'bold'} }>Description:</label> { errorDescription ?? '(no description)' }</div>
        </div>

        <div style={ {display: 'flex', flexDirection: 'row-reverse', fontSize: fontSizes.m} }>
          <AppButton
            text="OK"
            onClick={ () => onClose() }
            variant="filledRed"
            size="large"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ErrorDialog;