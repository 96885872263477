import styled from 'styled-components';

export const YearSelectorBar = styled.div`
    display: flex;
    flex-direction: row;
    border: 0.5px solid var(--color-grey);
    border-radius: 4px;
`;

interface ItemProps {
  active: boolean,
}

export const YearSelectorItem = styled.div<ItemProps>`
  background-color: ${ props => props.active ? 'lightgreen' : undefined }   
`;