import {apiSlice, tagTypes} from 'apiSlices/forecasting-api-slice';
import {TagDescription} from '@reduxjs/toolkit/dist/query/endpointDefinitions';

type TagName = typeof tagTypes[number];

interface TypedCommand {
  type: unknown;
}

type ApiTagDescription = TagDescription<TagName>;
type CommandTypeOf<T extends { type: unknown }> = T['type'];
type TagInvalidation<T> = (ApiTagDescription | ((c: T) => ApiTagDescription))
type StoredInvalidator = ApiTagDescription | ((c: any) => ApiTagDescription);

const handlers: { [key: string]: StoredInvalidator[] } = {};

export function invalidateTags<T extends TypedCommand>(cmdType: CommandTypeOf<T>, ...tags: TagInvalidation<T>[]) {
  const cmdTypeString = cmdType as string;
  handlers[cmdTypeString] = tags;
}

export function triggerInvalidate<T extends TypedCommand>(cmdName: CommandTypeOf<T>, dispatch: ((action: any) => void)): void;
export function triggerInvalidate<T extends TypedCommand>(c: T, dispatch: ((action: any) => void)): void;
export function triggerInvalidate(c: string | TypedCommand, dispatch: ((action: any) => void)) {
  const cmdTypeString = (typeof c == 'string') ? c : c.type as string;
  const handler = handlers[cmdTypeString];
  if ((handler ?? []).length === 0)
    return;
  const tagSpecs: TagDescription<TagName>[] = [];
  for (const h of handler) {
    if (typeof h === 'function') {
      tagSpecs.push(h(c));
    } else { // object or string, no matter which...
      tagSpecs.push(h);
    }
  }
  dispatch(apiSlice.util.invalidateTags(tagSpecs));
}

