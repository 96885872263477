import styled from 'styled-components';

export const FilterContainer = styled.div`
    display: flex;
`;
export const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
`;
export const FilterBarBar = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;
export const AddFilterContainer = styled.div`
    display: flex;
`;