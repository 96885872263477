import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import './App.css';
import Auth from './lib/auth';
import {profileAvailable} from 'storeSlices/profile-slice';
import MainPage from './components/mainPage/MainPage';
import * as t from 'apiCommands/api-commands';

// this forces the invalidateTags capabilities of the api-commands file to happen.
t.default();

interface AppProps {
  auth: Auth;
}

function App(props: AppProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = new Auth();
    if (!auth.getRenewScheduled()) {
      auth.scheduleRenewalWithRandom();
    } else {
      console.log('Renew already scheduled');
    }
    const userProfile = auth.getProfile();
    if (!userProfile)
      return;
    if (userProfile.requiresPasswordReset) {
      auth.changePassword(true);
    }
    dispatch(profileAvailable(userProfile));
  }, [dispatch, props.auth]);

  if (!props.auth.isAccessTokenValid()) {
    console.log('invalid access token.  waiting for token to be valid');
    return <div></div>;
  }

  return (
    <MainPage auth={ props.auth }/>
  );
}

export default App;
