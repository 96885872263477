import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const PageContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 900px;
  margin: 0 auto;
  gap: 20px;
  height: 100%;
  overflow-y: clip;
`;
export const HeaderSection = styled.div`
  flex-direction: row;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
`;
export const HeaderLeft = styled.div`
  flex-direction: row;
  display: flex;
  gap: 15px;
  align-items: center;
`;
export const HeaderRight = styled.div`
  flex-direction: row;
  display: flex;
  gap: 15px;
  align-items: center;
`;
export const Title = styled.div`
  font-size: var(--font-xl);
  font-weight: 300;
  padding: 15px 0 20px;
`;
export const ForecastsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 20px;
  overflow-y: scroll;
  flex-grow: 1;
  height: 100px;
`;
export const ForecastListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 0.1px lightgray solid;
  border-radius: 3px;
  overflow: hidden;
`;
export const ForecastListItemRow1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
`;
export const FiscalYearLabel = styled.div`
  display: flex;
  font-weight: bold;
  background-color: var(--color-theme-primary);
  color: white;
  flex-direction: column;
  justify-content: center;
  padding: 3px 8px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`;
export const ForecastName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  color: var(--color-secondary);
  height: 100%;
  font-weight: 500;

  &:hover {
    color: var(--color-theme-primary);
    cursor: pointer;
  }
`;
export const ForecastListItemRow1Left = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
export const ForecastListItemRow1Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-right: 10px;
`;
export const ForecastListItemRow2 = styled.div`
  padding: 3px 18px;
`;
export const ForecastListItemRow3 = styled.div`
  padding: 10px 10px;
`;
export const TimestampLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 12px;
  color: gray;
`;
export const CalendarIcon = styled(FontAwesomeIcon)`
  color: gray;
  padding-top: 2px;
`;
export const DropdownIconContainer = styled.div`
`;
