import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import ErrorDialog from 'components/ErrorDialog';
import {validateName} from 'lib/validators';
import {fontSizes} from 'style/vars';
import CustomInput from 'components/customInput/CustomInput';
import {CommandErrorResult, usePostCommand} from 'lib/command-client';
import {useGetAllForecastsByTenantIdQuery} from 'apiSlices/forecasting-api-slice';
import * as dtUtils from 'lib/date-time-utils';
import {CreateForecastApiCommand} from 'apiCommands/api-commands';
import AppButton from 'components/appButton/AppButton';

export interface AddForecastDialogProps {
  tenantId: string,
  open: boolean,
  onClose: (event?: object, reason?: string) => void;
}

function AddForecastDialog(props: AddForecastDialogProps) {
  const postCommand = usePostCommand();
  const {open, onClose, tenantId} = props;
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [cyStartDate, setCyStartDate] = useState('7/1/' + dtUtils.getCurrentYear());
  const [cyStartDateValid, setCyStartDateValid] = useState(true);
  const [saving, setSaving] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | null>(null);
  const forecasts = useGetAllForecastsByTenantIdQuery(tenantId);

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleCyStartDateChanged = (e: any) => {
    const val = e.target.value;
    const isValid = dtUtils.validateDate(val);
    setCyStartDate(val);
    setCyStartDateValid(isValid);
  };

  const handleAddClicked = async () => {
    setSaving(true);
    const cmd: CreateForecastApiCommand = {
      type: 'CreateForecast',
      name: name,
      tenantId: tenantId,
      description: description,
      cyStartDate: dtUtils.format(cyStartDate),
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      setName('');
      onClose();
    }
  };

  const handleCancelClicked = () => {
    setName('');
    onClose();
  };

  let nameValid = validateName(name);

  if (!!(forecasts.currentData) && forecasts.currentData.find(x => x.name === name)) {
    nameValid = false;
  }

  if (name === '') {
    nameValid = true; //done to handle the starting case
  }

  const enableSave = nameValid
    && name !== ''
    && cyStartDateValid
    && !saving;

  return (
    <Dialog open={ open } onClose={ onClose }>
      <div style={ {padding: '15px'} }>
        <div style={ {padding: '10px', fontSize: fontSizes.l, width: '300px', marginRight: '600px'} }>New Forecast</div>

        <div style={ {padding: '15px'} }>
          <CustomInput
            value={ name }
            label="Forecast Name"
            error={ !nameValid }
            onChange={ handleNameChange }
            disabled={ saving }
          />
        </div>

        <div style={ {padding: '15px'} }>
          <CustomInput
            value={ description }
            label="Description"
            onChange={ handleDescriptionChange }
            multiline={ true }
            disabled={ saving }
          />
        </div>

        <div style={ {padding: '15px'} }>
          <CustomInput
            value={ cyStartDate }
            label="Current Year Start Date (M/D/YYYY)"
            onChange={ handleCyStartDateChanged }
            multiline={ true }
            disabled={ saving }
            error={ !cyStartDateValid }
          />
        </div>

        <div style={ {display: 'flex', flexDirection: 'row-reverse', gap: '20px', paddingRight: '15px'} }>
          <AppButton
            text="Create Forecast"
            variant="filledPrimary"
            onClick={ handleAddClicked }
            disabled={ !enableSave }
          />
          <AppButton
            text="Cancel"
            variant="outlinedSecondary"
            onClick={ handleCancelClicked }
            disabled={ saving }
          />
        </div>
      </div>
      { (!!errorDialogState) && <ErrorDialog dialogState={ errorDialogState } open={ !!errorDialogState }
                                             onClose={ () => setErrorDialogState(null) }/> }
    </Dialog>
  );
}

export default AddForecastDialog;