import React from 'react';
import {Area, AreaChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {mapChartColor} from 'components/forecast/forecastCharts/charts-color-mapper';
import {ChartInput} from 'components/forecast/forecastCharts/chart-input-selector';
import {autoFormatValue} from 'lib/value-formatter';

export function AppStackedBarChart(props: { input: ChartInput }) {
  const input = props.input;
  const chart = props.input.chart;
  const data = props.input.data;

  const tooltipFormatter = (value: string, name: string, props: any) => {
    return valueFormatter(Number.parseFloat(value));
  };

  const valueFormatter = (value: number) => autoFormatValue(value);

  const isPrintView = props.input.isPrintView;
  const isAnimationActive = !isPrintView;

  return <ResponsiveContainer height="100%" width="99%">
    <AreaChart
      data={ data }
    >
      <CartesianGrid
        strokeDasharray="4 4"
      />
      <XAxis
        dataKey={ chart?.xAxisId }
        fontSize={ input.axisFontSize }
        height={ 60 }
      >
        <Label
          value={ chart?.xAxisLabel }
          position="insideBottom"
          offset={ 3 }
          style={ {color: '#555555'} }
          fontSize={ input.axisFontSize }
          height={ 80 }
        />
      </XAxis>
      <YAxis
        tickFormatter={ valueFormatter }
        offset={ -3 }
        fontSize={ input.axisFontSize }
        width={ 120 }
      />
      <Legend
        verticalAlign="top"
        height={ 50 }
        fontSize={ input.axisFontSize }
        iconSize={ 20 }
      />
      {
        !isPrintView &&
        <Tooltip
          formatter={ tooltipFormatter }
          isAnimationActive={ false }
        />
      }
      {
        chart && chart.dataSeries.map((ds, n) =>
          <Area
            key={ n }
            stackId="a"
            name={ ds.name }
            type="linear"
            dataKey={ ds.id }
            fill={ mapChartColor(ds.color) }
            stroke={ mapChartColor(ds.color) }
            strokeWidth={ isPrintView ? 2 : 4 }
            isAnimationActive={ isAnimationActive }
          />,
        )
      }
    </AreaChart>
  </ResponsiveContainer>;
}

export default AppStackedBarChart;
