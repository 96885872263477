import React, {KeyboardEvent, useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import ErrorDialog from 'components/ErrorDialog';
import {fontSizes} from 'style/vars';
import CustomInput from 'components/customInput/CustomInput';
import {CommandErrorResult, PostCommandType, PostIdCommandType} from 'lib/command-client';
import AppButton from 'components/appButton/AppButton';
import styled from 'styled-components';

export type Validator = (name: string) => boolean;

export interface SingleInputDialogProps<T extends { type: string }> {
  open: boolean,
  onClose: (id: undefined | string) => void,
  buildCommand: (newName: string) => (T | undefined),
  postCommand: PostIdCommandType | PostCommandType,
  title: string,
  buttonText: string,
  textInputLabel: string,
  validateName: Validator,
}

export function SingleInputDialog<T extends { type: string }>(props: SingleInputDialogProps<T>) {
  const {postCommand, open, onClose, buildCommand, title, buttonText, textInputLabel, validateName} = props;
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | undefined>(undefined);

  let inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        console.log(inputRef.current);
        let input = inputRef.current;
        if (!input)
          return;

        input.focus();
        input.select();
        console.log('focused');
      }, 0);
    }
  }, [open]);

  async function handleKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter' && enableSave) {
      await handlePrimaryClicked();
    } else if (event.key === 'Esc') {
      handleCancelClicked();
    }
  }

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handlePrimaryClicked = async () => {
    setSaving(true);
    const cmd = buildCommand(name);
    if (!cmd) {
      setSaving(false);
      return;
    }
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState(result);
    } else {
      setSaving(false);
      setName('');
      onClose(result.id ?? '');
    }
  };

  const handleCancelClicked = () => {
    setName('');
    onClose(undefined);
  };

  const error = name !== '' && !validateName(name);
  const enableSave = !error && name !== '';

  return (
    <Dialog open={ open } onClose={ () => onClose(undefined) } onKeyDown={handleKeyDown}>
      <DialogPaper>
        <DialogTitle>
          { title }
        </DialogTitle>

        <DialogInputContainer>
          <CustomInput value={ name }
                       label={ textInputLabel }
                       error={ error }
                       onChange={ handleNameChange }
                       disabled={ saving }
                       autoFocus={true}
                       inputRef={inputRef}
          />
        </DialogInputContainer>

        <DialogButtonPanel>
          <AppButton text={ buttonText }
                     variant="filledPrimary"
                     onClick={ handlePrimaryClicked }
                     disabled={ !enableSave }
          />
          <AppButton text="Cancel"
                     variant="outlinedSecondary"
                     onClick={ handleCancelClicked }
                     disabled={ saving }
          />
        </DialogButtonPanel>
      </DialogPaper>
      {
        (!!errorDialogState) &&
        <ErrorDialog dialogState={ errorDialogState }
                     open={ !!errorDialogState }
                     onClose={ () => setErrorDialogState(undefined) }
        />
      }
    </Dialog>
  );
}

const DialogPaper = styled.div`
  padding: 15px;
`;

const DialogTitle = styled.div`
  padding: 10px;
  font-size: ${ fontSizes.l };
  width: 300px;
  margin-right: 600px;
`;

const DialogInputContainer = styled.div`
  padding: 15px;
`;

const DialogButtonPanel = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-right: 15px;
`;
