import React, {useEffect} from 'react';
import * as s from './forecast-welcome-styles';
import * as cs from 'components/forecast/forecast-common-styles';
import * as icons from 'style/Icons';
import {useGetWelcomeStatusQuery} from 'apiSlices/forecasting-api-slice';
import {useAppSelector} from 'app/hooks';
import {useNavigate} from 'react-router-dom';
import * as routeBuilder from 'lib/route-builder';

interface TaskProps {
  completed: boolean;
  link?: string,
}

export const Task = (props: React.PropsWithChildren<TaskProps>) => {
  const {completed, children, link} = props;
  const icon = completed ? <icons.CompletedTask/> : <icons.Tasks/>;

  const navigate = useNavigate();

  const handleClick = () => {
    if (completed)
      return;
    if (!link)
      return;
    navigate(link);
  }
  return (
    <s.TaskRow completed={ completed } onClick={() => handleClick() }>
      <s.TaskIcon completed={ completed }>{ icon }</s.TaskIcon>
      <s.TaskLabel completed={ completed }>{ children }</s.TaskLabel>
    </s.TaskRow>
  );
};

export const ForecastWelcome = () => {
  const forecastId = useAppSelector(s => s.ui.activeForecastId) ?? '';
  const tenantId = useAppSelector(s => s.ui.activeTenantId) ?? '';
  const scenarioId = useAppSelector(s => s.ui.activeScenarioId) ?? '';

  const forecastSequence = useAppSelector(s => s.ui.activeForecastStatus?.updateSequence);
  let {data, refetch} = useGetWelcomeStatusQuery(forecastId, {skip: forecastId == ''});

  useEffect(() => {
    if (data?.forecastSequence !== undefined && forecastSequence !== undefined && (data?.forecastSequence !== forecastSequence)) {
      refetch();
    }
  }, [refetch, data?.forecastSequence, forecastSequence]);

  if (!data)
    return <>Bad forecast id</>;

  return <s.WelcomeContainer>
    <s.TitleSection>
      <cs.PageTitle>Welcome</cs.PageTitle>
      <s.WelcomeBlurb>
        Now that you've created a forecast, it's time to get started uploading your input and building your first
        forecast scenario.
        Please review the list of getting started tasks below, and don't hesitate to contact us at <a
        href="mailto:support@govinvest.com">support@govinvest.com</a> for help.
      </s.WelcomeBlurb>
    </s.TitleSection>
    <s.SectionBox>
      <cs.SectionHeading>
        Getting Started
      </cs.SectionHeading>
      <s.WelcomeBlurb>
        The following tasks are here to help you get started building your forecast. Click on the task for next steps on
        how to
        complete that task.
      </s.WelcomeBlurb>
      <s.TaskSection>
        <Task completed={ !data.needsChartOfAccounts } link={routeBuilder.getDataChartOfAccounts(tenantId, forecastId)}>
          Upload your chart of accounts
        </Task>
        <Task completed={ !data.needsAccountDetail } link={routeBuilder.getDataAccountDetail(tenantId, forecastId)}>
          Upload details about your accounts
        </Task>
        <Task completed={ !data.needsFundBalances } link={routeBuilder.getDataFundBalances(tenantId, forecastId)}>
          Upload your fund balances
        </Task>
        <Task completed={ !data.needsBudgets } link={routeBuilder.getDataBudgets(tenantId, forecastId)}>
          Upload details about your budget
        </Task>
        <Task completed={ false } link={routeBuilder.getForecastBudgetBuilder(tenantId, forecastId, scenarioId)}>
          Create scenarios in the budget builder
        </Task>
        <Task completed={ false } link={routeBuilder.getForecastChartsRoute(tenantId, forecastId, scenarioId)}>
          Explore the forecast results from the charting page
        </Task>
        <Task completed={ false } link={routeBuilder.getForecastBudgetExplorerRoute(tenantId, forecastId, scenarioId)}>
          Use the Budget Explorer to dive deep into your forecast
        </Task>
      </s.TaskSection>
    </s.SectionBox>
  </s.WelcomeContainer>;
};
export default ForecastWelcome;
