import LZString from 'lz-string';
import {ForecastChartsInput} from './chart-input-selector';
import {getEpocSeconds} from 'lib/date-time-utils';
import * as htmlToImage from 'html-to-image';

export interface PrintLocalStorage {
  prints: PrintData[],
}

export interface PrintData {
  printId: number,
  chartInput?: ForecastChartsInput,
  chartPngString?: string,
}

const storageKey = 'print-data';
export const showPrintView = async (input: ForecastChartsInput) => {

  const chartContainerEl = document.getElementById('chart-container');
  if (!chartContainerEl)
    throw new Error('chartContainerEl not defined');
  const png = await htmlToImage.toPng(chartContainerEl);

  let printLocalStorage: PrintLocalStorage = {prints: []};
  let serializedPrintData = sessionStorage.getItem(storageKey);
  if (!!serializedPrintData)
    printLocalStorage = JSON.parse(LZString.decompress(serializedPrintData) as string);

  const newPrintIdEpocSecondsNow = Math.round(getEpocSeconds());

  //start by clearing out old ones
  const anHourAgoEpocSeconds = newPrintIdEpocSecondsNow - 360;
  printLocalStorage.prints = printLocalStorage.prints.filter(x => x.printId > anHourAgoEpocSeconds);

  //then clear out more if there are > 10
  if (printLocalStorage.prints.length > 10)
    printLocalStorage.prints = printLocalStorage.prints.slice(-10);

  const newPrintData = {
    printId: newPrintIdEpocSecondsNow,
    chartInput: input,
    chartPngString: png,
  };
  printLocalStorage.prints.push(newPrintData);

  const serialized = JSON.stringify(printLocalStorage);
  const compressed = LZString.compress(serialized);
  sessionStorage.setItem(storageKey, compressed);

  const loc = window.location;
  const printUrl = loc.protocol + '//' + loc.host + '/' + loc.pathname.split('/')[1] + `/printable?printId=${ newPrintIdEpocSecondsNow }`;
  const win = window.open(printUrl, '_blank');
  win?.postMessage('hello', window.location.origin);
  win?.focus();
};

export const getPrintView = (printId: number) => {
  let serializedPrintData = sessionStorage.getItem(storageKey);
  if (!serializedPrintData)
    throw new Error('print local storage not found');
  const printLocalStorage = JSON.parse(LZString.decompress(serializedPrintData) as string) as PrintLocalStorage;
  const printData = printLocalStorage.prints.find(x => x.printId === printId);
  if (!printData)
    throw new Error('print data not found');
  return printData;
};