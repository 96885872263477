import React, {ChangeEvent, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import debounce from 'lodash.debounce';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon, ListItemText} from '@mui/material';
import '@fortawesome/fontawesome-free-solid';
import {useGetActiveForecastsByTenantIdQuery, useGetTenantsQuery} from 'apiSlices/forecasting-api-slice';
import Spinner from 'components/Spinner';
import SearchBox from 'components/searchBox/SearchBox';
import {sortAlphabeticallyByField} from 'lib/util';
import AddForecastDialog from './addForecastDialog/AddForecastDialog';
import * as c from './forecast-list-styles';
import Button from 'components/appButton/AppButton';
import * as routeBuilder from 'lib/route-builder';
import * as icons from 'style/Icons';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';
import {DeleteForecastApiCommand, UploadForecastApiCommand} from 'apiCommands/api-commands';
import {usePostCommand} from 'lib/command-client';
import * as dtUtils from 'lib/date-time-utils';
import DuplicateForecastDialog from './duplicateForecastDialog/DuplicateForecastDialog';
import CopyForecastToTenantDialog from './copyForecastToTenantDialog/CopyForecastToTenantDialog';
import Download from 'lib/download-util';
import {dataSizes} from 'lib/constants';
import {Forecast} from 'apiSlices/api-types';
import {DateTime} from 'luxon';
import TimeAgo from 'react-timeago';

function ForecastsList() {
  const params = useParams();
  const postCommand = usePostCommand();
  const tenantId = params.tenantId ?? '';
  const [filterString, setFilterString] = useState<string>('');
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState<boolean>(false);
  const [showCopyToTenantDialog, setShowCopyToTenantDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [forecast, setCurrentForecast] = useState<Forecast | null>(null);
  const showItemMoreMenu = Boolean(anchorEl);

  const forecastsQuery = useGetActiveForecastsByTenantIdQuery(tenantId, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const {
    data: forecasts,
    isLoading,
  } = forecastsQuery;

  const tenantsQuery = useGetTenantsQuery();
  const {
    data: tenants,
  } = tenantsQuery;
  const multipleTenants = !!tenants && tenants.length > 1;

  if (isLoading) {
    return <Spinner/>;
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterString(value);
  };

  if (!forecasts) {
    console.log('tenants null');
    return <></>;
  }

  const handleForecastMoreMenuClick = (e: React.MouseEvent<HTMLElement>, f: Forecast) => {
    setCurrentForecast(f);
    setAnchorEl(e.currentTarget);
  };

  const handleItemMoreClose = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handleDeleteClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (!forecast)
      return;
    setAnchorEl(null);
    setShowConfirmDialog(true);
  };

  const handleDuplicateClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (!forecast)
      return;
    setAnchorEl(null);
    setShowDuplicateDialog(true);
  };

  const handleCopyToTenantClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (!forecast)
      return;
    setAnchorEl(null);
    setShowCopyToTenantDialog(true);
  };

  const handleDuplicateDialogClose = (e: any) => {
    forecastsQuery.refetch();
    setShowDuplicateDialog(false);
  };

  const handleCopyForecastDialogClose = (e: any) => {
    forecastsQuery.refetch();
    setShowCopyToTenantDialog(false);
  };

  const handleInputDialogClosed = (e: any) => {
    forecastsQuery.refetch();
    setShowAddDialog(false);
  };

  const handleDownloadClicked = async (e: React.MouseEvent<HTMLElement>) => {
    if (!forecast)
      return;
    setAnchorEl(null);
    await Download(`/api/forecasts/package/${ forecast.id }`);
  };

  const handleUploadClicked = async (e: React.MouseEvent<HTMLElement>) => {
    document?.getElementById('upload-forecast')?.click();
  };
  const handleUploadForecastOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files)
      return;
    if (files.length === 0)
      return;
    if (!files[0])
      return;
    const file = files[0];
    if (file.size > dataSizes.size100MB) {
      alert('This file is too large to import.');
      return;
    }

    async function onReaderLoad(event: any) {
      const forecastObj = JSON.parse(event.target.result);
      const cmd: UploadForecastApiCommand = {
        type: 'UploadForecastApiCommand',
        tenantId: tenantId,
        forecast: forecastObj,
      };
      await postCommand(cmd);
      forecastsQuery.refetch();
      // @ts-ignore
      document.getElementById('upload-forecast').value = '';
    }

    const reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(file);

    reader.onload = onReaderLoad;
  };

  const deleteAction = async () => {
    if (!forecast)
      return;
    const cmd: DeleteForecastApiCommand = {
      type: 'DeleteForecast',
      tenantId: forecast.tenantId,
      forecastId: forecast.id,
    };
    await postCommand(cmd);
    await forecastsQuery.refetch();
  };

  const handleConfirmDelete = () => {
    setShowConfirmDialog(false);
  };

  const handleCloseDeleteConfirm = () => {
    setShowConfirmDialog(false);
  };

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);
  const filtered = forecasts.filter(t => t.name.toLowerCase().includes(filterString.toLowerCase()));
  const filteredSorted = sortAlphabeticallyByField(filtered, 'name') as Forecast[];

  const getFiscalYearLabel = (f: Forecast) => {
    const fyYear = dtUtils.getFyYearYY(f.cyStartDate);
    return 'FY' + fyYear;
  };

  const chopDescriptionText = (src: string) => {
    const newLineSplit = src.split('\n');
    if (newLineSplit[0].length > 400)
      return newLineSplit[0].substring(0, 400).trimEnd() + '...';
    return newLineSplit[0];
  };

  function LastUpdate(f: Forecast) {
    if (!f.lastUpdate)
      return <></>;

    const lastUpdate = DateTime.fromISO(f.lastUpdate).toLocal();
    return <>
      <c.TimestampLabel>
        Last update:
        <c.CalendarIcon icon="calendar"/>
        {lastUpdate.toLocaleString() }
        &nbsp; - <TimeAgo date={lastUpdate.toString()}/>
      </c.TimestampLabel>
    </>
  }

  return (
    <>
      <c.PageContainer>
        <c.HeaderSection>
          <c.HeaderLeft>
            <c.Title>Forecasts</c.Title>
            <SearchBox onChange={ debouncedHandleSearchChange }/>
          </c.HeaderLeft>
          <c.HeaderRight>
            <Button text="New Forecast" onClick={ () => setShowAddDialog(true) } icon={ <icons.Add/> } size="large"/>
            <Button text="Upload" onClick={ handleUploadClicked } icon={ <icons.Upload/> } size="large"/>
            <Button text="Recycle Bin" linkTo={ routeBuilder.getForecastRecycleBinRoute(tenantId) }
                    icon={ <icons.Trash/> } size="large"/>
          </c.HeaderRight>
        </c.HeaderSection>
        <c.ForecastsListContainer>
          {
            filteredSorted.length === 0 &&
            <c.ForecastName
              style={ {textAlign: 'center'} }
              onClick={ () => setShowAddDialog(true) }>
              Create your first forecast
            </c.ForecastName>
          }
          {
            filteredSorted.map((f, n) => (
              <div style={{display: 'block'}}>
              <c.ForecastListItem key={ n }>
                <c.ForecastListItemRow1>
                  <c.ForecastListItemRow1Left>
                    <c.FiscalYearLabel>
                      <Link to={ routeBuilder.getForecastRoute(tenantId, f.id) } style={ {color: '#fff'} }>
                        { getFiscalYearLabel(f) }
                      </Link>
                    </c.FiscalYearLabel>
                    <Link to={ routeBuilder.getForecastRoute(tenantId, f.id) }>
                      <c.ForecastName>
                        { f.name }
                      </c.ForecastName>
                    </Link>
                  </c.ForecastListItemRow1Left>
                  <c.ForecastListItemRow1Right>
                    <Button
                      text="Open"
                      linkTo={ routeBuilder.getForecastRoute(tenantId, f.id) }
                      icon={ <icons.FileOpen/> }
                    />
                    <c.DropdownIconContainer onClick={ (e) => handleForecastMoreMenuClick(e, f) }>
                      <Button icon={ <icons.MoreHoriz/> }/>
                    </c.DropdownIconContainer>
                  </c.ForecastListItemRow1Right>
                </c.ForecastListItemRow1>
                <c.ForecastListItemRow2>
                  { !!f.description && chopDescriptionText(f.description) }
                  { !f.description && <div style={ {fontStyle: 'italic'} }>(no description)</div> }
                </c.ForecastListItemRow2>
                <c.ForecastListItemRow3>
                  { LastUpdate(f) }
                </c.ForecastListItemRow3>
              </c.ForecastListItem>
              </div>
            ))
          }
        </c.ForecastsListContainer>
      </c.PageContainer>
      {
        showAddDialog &&
        <AddForecastDialog tenantId={ tenantId } onClose={ handleInputDialogClosed } open={ showAddDialog }/>
      }
      {
        showDuplicateDialog && <DuplicateForecastDialog
          open={ showDuplicateDialog }
          forecast={ forecast }
          tenantId={ tenantId }
          onClose={ handleDuplicateDialogClose }
        />
      }
      {
        showCopyToTenantDialog && <CopyForecastToTenantDialog
          open={ showCopyToTenantDialog }
          forecast={ forecast }
          tenantId={ tenantId }
          onClose={ handleCopyForecastDialogClose }
        />
      }
      <Menu
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ showItemMoreMenu }
        onClose={ handleItemMoreClose }
        MenuListProps={ {
          'aria-labelledby': 'basic-appButton',
        } }
      >
        <MenuItem onClick={ handleDuplicateClicked }>
          <ListItemIcon>
            <icons.Copy fontSize="small"/>
          </ListItemIcon>
          <ListItemText>
            Duplicate
          </ListItemText>
        </MenuItem>
        {
          multipleTenants &&
          <MenuItem onClick={ handleCopyToTenantClicked }>
            <ListItemIcon>
              <icons.Building fontSize="small"/>
            </ListItemIcon>
            <ListItemText>
              Copy to tenant
            </ListItemText>
          </MenuItem>
        }
        <MenuItem onClick={ handleDownloadClicked }>
          <ListItemIcon>
            <icons.CloudDownload fontSize="small"/>
          </ListItemIcon>
          <ListItemText>
            Download
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={ handleDeleteClicked }>
          <ListItemIcon>
            <icons.Trash fontSize="small"/>
          </ListItemIcon>
          <ListItemText>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>
      {
        showConfirmDialog &&
        <ConfirmDialog
          open={ showConfirmDialog }
          confirmState={ {
            confirmTitle: 'DELETE',
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete forecast ' + forecast?.name + '?',
          } }
          onConfirm={ handleConfirmDelete }
          onClose={ handleCloseDeleteConfirm }
          confirmAction={ deleteAction }
          confirmIcon={ <icons.Trash/> }
        />
      }
      <input
        type="file"
        id="upload-forecast"
        style={ {display: 'none'} }
        accept=".json"
        multiple={ false }
        onChange={ handleUploadForecastOnChange }
      />
    </>
  );
}

export default ForecastsList;
