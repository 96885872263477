import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {MenuItem} from '@mui/material';
import ErrorDialog from 'components/ErrorDialog';
import {fontSizes} from 'style/vars';
import CustomInput from 'components/customInput/CustomInput';
import {CommandErrorResult, usePostCommand} from 'lib/command-client';
import AppButton from 'components/appButton/AppButton';
import {ReorderStepApiCommand} from 'apiCommands/api-commands';
import {Step} from 'apiSlices/api-types';

export interface MoveStepDialogProps {
  forecastId: string,
  scenarioId: string,
  stepId: string,
  open: boolean,
  onClose: (event?: object, reason?: string) => void;
  steps: Step[],
}

function MoveStepDialog(props: MoveStepDialogProps) {
  const postCommand = usePostCommand();
  const {open, onClose, forecastId, scenarioId, stepId, steps} = props;
  const [saving, setSaving] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | null>(null);
  const [selectedStepId, setSelectedStepId] = useState<string>(steps[0].id);

  const handleMoveBeforeClicked = async () => {
    setSaving(true);
    const step = steps.find(x => x.id === selectedStepId);
    if (!step)
      return;
    const cmd: ReorderStepApiCommand = {
      type: 'ReorderStepApiCommand',
      forecastId: forecastId,
      scenarioId: scenarioId,
      stepId: stepId,
      order: step.order,
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      onClose();
    }
  };

  const handleCancelClicked = () => {
    onClose();
  };

  const handleStepSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStepId(e.target.value);
  };

  if (!steps || !selectedStepId)
    return <></>;

  return (
    <Dialog open={ open } onClose={ onClose }>
      <div style={ {padding: '15px'} }>
        <div style={ {padding: '10px', fontSize: fontSizes.l, width: '300px', marginRight: '600px'} }>
          Move Step Before
        </div>

        <div style={ {padding: '15px'} }>
          <CustomInput
            select={ true }
            label="Move Step Before"
            value={ selectedStepId }
            onChange={ handleStepSelectChange }
          >
            {
              steps && steps.map((s, n) =>
                <MenuItem key={ n } value={ s.id } onClick={ () => {
                } }>{ s.name }</MenuItem>,
              )
            }
          </CustomInput>
        </div>

        <div style={ {display: 'flex', flexDirection: 'row-reverse', gap: '20px', paddingRight: '15px'} }>
          <AppButton
            text="Move Before Step"
            variant="filledPrimary"
            onClick={ handleMoveBeforeClicked }
          />
          <AppButton
            text="Cancel"
            variant="outlinedSecondary"
            onClick={ handleCancelClicked }
            disabled={ saving }
          />
        </div>
      </div>
      { (!!errorDialogState) && <ErrorDialog dialogState={ errorDialogState } open={ !!errorDialogState }
                                             onClose={ () => setErrorDialogState(null) }/> }
    </Dialog>
  );
}

export default MoveStepDialog;
