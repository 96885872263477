export const formatValue = (value: number, minimumFractionDigits = 0) => {
  let fmt = value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minimumFractionDigits,
  });
  return fmt;
};

export const formatPercent = (value: number) => {
  let fmt = value.toLocaleString('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  });
  return fmt;
};

export function autoFormatValue(value: number, shift: number = 0) {
  const absVal = Math.abs(value);
  if (absVal < 1e-7)
    return '0';

  const sign = Math.sign(value);
  const log = Math.log10(absVal) - shift;

  let [scale, unit] = [1, ''];
  if (log >= 10)
    [scale, unit] = [1e9, ' B'];
  else if (log >= 7)
    [scale, unit] = [1e6, ' M'];
  else if (log >= 4)
    [scale, unit] = [1e3, ' K'];

  const scaled = sign * absVal / scale;

  let fmt = scaled.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return `${ fmt }${ unit }`;
}
