import React, {useState} from 'react';
import CustomInput from 'components/customInput/CustomInput';
import {MenuItem} from '@mui/material';
import {useGetBudgetNamesQuery} from 'apiSlices/forecasting-api-slice';
import styled from 'styled-components';
import {usePostCommand} from 'lib/command-client';
import {SelectScenarioBaseBudgetApiCommand} from 'apiCommands/api-commands';
import {useAppSelector} from 'app/hooks';

export const BaseBudgetSelector = () => {
  const forecastId = useAppSelector(s => s.ui.activeForecastId);
  const scenarioId = useAppSelector(s => s.ui.activeScenarioId);
  const budgetNamesQuery = useGetBudgetNamesQuery(forecastId ?? '', {skip: !forecastId});

  const [saving, setSaving] = useState(false);
  const baseBudgetName = useAppSelector(s => s.ui.activeScenario?.baseBudgetName);

  const postCommand = usePostCommand();

  const handleBaseBudgetChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!forecastId || !scenarioId)
      return;
    const newBaseBudget = e.target.value;
    setSaving(true);
    const cmd: SelectScenarioBaseBudgetApiCommand = {
      type: 'SelectScenarioBaseBudgetApiCommand',
      forecastId: forecastId,
      scenarioId: scenarioId,
      baseBudgetName: newBaseBudget,
    };
    await postCommand(cmd);
    setSaving(false);
  };

  if (!forecastId)
    return <></>;

  const baseBudgetNames = budgetNamesQuery.data ?? [];
  const error = !(baseBudgetName);
  const notFound = baseBudgetNames.find(x => x === baseBudgetName) === undefined;
  const disabled = saving || !scenarioId;
  const noBudgets = baseBudgetNames.length === 0;

  return <BaseBudgetSelectionContainer>
    <CustomInput
      select={ true }
      label="Base Budget"
      value={ notFound || !baseBudgetName ? 'none' : baseBudgetName }
      onChange={ handleBaseBudgetChanged }
      error={ error || notFound }
      disabled={ disabled || noBudgets }
    >
      {
        (notFound || !baseBudgetName) && <MenuItem key={ 'none' } value={ 'none' } onClick={ () => {
        } } dense={ true }></MenuItem>
      }
      {
        baseBudgetNames && baseBudgetNames.map((n) =>
          <MenuItem key={ n } value={ n } onClick={ () => {
          } } dense={ true }>{ n }</MenuItem>,
        )
      }
    </CustomInput>
  </BaseBudgetSelectionContainer>;
};

export default BaseBudgetSelector;

export const BaseBudgetSelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
