export const roles = {
  SysAdmin: 'SysAdmin',
};

export const availableDataTypes = {
  ChartOfAccounts: 'ChartOfAccounts',
  VendorList: 'VendorList',
  AccountDetail: 'AccountDetail',
  FundList: 'FundList',
  FundBalances: 'FundBalances',
  Budgets: 'Budgets',
};

export const dataSizes = {
  size100MB: 1024 * 1024 * 100,
};
