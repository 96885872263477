import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import {useGetTenantsQuery} from 'apiSlices/forecasting-api-slice';
import Spinner from 'components/Spinner';
import TenantImage from 'components/tenantImage/TenantImage';
import SearchBox from 'components/searchBox/SearchBox';
import {sortAlphabeticallyByField} from 'lib/util';
import * as routeBuilder from 'lib/route-builder';
import {getTenantForecastsRoute} from 'lib/route-builder';
import {Tenant} from 'apiSlices/api-types';

function TenantsList() {
  const [filterString, setFilterString] = useState<string>('');
  const navigate = useNavigate();

  const {
    data: tenants,
    isLoading,
  } = useGetTenantsQuery();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterString(value);
  };

  if (!tenants) {
    console.log('tenants null');
    return <></>;
  }

  if (tenants.length === 1)
    navigate(routeBuilder.getTenantForecastsRoute(tenants[0].id), {replace: true});

  if (isLoading) {
    return <Spinner/>;
  }

  const debouncedHandleSearchChange = debounce(handleSearchChange, 300);
  const filteredTenants = tenants.filter(t => t.name.toLowerCase().includes(filterString.toLowerCase()));
  const filteredSortedTenants = sortAlphabeticallyByField(filteredTenants, 'name') as Tenant[];

  return (
    <>
      <PageContainer>
        <HeaderSection>
          <Title>Tenants</Title>
          <SearchBox onChange={ debouncedHandleSearchChange }/>
        </HeaderSection>
        <TenantsListContainer>
          {
            filteredSortedTenants.map((t, n) => (
              <TenantListItem key={ n }>
                <TenantImage tenantId={ t.id } width="40px"/>
                <TenantName>
                  <Link to={ getTenantForecastsRoute(t.id) }>
                    { t.name }
                  </Link>
                </TenantName>
              </TenantListItem>
            ))
          }
        </TenantsListContainer>
      </PageContainer>
    </>
  );
}

export default TenantsList;

const PageContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;
  height: 100%;
  overflow-y: clip;
  width: 600px;
`;
const HeaderSection = styled.div`
  flex-direction: row;
  display: flex;
  gap: 15px;
  align-items: center;
`;
const Title = styled.div`
  font-size: var(--font-xl);
  font-weight: 300;
  padding: 15px;
`;
const TenantsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  overflow-y: scroll;
  flex-grow: 1;
  height: 100px;
`;
const TenantListItem = styled.div`
  height: 60px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  border-bottom: 0.5px lightgray solid;
`;
const TenantName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
