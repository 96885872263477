import React, {ChangeEvent, useState} from 'react';
import * as s from './forecast-settings-styles';
import AppButton from 'components/appButton/AppButton';
import CustomInput from 'components/customInput/CustomInput';
import {useGetForecastQuery} from 'apiSlices/forecasting-api-slice';
import {validateDescription} from 'lib/validators';
import {UpdateForecastDescriptionApiCommand} from 'apiCommands/api-commands';
import {CommandErrorResult, usePostCommand} from 'lib/command-client';
import ErrorDialog from 'components/ErrorDialog';

export const DescriptionSetting = (props: { forecastId?: string }) => {
  const forecastId = props.forecastId ?? '';
  const forecastQuery = useGetForecastQuery(forecastId, {refetchOnMountOrArgChange: true});
  const {data: forecast, isLoading} = forecastQuery;
  const postCommand = usePostCommand();
  const [newDescription, setNewDescription] = useState(forecast?.description ?? null);
  const [isValid, setIsValid] = useState(true);
  const [saving, setSaving] = useState(false);
  const [trailingText, setTrailingText] = useState('Saved');
  const [showTrailingText, setShowTrailingText] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | null>(null);
  if (!forecast)
    return <></>;
  if (newDescription === null)
    return <></>;
  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = validateDescription(e.target.value);
    setIsValid(isValid);
    setNewDescription(e.target.value);
    setShowTrailingText(false);
    setTrailingText('Saved');
  };
  const handleCancelClicked = (e: React.MouseEvent<HTMLElement>) => {
    reset();
  };
  const handleUpdateClicked = async (e: React.MouseEvent<HTMLElement>) => {
    if (!forecast)
      return;
    setSaving(true);
    const cmd: UpdateForecastDescriptionApiCommand = {
      type: 'UpdateForecastDescription',
      tenantId: forecast.tenantId,
      forecastId: forecast.id,
      description: newDescription,
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      forecastQuery.refetch();
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      forecastQuery.refetch();
    }
  };
  const reset = () => {
    setNewDescription(forecast.description);
    setIsValid(true);
    setShowTrailingText(false);
  };
  const handleErrorDialogClose = () => {
    setErrorDialogState(null);
    setNewDescription(forecast.description);
  };
  const handleOnKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      reset();
    }
  };
  const getNumRows = (description: string) => {
    if (description == null)
      return 1;
    return Math.min(Math.ceil(description.length / 100), 20);
  };
  const saveEnabled = isValid
    && forecast.description !== newDescription
    && !saving
    && !forecastQuery.isFetching;
  const cancelEnabled = forecast.description !== newDescription
    && !saving
    && !forecastQuery.isFetching;
  const showButtons = newDescription !== forecast.description;
  const updateText = saving ? 'Saving...' : 'Update';

  return <s.InputSettingsSection>
    <s.InputLabel>Description</s.InputLabel>
    <s.DescriptionInputSection>
      <div style={ {width: '700px'} }>
        <CustomInput
          value={ newDescription }
          onChange={ handleValueChange }
          onKeyUp={ handleOnKeyUp }
          disabled={ isLoading }
          error={ !isValid }
          multiline={ true }
          rows={ getNumRows(newDescription) }
        />
      </div>
      {
        showButtons && <div style={ {display: 'flex', flexDirection: 'column', alignItems: 'top'} }>
          <div style={ {display: 'flex', flexDirection: 'row', gap: '20px'} }>
            <AppButton
              text={ updateText }
              variant="filledPrimary"
              onClick={ handleUpdateClicked }
              disabled={ !saveEnabled }
            />
            <AppButton
              text="Cancel"
              variant="outlinedSecondary"
              onClick={ handleCancelClicked }
              disabled={ !cancelEnabled }
            />
          </div>
          <div></div>
        </div>
      }
      {
        showTrailingText &&
        <div style={ {margin: 'auto 0px', fontStyle: 'italic'} }>{ trailingText }</div>
      }
    </s.DescriptionInputSection>
    { (!!errorDialogState) &&
      <ErrorDialog dialogState={ errorDialogState } open={ !!errorDialogState } onClose={ handleErrorDialogClose }/> }
  </s.InputSettingsSection>;
};
export default DescriptionSetting;
