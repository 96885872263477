import {CommandErrorResult, pollForCommandStatus, postAsyncCommand} from 'lib/command-client';
import {ImportFileApiCommand} from 'apiCommands/api-commands';
import {triggerInvalidate} from 'apiCommands/tag-invalidation';

export interface ImportWorkflowParameters {
  name: string,
  description: string,
  file: File,
  tenantId: string,
  forecastId: string,
  dataFileType: string,
}

export interface FileImportResult {
  numRecords: number,
  succeeded: boolean,
  validationMessages: FileImportValidationMessage[]
}

export interface FileImportValidationMessage {
  level: 'Info' | 'Warning' | 'Error',
  message: string,
  detail?: string,
  rowNumber?: number,
}

export class ImportWorkflow {
  public succeeded: boolean = false;
  public error: CommandErrorResult | null = null;
  public importedDataFileId: string | null = null;
  public importResult: FileImportResult | null = null;

  private _input: ImportWorkflowParameters;

  constructor(input: ImportWorkflowParameters) {
    this._input = input;
  }

  async Execute(dispatch: ((action: any) => void)) {
    const data = new FormData();
    data.append('file', this._input.file as Blob);

    console.log('Posting import file');
    const postDataResult = await fetch(`/api/datafiles/import`, {
      method: 'POST',
      body: data,
    });
    if (postDataResult.status >= 300) {
      try {
        const content = await postDataResult.json();
        this.error = {error: content.error, errorDescription: content.errorDescription} as CommandErrorResult;
        this.succeeded = false;
        return;
      } catch (e) {
        this.error = {
          error: 'bad_http_status',
          errorDescription: 'Unexpected status code result: ' + postDataResult.status,
        };
        this.succeeded = false;
        return;
      }
    }
    const content = await postDataResult.json();
    if (!content.id)
      throw new Error('Expected id to be returned from POST');
    console.log('importFileId: ' + content.id);
    this.importedDataFileId = content.id as string;

    this.succeeded = true;

    console.log('Posting import command');
    const cmd: ImportFileApiCommand = {
      type: 'ImportFileApiCommand',
      name: this._input.name,
      description: this._input.description,
      tenantId: this._input.tenantId,
      forecastId: this._input.forecastId,
      dataFileType: this._input.dataFileType,
      importFileId: this.importedDataFileId,
      originalFilename: this._input.file.name,
    };

    const cmdResult = await postAsyncCommand(cmd);
    if (cmdResult.error) {
      this.error = {error: cmdResult.error, errorDescription: cmdResult.errorDescription};
      this.succeeded = false;
    }
    if (!cmdResult.commandId)
      throw new Error('Expected commandId on post response');
    console.log('cmdId:' + cmdResult.commandId);

    console.log('Polling for status');
    const cmdStatus = await pollForCommandStatus(cmdResult.commandId);
    if (cmdStatus.status === 'Error' || !!cmdStatus.error) {
      console.log('Error command status');
      this.error = {error: cmdStatus.error, errorDescription: cmdStatus.errorDescription};
      this.succeeded = false;
      return;
    }

    if (!cmdStatus.payload || !cmdStatus.payload.id) {
      throw new Error('Expected a payload to have an id');
    }

    const importId = cmdStatus.payload.id;

    console.log('Getting import results');
    const getImportResult = await fetch(`/api/datafiles/import/${ importId }`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (getImportResult.status >= 300) {
      try {
        const content = await postDataResult.json();
        this.error = {error: content.error, errorDescription: content.errorDescription} as CommandErrorResult;
        this.succeeded = false;
        return;
      } catch (e) {
        this.error = {
          error: 'bad_http_status',
          errorDescription: 'Unexpected status code result: ' + postDataResult.status,
        };
        this.succeeded = false;
        return;
      }
    }
    console.log('Completed import received');
    const getImportContent = await getImportResult.json();
    this.importResult = getImportContent.importResult as FileImportResult;

    this.succeeded = this.importResult.succeeded;

    triggerInvalidate<ImportFileApiCommand>('ImportFileApiCommand', dispatch);
  }

}