import React from 'react';
import * as s from './forecast-settings-styles';
import {useGetForecastQuery} from 'apiSlices/forecasting-api-slice';

export const CyStartDateSetting = (props: { forecastId?: string }) => {
  const forecastId = props.forecastId ?? '';
  const forecastQuery = useGetForecastQuery(forecastId, {refetchOnMountOrArgChange: true});
  const {data: forecast} = forecastQuery;
  if (!forecast)
    return <></>;

  return <s.InputSettingsSection>
    <s.InputLabel>Current Year Start Date</s.InputLabel>
    <s.CyStartDateSetting>
      { forecast.cyStartDate }
      <br/>
      The start date cannot be changed after creating the forecast.
    </s.CyStartDateSetting>
  </s.InputSettingsSection>;
};
export default CyStartDateSetting;
