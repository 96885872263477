import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as s from './fitler-styles';
import {
  ChartFilterState,
  ChartFilterValueState,
  filterCleared,
  filterRemoved,
  FilterValueToggled,
  filterValueToggled,
} from 'storeSlices/charts-slice';
import AppButton from 'components/appButton/AppButton';
import * as icons from 'style/Icons';
import {Divider, Menu, MenuItem} from '@mui/material';

export function Filter(props: { input: ChartFilterState }) {
  const dispatch = useDispatch();
  const input = props.input;
  const dimension = input.dimension;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleRemoveFilter = () => {
    setAnchorEl(null);
    dispatch(filterRemoved(dimension.id));
  };
  const handleClearFilter = () => {
    dispatch(filterCleared(dimension.id));
  };
  const handleFilterValueClicked = (fv: ChartFilterValueState) => {
    const act: FilterValueToggled = {dimensionId: dimension.id, value: fv.name};
    dispatch(filterValueToggled(act));

    // if we're a single select, just close the menu
    if (input.singleSelect)
      setAnchorEl(null);
  };

  const filterValues = input.values;
  const disableClear = !filterValues.find(x => x.enabled);

  let globalItems = !input.singleSelect
    ? [
      <MenuItem key={ 'remove' } value={ 'remove' }>
        <AppButton
          text="Remove Filter"
          icon={ <icons.Trash/> }
          onClick={ handleRemoveFilter }
        />
      </MenuItem>,
      <MenuItem key={ 'clear' } value={ 'clear' }>
        <AppButton
          text="Clear Filter"
          icon={ <icons.X/> }
          onClick={ handleClearFilter }
          disabled={ disableClear }
        />
      </MenuItem>,
      <Divider/>,
    ]
    : [];

  let checkMark = input.singleSelect
    ? <icons.SingleChecked/>
    : <icons.Checked/>;

  let uncheckMark = input.singleSelect
    ? <icons.SingleUnchecked/>
    : <icons.Unchecked/>;

  let buttonLabel = input.singleSelect
    ? `${ dimension.name }: ${ input.values.find(v => v.enabled)?.name }`
    : dimension.name;

  return <s.FilterContainer key={ dimension.id }>
    <AppButton
      text={ buttonLabel }
      icon={ <icons.DownChevron/> }
      onClick={ handleClick }
    />
    <Menu
      id="basic-menu"
      anchorEl={ anchorEl }
      open={ showMenu }
      onClose={ handleMenuClose }
      MenuListProps={ {
        'aria-labelledby': 'basic-appButton',
      } }
    >
      { globalItems }
      {
        filterValues.map((fv, n) =>
          <MenuItem key={ n } value={ n }>
            <AppButton
              text={ fv.name }
              icon={ fv.enabled ? checkMark : uncheckMark }
              onClick={ () => handleFilterValueClicked(fv) }
            />
          </MenuItem>,
        )
      }

    </Menu>
  </s.FilterContainer>;
}

export default Filter;
