import {createTheme} from '@mui/material/styles';
import {colors} from './colors';

export const muiTheme = createTheme({
  status: {
    danger: colors.red,
  },
  palette: {
    primary: {
      main: colors.themePrimaryLight,
      darker: colors.themePrimary,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.secondary,
      darker: colors.secondary,
      light: '#fff',
      contrastText: '#fff',
    },
    info: {
      main: colors.darkGrey,
      darker: colors.darkGrey,
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }

  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }

  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}