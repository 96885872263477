import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
`;
export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const FirstRowRight = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Title = styled.div`
  font-size: var(--font-l);
`;
export const ItemsRow = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 0.5px #d0d0d0 solid;
`;
export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.5px #d0d0d0 solid;
  padding: 10px;
`;
export const ItemRight = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ItemLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
export const ItemName = styled.div`
  padding-top:2px;
  padding-left: 5px;
  width: 200px;
  min-width: 250px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const ItemDescription = styled.div`
  padding-top:2px;
  max-width: 550px;
  font-style: italic;
`;
export const ItemIcon = styled.div`
  color: var(--color-secondary);
`;
export const ItemActiveChip = styled.div`
`;
export const ItemButtons = styled.div`
  display: flex;
  flex-direction: column;
`;
