import {Action, createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from 'store';
import {ChartDto, ChartsList, ChartType, Dimension, Metric} from 'apiSlices/chart-types';
import {buildChartData, buildTableData} from './chart-data-builder';

export interface ChartState {
  summaryExpanded: boolean,
  mainChartId?: string,
  mainChart?: ChartDto,
  mainChartData?: any,
  mainTableData?: any,
  mainChartMetrics?: Array<Metric> | undefined,
  chartsAvailable: boolean,
  mainChartType?: ChartType,
  mainChartFilters: ChartFiltersState,
  chartList?: ChartsList,
}

const initialState: ChartState = {
  summaryExpanded: true,
  chartsAvailable: false,
  mainChartFilters: {filters: new Array<ChartFilterState>()},
};

export interface ChartFiltersState {
  filters: ChartFilterState[],
}

export interface ChartFilterState {
  dimension: Dimension,
  values: ChartFilterValueState[],
  added: boolean,
  singleSelect: boolean,
}

export interface ChartFilterValueState {
  name: string,
  enabled: boolean,
}

export interface FilterValueToggled {
  dimensionId: string,
  value: string,
}

export const _updateMainChart = (state: ChartState) => {
  if (!state.mainChart || state.mainChart.calcNeeded)
    return;
  if (!state.mainChartType || !state.mainChart.supportedChartTypes.includes(state.mainChartType)) {
    state.mainChartType = state.mainChart.supportedChartTypes[0];
  }
  state.mainChartFilters = {filters: new Array<ChartFilterState>()};
  for (let filterDim of state.mainChart.filterDimensions ?? {}) {
    const filterValues = state.mainChart.filterDimensionValues.find(x => x.id === filterDim.id);
    if (!filterValues)
      throw new Error('Filter values not available for dimension:' + filterDim.id);

    const isSingleSelect = filterDim.singleSelect;

    const filter: ChartFilterState = {
      dimension: filterDim,
      values: filterValues.values.map(v => ({name: v, enabled: false})),
      added: isSingleSelect,
      singleSelect: isSingleSelect,
    };

    if (isSingleSelect && filter.values.length > 0)
      filter.values[0].enabled = true;

    state.mainChartFilters.filters.push(filter);
  }

  state.mainChartData = buildChartData(state.mainChart, state.mainChartFilters);
  state.mainTableData = buildTableData(state.mainChart, state.mainChartData);
  state.mainChartMetrics = state.mainChart.metrics;
};

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    summaryAreaExpandedToggled: (state, _: Action) => {
      state.summaryExpanded = !state.summaryExpanded;
    },
    chartDataUpdated: (state, action: PayloadAction<ChartDto>) => {
      state.chartsAvailable = true;
      state.mainChart = action.payload;
      _updateMainChart(state);
    },
    chartListUpdated: (state, action: PayloadAction<ChartsList>) => {
      state.chartList = action.payload;
      const defaultMainChartId = action.payload.defaultMainChartId;
      if (!state.mainChartId) {
        state.mainChartId = defaultMainChartId;
      }
      _updateMainChart(state);
    },
    mainChartSelectionChanged: (state, action: PayloadAction<string>) => {
      state.mainChartId = action.payload;
      _updateMainChart(state);
    },
    mainChartTypeSelectionChanged: (state, action: PayloadAction<ChartType>) => {
      state.mainChartType = action.payload;
    },
    filterAdded: (state, action: PayloadAction<string>) => {
      const filter = state.mainChartFilters.filters.find(x => x.dimension.id === action.payload);
      if (!filter)
        throw new Error('Filter not found: ' + action.payload);
      filter.added = true;
    },
    filterRemoved: (state, action: PayloadAction<string>) => {
      const filter = state.mainChartFilters.filters.find(x => x.dimension.id === action.payload);
      if (!filter)
        throw new Error('Filter not found: ' + action.payload);
      filter.added = false;
      filter.values.forEach(x => x.enabled = false);
      if (!state.mainChart)
        throw new Error('Missing mainChart (filterRemoved)');
      state.mainChartData = buildChartData(state.mainChart, state.mainChartFilters);
      state.mainTableData = buildTableData(state.mainChart, state.mainChartData);
    },
    filterValueToggled: (state, action: PayloadAction<FilterValueToggled>) => {
      const filter = state.mainChartFilters.filters.find(x => x.dimension.id === action.payload.dimensionId);
      if (!filter)
        throw new Error('Filter not found (filterValueToggled): ' + action.payload);
      const filterValue = filter.values.find(x => x.name === action.payload.value);
      if (!filterValue)
        throw new Error('Filter value not found (filterValueToggled): ' + action.payload.value);
      if (filter.singleSelect) {
        if (filterValue.enabled) return;
        filter.values.forEach(x => x.enabled = false);
        filterValue.enabled = true;
      } else {
        filterValue.enabled = !filterValue.enabled;
      }
      if (!state.mainChart)
        throw new Error('Missing mainChart (filterValueToggled)');
      state.mainChartData = buildChartData(state.mainChart, state.mainChartFilters);
      state.mainTableData = buildTableData(state.mainChart, state.mainChartData);
    },
    filterCleared: (state, action: PayloadAction<string>) => {
      const filter = state.mainChartFilters.filters.find(x => x.dimension.id === action.payload);
      if (!filter)
        throw new Error('Filter not found (filterCleared): ' + action.payload);
      for (let fv of filter.values)
        fv.enabled = false;
      if (!state.mainChart)
        throw new Error('Missing mainChart (filterValueToggled)');
      state.mainChartData = buildChartData(state.mainChart, state.mainChartFilters);
      state.mainTableData = buildTableData(state.mainChart, state.mainChartData);
    },
  },
});

export const {
  summaryAreaExpandedToggled,
  mainChartSelectionChanged,
  mainChartTypeSelectionChanged,
  filterAdded,
  filterRemoved,
  filterValueToggled,
  filterCleared,
  chartListUpdated,
  chartDataUpdated,
} = chartSlice.actions;

export const selectChartType = (state: RootState) => state.charts.mainChartType;
export const selectSummaryExpanded = (state: RootState) => false; /*state.charts.summaryExpanded*/

export const selectCharts = (state: RootState) => state.charts;
export const selectMainChartId = (state: RootState) => state.charts.mainChartId;

export default chartSlice.reducer;
