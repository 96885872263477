import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import * as s from './available-data-styles';
import AppButton from 'components/appButton/AppButton';
import {Chip, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import * as icons from 'style/Icons';
import useDataImportDialog from 'components/forecast/forecastData/dataTabs/dataImportDialog/DataImportDialogHook';
import DataImportDialog from 'components/forecast/forecastData/dataTabs/dataImportDialog/DataImportDialog';
import {useGetDataFilesQuery, useGetForecastQuery} from 'apiSlices/forecasting-api-slice';
import {usePostCommand} from 'lib/command-client';
import {ActivateDataFileApiCommand, DeleteDataFileApiCommand} from 'apiCommands/api-commands';
import useConfirmDialog from 'components/confirmDialog/ConfirmDialogHook';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';
import {selectIsAdmin} from 'storeSlices/profile-slice';
import Download from 'lib/download-util';
import {ThemeProvider} from '@mui/material/styles';
import {muiTheme} from 'style/mui-theme';
import {DataFile} from 'apiSlices/api-types';

export interface AvailableDataProps {
  forecastId: string,
  tenantId: string,
  label: string,
  dataType: string,
}

function AvailableData(props: AvailableDataProps) {
  const {
    label,
    dataType,
    tenantId,
    forecastId,
  } = props;
  const postCommand = usePostCommand();
  const [itemMoreMenuAnchorEl, setItemMoreMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [currentDataFile, setCurrentDataFile] = useState<DataFile | null>(null);
  const [isDfSaving, setIsDfSaving] = useState<boolean>(false);
  const showItemMoreMenu = Boolean(itemMoreMenuAnchorEl);
  const importDataDialog = useDataImportDialog();
  const confirmDeleteDialog = useConfirmDialog();
  const dataFilesQuery = useGetDataFilesQuery({tenantId, forecastId, type: dataType});
  const {data: dataFiles} = dataFilesQuery;
  const forecastQuery = useGetForecastQuery(forecastId);
  const {data: forecast} = forecastQuery;
  const isAdmin = useSelector(selectIsAdmin);

  const handleImportClicked = async (e: React.MouseEvent<HTMLElement>) => {
    importDataDialog.open();
  };
  const handleDownloadTemplateClicked = async (e: React.MouseEvent<HTMLElement>) => {
    setItemMoreMenuAnchorEl(null);
    await Download(`/api/datafiles/template?tenantId=${ tenantId }&forecastId=${ forecastId }&type=${ dataType }`);
  };
  const handleDataImportDialogClose = async () => {
    await dataFilesQuery.refetch();
    importDataDialog.handleClose();
    forecastQuery.refetch();
  };
  const handleDownloadJsonClicked = async () => {
    if (!currentDataFile) {
      console.error('no current data file');
      return;
    }
    setItemMoreMenuAnchorEl(null);
    await Download(`/api/datafiles/download/mapped/${ currentDataFile.id }`);
  };
  const handleDownloadOriginalClicked = async () => {
    if (!currentDataFile) {
      console.error('no current data file');
      return;
    }
    setItemMoreMenuAnchorEl(null);
    await Download(`/api/datafiles/download/original/${ currentDataFile.id }`);
  };
  const handleItemMoreClose = (e: React.MouseEvent<HTMLElement>) => {
    setItemMoreMenuAnchorEl(null);
  };
  const handleItemMoreClick = (e: React.MouseEvent<HTMLElement>, df: DataFile) => {
    setCurrentDataFile(df);
    setItemMoreMenuAnchorEl(e.currentTarget);
  };
  const handleDeleteClicked = () => {
    setItemMoreMenuAnchorEl(null);
    confirmDeleteDialog.open();
  };
  const handleMakeActiveClicked = async () => {
    setItemMoreMenuAnchorEl(null);
    if (!currentDataFile) {
      console.error('no current data file');
      return;
    }
    setIsDfSaving(true);
    const cmd: ActivateDataFileApiCommand = {
      type: 'ActivateDataFileApiCommand',
      dataFileId: currentDataFile.id,
      tenantId: currentDataFile!.tenantId,
      forecastId: forecastId,
    };
    await postCommand(cmd);
    await dataFilesQuery.refetch();
    await forecastQuery.refetch();
    setIsDfSaving(false);
  };
  const deleteDataFileAction = async () => {
    if (!currentDataFile) {
      console.error('no current data file');
      return;
    }
    const cmd: DeleteDataFileApiCommand = {
      type: 'DeleteDataFileApiCommand',
      id: currentDataFile.id,
      tenantId: currentDataFile!.tenantId,
    };
    await postCommand(cmd);
    dataFilesQuery.refetch();
  };

  const toSort = !!dataFiles ? [...dataFiles] : [] as DataFile[];
  const sortedDataFiles = toSort.sort((a, b) => a.name.localeCompare(b.name));
  let activeFileId = 'none';
  if (!!forecast && !!forecast.activeDataFileIds) {
    activeFileId = forecast.activeDataFileIds[dataType] ?? 'not_found';
  }
  const disableDataFileActions = isDfSaving || forecastQuery.isFetching || dataFilesQuery.isFetching;

  return <s.Container>
    <s.FirstRow>
      <s.Title>{ label }</s.Title>
      <s.FirstRowRight>
        <AppButton
          text="Import"
          onClick={ handleImportClicked }
          icon={ <icons.Upload/> }
          disabled={ disableDataFileActions }
        />
        <AppButton
          text="Download Template"
          onClick={ handleDownloadTemplateClicked }
          icon={ <icons.Download/> }
          disabled={ disableDataFileActions }
        />
      </s.FirstRowRight>
    </s.FirstRow>
    <s.ItemsRow>
      {
        !!sortedDataFiles && sortedDataFiles.map((df, i) => {
          return <s.Item key={ i }>
            <s.ItemLeft>
              <s.ItemIcon>
                <icons.File/>
              </s.ItemIcon>
              <s.ItemName title={ isAdmin ? df.id : undefined }>
                <>
                  { df.name }
                  {
                    activeFileId === df.id && !disableDataFileActions &&
                    <s.ItemActiveChip>
                      <ThemeProvider theme={ muiTheme }>
                        <Chip label="active" color="primary" size="small"/>
                      </ThemeProvider>
                    </s.ItemActiveChip>
                  }
                </>
              </s.ItemName>
              <s.ItemDescription>{ !!df.description ? df.description : '(no description)' }</s.ItemDescription>
            </s.ItemLeft>
            <s.ItemRight>
              <s.ItemButtons>
                <AppButton
                  text=""
                  onClick={ (e) => handleItemMoreClick(e, df) }
                  icon={ <icons.MoreHoriz/> }
                  disabled={ isDfSaving }
                />
              </s.ItemButtons>
            </s.ItemRight>
          </s.Item>;
        })
      }
      {
        (dataFilesQuery.isLoading) && <s.Item>Loading...</s.Item>
      }
      {
        (!dataFilesQuery.isLoading && sortedDataFiles.length === 0) &&
        <s.Item>Import a file using the buttons above.</s.Item>
      }
    </s.ItemsRow>
    {
      importDataDialog.isOpen &&
      <DataImportDialog
        tenantId={ tenantId }
        forecastId={ forecastId }
        dataType={ dataType }
        dataLabel={ label }
        open={ importDataDialog.isOpen }
        onClose={ handleDataImportDialogClose }
      />
    }
    <Menu
      id="basic-menu"
      anchorEl={ itemMoreMenuAnchorEl }
      open={ showItemMoreMenu }
      onClose={ handleItemMoreClose }
      MenuListProps={ {
        'aria-labelledby': 'basic-appButton',
      } }
    >
      {
        currentDataFile && currentDataFile.id !== activeFileId &&
        <MenuItem onClick={ handleMakeActiveClicked }>
          <ListItemIcon>
          </ListItemIcon>
          <ListItemText>
            Make Active
          </ListItemText>
        </MenuItem>
      }
      <MenuItem onClick={ handleDeleteClicked }>
        <ListItemIcon>
          <icons.Trash fontSize="small"/>
        </ListItemIcon>
        <ListItemText>
          Delete
        </ListItemText>
      </MenuItem>
      {
        isAdmin &&
        <MenuItem onClick={ handleDownloadJsonClicked }>
          <ListItemIcon>
            <icons.Download fontSize="small"/>
          </ListItemIcon>
          <ListItemText>
            Download JSON
          </ListItemText>
        </MenuItem>
      }
      <MenuItem onClick={ handleDownloadOriginalClicked }>
        <ListItemIcon>
          <icons.Download fontSize="small"/>
        </ListItemIcon>
        <ListItemText>
          Download Original
        </ListItemText>
      </MenuItem>
    </Menu>
    {
      confirmDeleteDialog.isOpen &&
      <ConfirmDialog
        open={ confirmDeleteDialog.isOpen }
        confirmState={ {
          confirmTitle: 'DELETE',
          title: 'Confirm Delete',
          message: 'Are you sure you want to PERMANENTLY delete data file ' + currentDataFile?.name + '?',
        } }
        onConfirm={ confirmDeleteDialog.handleClose }
        onClose={ confirmDeleteDialog.handleClose }
        confirmAction={ deleteDataFileAction }
        confirmIcon={ <icons.Trash/> }
      />
    }
  </s.Container>;
}

export default AvailableData;
