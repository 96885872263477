import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import appConfig from 'lib/config';
import {ChartDto, ChartsList} from './chart-types';
import * as t from './api-types';

export const tagTypes = [
  'StepInput',
  'StepFilters',
  'Welcome',
  'ForecastList',
  'Forecast',
  'ReportsAvailable',
  'BudgetExplorer',
] as const;

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: appConfig.baseUrl,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  tagTypes: tagTypes,
  endpoints: builder => ({
    getTenants: builder.query<t.Tenant[], void>({
      query: () => '/api/tenants',
    }),
    getActiveForecastsByTenantId: builder.query<t.Forecast[], string>({
      query: (tenantId) => `/api/forecasts/list/active?tenantId=${ tenantId }`,
      providesTags: (result, error, tenantId) =>
        result
          ? [{type: 'ForecastList', tenantId}]
          : [],
    }),
    getAllForecastsByTenantId: builder.query<t.Forecast[], string>({
      query: (tenantId) => `/api/forecasts/list/all?tenantId=${ tenantId }`,
      providesTags: (result, error, tenantId) =>
        result
          ? [{type: 'ForecastList', tenantId}]
          : [],
    }),
    getDeletedForecastsByTenantId: builder.query<t.Forecast[], string>({
      query: (tenantId) => `/api/forecasts/list/deleted?tenantId=${ tenantId }`,
      providesTags: (result, error, tenantId) =>
        result
          ? [{type: 'ForecastList', tenantId}]
          : [],
    }),
    getForecast: builder.query<t.Forecast, string>({
      query: (forecastId) => `/api/forecasts/single/${ forecastId }`,
      structuralSharing: false,
      providesTags: (result, error, forecastId) =>
        result
          ? [{type: 'Forecast', forecastId}]
          : [],
    }),
    getCommandStatus: builder.query<t.CommandStatus, string>({
      query: (commandId) => `/api/commands/status/${ commandId }`,
    }),
    getDataFiles: builder.query<t.DataFile[], { tenantId: string, forecastId: string, type: string }>({
      query: (arg) => `/api/datafiles/list?tenantId=${ arg.tenantId }&forecastId=${ arg.forecastId }&type=${ arg.type }`,
    }),
    getForecastValidation: builder.query<t.ForecastValidationResult, string>({
      query: (forecastId) => `/api/forecast-validation/single/${ forecastId }`,
    }),
    getForecastStatus: builder.query<t.ForecastStatusFetched, string>({
      query: (forecastId) => `/api/check-forecast-status/${ forecastId }`,
    }),
    getLatestCalculation: builder.query<t.Calculation, string>({
      query: (forecastId) => `/api/calculations/latest/${ forecastId }`,
    }),
    getCalculation: builder.query<t.Calculation, string>({
      query: (calcId) => `/api/calculations/calc/${ calcId }`,
    }),
    getChartList: builder.query<ChartsList, string>({
      query: (forecastId) => `/api/chartdata/list/${ forecastId }`,
    }),
    getChartData: builder.query<ChartDto, { forecastId: string, chartId: string, scenarioId: string }>({
      query: (args) => `/api/chartdata/chart/${ args.forecastId }?chartId=${ args.chartId }&scenarioId=${ args.scenarioId }`,
      structuralSharing: false,
    }),
    getBudgetBuilderWorkspace: builder.query<t.BudgetBuilderWorkspaceDto, string>({
      query: (forecastId) => `/api/budgetbuilder/workspace/${ forecastId }`,
    }),
    getBudgetNames: builder.query<string[], string>({
      query: (forecastId) => `/api/budgetbuilder/budget-names/${ forecastId }`,
    }),
    getAccountFilters: builder.query<t.AccountFilters, { forecastId: string, scenarioId: string }>({
      query: ({forecastId, scenarioId}) => `/api/budgetbuilder/account-filters/${ forecastId }/${ scenarioId }`,
    }),
    getStepFilters: builder.query<t.StepFilters, { forecastId: string, scenarioId: string, stepId: string }>({
      query: ({
                forecastId,
                scenarioId,
                stepId,
              }) => `/api/budgetbuilder/step-filters/${ forecastId }/${ scenarioId }/${ stepId }`,
      providesTags: (result, error, arg) =>
        result
          ? [{type: 'StepFilters', forecastId: arg.forecastId, scenarioId: arg.scenarioId, stepId: arg.stepId}]
          : [],
    }),
    getStepInput: builder.query<t.StepInput, { forecastId: string, scenarioId: string, stepId: string, year: number }>({
      query: ({
                forecastId,
                scenarioId,
                stepId,
                year,
              }) => `/api/budgetbuilder/step-input/${ forecastId }/${ scenarioId }/${ stepId }/${ year }`,
      providesTags: (result, error, arg) =>
        result
          ? [
            {
              type: 'StepInput',
              forecastId: arg.forecastId,
              scenarioId: arg.scenarioId,
              stepId: arg.stepId,
              year: arg.year,
            },
          ]
          : []
      ,
    }),
    getWelcomeStatus: builder.query<t.WelcomeStatus, string>({
      query: (forecastId) => `/api/forecasts/welcome/${ forecastId }`,
      providesTags: (result, error, forecastId) =>
        result ? [{type: 'Welcome', forecastId: forecastId}] : [],
    }),
    getReportsAvailable: builder.query<t.ReportsAvailable, { forecastId: string, scenarioId: string }>({
      query: ({forecastId, scenarioId}) => `/api/reports/${ forecastId }/${ scenarioId }/reports`,
      providesTags: (result, error, arg) =>
        result ? [{type: 'ReportsAvailable', forecastId: arg.forecastId, scenarioId: arg.scenarioId}] : [],
    }),
    getBudgetExplorerData: builder.query<t.BudgetExplorerData, { forecastId: string, scenarioId: string }>({
      query: ({forecastId, scenarioId}) => `/api/budgetexplorer/${ forecastId }/${ scenarioId }`,
      providesTags: (result, error, arg) =>
        result ? [{type: 'BudgetExplorer', forecastId: arg.forecastId, scenarioId: arg.scenarioId}] : [],
    }),
  }),
});
export const {
  useGetTenantsQuery,
  useGetActiveForecastsByTenantIdQuery,
  useGetAllForecastsByTenantIdQuery,
  useGetDeletedForecastsByTenantIdQuery,
  useGetForecastQuery,
  useGetCommandStatusQuery,
  useGetDataFilesQuery,
  useGetForecastValidationQuery,
  useGetLatestCalculationQuery,
  useGetCalculationQuery,
  useGetChartListQuery,
  useGetChartDataQuery,
  useGetBudgetBuilderWorkspaceQuery,
  useGetBudgetNamesQuery,
  useGetForecastStatusQuery,
  useGetAccountFiltersQuery,
  useGetStepInputQuery,
  useGetStepFiltersQuery,
  useGetWelcomeStatusQuery,
  useGetReportsAvailableQuery,
  useGetBudgetExplorerDataQuery,
} = apiSlice;
