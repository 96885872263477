import React, {useState} from 'react';
import * as s from './filter-styles';
import AppButton from 'components/appButton/AppButton';
import * as icons from 'style/Icons';
import {Divider, ListItemIcon, Menu, MenuItem} from '@mui/material';

interface FilterParams {
  id: string;
  name: string;
  values: string[];
  selectedValues: string[];
  removeFilter: (id: string) => void;
  filterChanged: (id: string, values: string[]) => void;
  disabled: boolean,
}

export const Filter = (props: FilterParams) => {
  const {id, name, values, removeFilter, disabled, filterChanged, selectedValues} = props;

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const showMenu = Boolean(anchorEl);

  const filterValues = values.map(v => ({
    name: v,
    enabled: selectedValues.includes(v),
  }));

  filterValues.sort((a, b) => {
    //if (a.enabled === b.enabled)
    return a.name.localeCompare(b.name);
    //return a.enabled ? -1 : 1;
  });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(undefined);
  };
  const handleRemoveFilter = () => {
    setAnchorEl(undefined);
    removeFilter(id);
  };
  const handleClearFilter = () => {
    filterChanged(id, []);
  };
  const handleFilterValueClicked = (fv: string) => {
    let values = filterValues.filter(x => x.enabled).map(x => x.name);
    if (values.includes(fv))
      values = values.filter(x => x !== fv);
    else
      values = [...values, fv];
    filterChanged(id, values);
  };

  const disableClear = !filterValues.find(x => x.enabled);

  return <s.FilterContainer>
    <AppButton
      text={ name }
      icon={ <icons.DownChevron/> }
      onClick={ handleClick }
      disabled={ disabled }
    />
    <Menu
      id="basic-menu"
      anchorEl={ anchorEl }
      open={ showMenu }
      onClose={ handleMenuClose }
      MenuListProps={ {
        'aria-labelledby': 'basic-appButton',
        dense: true,
      } }
    >
      <MenuItem key={ 'remove' } value={ 'remove' } onClick={ handleRemoveFilter }>
        <ListItemIcon>
          <icons.Trash/>
        </ListItemIcon>
        Remove Filter
      </MenuItem>
      <MenuItem key={ 'clear' } value={ 'clear' } onClick={ handleClearFilter } disabled={ disableClear }>
        <ListItemIcon>
          <icons.X/>
        </ListItemIcon>
        Clear Filter
      </MenuItem>
      <Divider/>
      {
        filterValues.map((fv, n) =>
          <MenuItem key={ fv.name } value={ n } selected={ fv.enabled }
                    onClick={ () => handleFilterValueClicked(fv.name) }>
            <ListItemIcon>
              { fv.enabled ? <icons.Checked/> : <icons.Unchecked/> }
            </ListItemIcon>
            { fv.name }
          </MenuItem>,
        )
      }
    </Menu>
  </s.FilterContainer>;
};