import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import ErrorDialog from 'components/ErrorDialog';
import {validateName} from 'lib/validators';
import {fontSizes} from 'style/vars';
import CustomInput from 'components/customInput/CustomInput';
import {CommandErrorResult, usePostIdCommand} from 'lib/command-client';
import AppButton from 'components/appButton/AppButton';
import {CreateScenarioApiCommand} from 'apiCommands/api-commands';
import {useGetBudgetNamesQuery} from 'apiSlices/forecasting-api-slice';
import {MenuItem} from '@mui/material';

export interface AddScenarioDialogProps {
  forecastId: string,
  open: boolean,
  onClose: (id: undefined | string) => void;
}

function AddScenarioDialog(props: AddScenarioDialogProps) {
  const postIdCommand = usePostIdCommand();
  const {open, onClose, forecastId} = props;
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | null>(null);
  const budgetNamesQuery = useGetBudgetNamesQuery(forecastId);
  const {data: budgetNames} = budgetNamesQuery;
  const [budgetName, setBudgetName] = useState<string | null>(null);

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleAddClicked = async () => {
    if (!budgetName) {
      return;
    }
    setSaving(true);
    const cmd: CreateScenarioApiCommand = {
      type: 'CreateScenarioApiCommand',
      name: name,
      forecastId: forecastId,
      baseBudgetName: budgetName,
    };
    const result = await postIdCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      setName('');
      onClose(result.id);
    }
  };

  const handleCancelClicked = () => {
    setName('');
    onClose(undefined);
  };

  const handleBaseBudgetChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setBudgetName(e.target.value);
  };

  let nameValid = validateName(name);

  if (name === '') {
    nameValid = true; //done to handle the starting case
  }

  const enableSave = nameValid
    && name !== ''
    && !saving;

  const disabled = budgetNamesQuery.isLoading || budgetNamesQuery.isFetching;
  const defaultBudgetName = (!!budgetNames && budgetNames.length > 0) ? budgetNames[0] : '';
  if (budgetName === null && defaultBudgetName !== '')
    setBudgetName(defaultBudgetName);

  return (
    <Dialog open={ open } onClose={ () => onClose(undefined) }>
      <div style={ {padding: '15px'} }>
        <div style={ {padding: '10px', fontSize: fontSizes.l, width: '300px', marginRight: '600px'} }>
          New Scenario
        </div>

        <div style={ {padding: '15px'} }>
          <CustomInput
            value={ name }
            label="Scenario Name"
            error={ !nameValid }
            onChange={ handleNameChange }
            disabled={ saving }
          />
        </div>
        <div style={ {padding: '15px'} }>
          <CustomInput
            select={ true }
            label="Base Budget"
            value={ budgetName ?? defaultBudgetName }
            onChange={ handleBaseBudgetChanged }
            disabled={ disabled }
          >
            {
              !!budgetNames && budgetNames.map((baseBudgetName, n) =>
                <MenuItem key={ baseBudgetName } value={ baseBudgetName } onClick={ () => {
                } }>{ baseBudgetName }</MenuItem>,
              )
            }
          </CustomInput>
        </div>

        <div style={ {display: 'flex', flexDirection: 'row-reverse', gap: '20px', paddingRight: '15px'} }>
          <AppButton
            text="Create Scenario"
            variant="filledPrimary"
            onClick={ handleAddClicked }
            disabled={ !enableSave }
          />
          <AppButton
            text="Cancel"
            variant="outlinedSecondary"
            onClick={ handleCancelClicked }
            disabled={ saving }
          />
        </div>
      </div>
      { (!!errorDialogState) && <ErrorDialog dialogState={ errorDialogState } open={ !!errorDialogState }
                                             onClose={ () => setErrorDialogState(null) }/> }
    </Dialog>
  );
}

export default AddScenarioDialog;
