import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import * as s from './forecast-reports-styles';
import * as cs from 'components/forecast/forecast-common-styles';
import {useGetReportsAvailableQuery} from 'apiSlices/forecasting-api-slice';
import * as icons from 'style/Icons';
import AppButton from 'components/appButton/AppButton';
import {useAppSelector} from 'app/hooks';
import {usePostCommand} from 'lib/command-client';
import {RequestReportApiCommand} from 'apiCommands/api-commands';
import Download from 'lib/download-util';
import {Box} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {green} from '@mui/material/colors';

export const ForecastReports = () => {
  const params = useParams();
  const forecastId = params.forecastId ?? '';
  const scenarioId = params.scenarioId ?? '';
  const postCommand = usePostCommand();

  const forecastSequence = useAppSelector(s => s.ui.activeForecast?.updateSequence) ?? -1;

  const getReports = useGetReportsAvailableQuery({
    forecastId,
    scenarioId,
  }, {skip: forecastId === '' || scenarioId === '', pollingInterval: 3000});
  const result = getReports.data;

  const isCurrent = forecastSequence === result?.forecastSequence;
  const hasDetailedReport = isCurrent && (!!result?.detailedForecastReport);
  const hasStepsReport = isCurrent && (!!result?.stepDetailReport);
  const detailedReportLink = `/api/reports/${ forecastId }/${ scenarioId }/reports-cached/${ (result?.detailedForecastReport ?? '') }?fileName=detailed_forecast_report.xlsx`;
  const stepsReportLink = `/api/reports/${ forecastId }/${ scenarioId }/reports-cached/${ (result?.stepDetailReport ?? '') }?fileName=steps_report.xlsx`;

  const [buildingDetailReport, setBuildingDetailReport] = useState(false);
  const [buildingStepsReport, setBuildingStepsReport] = useState(false);

  useEffect(() => {
    if (!isCurrent || hasDetailedReport)
      setBuildingDetailReport(false);
  }, [isCurrent, hasDetailedReport]);

  useEffect(() => {
    if (!isCurrent || hasStepsReport)
      setBuildingStepsReport(false);
  }, [isCurrent, hasStepsReport]);

  async function handleDownloadDetailedReportClicked() {
    if (hasDetailedReport)
      await Download(detailedReportLink);
    else {
      setBuildingDetailReport(true);
      await postCommand<RequestReportApiCommand>({
        type: 'RequestReportApiCommand',
        forecastId: forecastId,
        scenarioId: scenarioId,
        forecastSequence: forecastSequence,
        reportId: 'DetailedForecast',
      });
    }
  }

  async function handleDownloadStepsReportClicked() {
    if (hasStepsReport)
      await Download(stepsReportLink);
    else {
      setBuildingStepsReport(true);
      await postCommand<RequestReportApiCommand>({
        type: 'RequestReportApiCommand',
        forecastId: forecastId,
        scenarioId: scenarioId,
        forecastSequence: forecastSequence,
        reportId: 'StepDetail',
      });
    }
  }

  return <s.ForecastReportsContainer>
    <s.TitleSection>
      <s.TitleSectionLeft>
        <cs.PageTitle>
          Reports
        </cs.PageTitle>
      </s.TitleSectionLeft>
      <s.TitleSectionRight>
      </s.TitleSectionRight>
    </s.TitleSection>
    <s.WelcomeBlurb>
      Please select from the below reports.
    </s.WelcomeBlurb>
    <s.MainArea>
      <s.LeftMainArea>
        <s.SectionTitle>
          <s.SectionTitleLeft>
            <icons.Table/>
          </s.SectionTitleLeft>
          <s.SectionTitleRight>Spreadsheets</s.SectionTitleRight>
        </s.SectionTitle>
        <s.ReportDownloadRow>
          <s.ReportDownloadLinks>
            <s.ReportDownloadLink>
              <Box sx={ {m: 1, position: 'relative'} }>
                <AppButton
                  text={ hasDetailedReport ? 'excel' : 'request' }
                  size="small"
                  variant="outlinedSecondary"
                  disabled={ buildingDetailReport }
                  onClick={ () => handleDownloadDetailedReportClicked() }
                />
                { buildingDetailReport &&
                  <CircularProgress
                    size={ 24 }
                    sx={ {
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    } }
                  />
                }
              </Box>
            </s.ReportDownloadLink>
          </s.ReportDownloadLinks>
          <s.ReportDownloadName>Detailed Forecast Report</s.ReportDownloadName>
        </s.ReportDownloadRow>
        <s.ReportDownloadRow>
          <s.ReportDownloadLinks>
            <s.ReportDownloadLink>
              <Box sx={ {m: 1, position: 'relative'} }>
                <AppButton
                  text={ hasStepsReport ? 'excel' : 'request' }
                  size="small"
                  variant="outlinedSecondary"
                  disabled={ buildingStepsReport }
                  onClick={ () => handleDownloadStepsReportClicked() }
                />
                { buildingStepsReport &&
                  <CircularProgress
                    size={ 24 }
                    sx={ {
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    } }
                  />
                }
              </Box>
            </s.ReportDownloadLink>
          </s.ReportDownloadLinks>
          <s.ReportDownloadName>Step Details Report</s.ReportDownloadName>
        </s.ReportDownloadRow>
      </s.LeftMainArea>
      <s.RightMainArea>
      </s.RightMainArea>
    </s.MainArea>
  </s.ForecastReportsContainer>;
};

export default ForecastReports;
