import React from 'react';
import {Box, Modal, Typography} from '@mui/material';
import Spinner from 'components/Spinner';

export default function ModalSpinner(props: { show: boolean, message: string }) {
  const {show, message} = props;

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    border: '1px solid',
    boxShadow: 24,
    borderRadius: '6px',
    p: 4,
  };

  return (
    <Modal open={ show }
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
    >
      <Box sx={ style }>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Please wait...
        </Typography>
        <Typography id="modal-modal-description" sx={ {mt: 2} }>
          { message }
        </Typography>
        <Spinner/>
      </Box>
    </Modal>
  );
}