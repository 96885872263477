import React, {useEffect, useState} from 'react';
import {getQueryVariable} from 'lib/uri-util';
import {getPrintView} from 'components/forecast/forecastCharts/print-helper';
import ReactPDF, {Document, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import {ForecastChartsInput} from 'components/forecast/forecastCharts/chart-input-selector';
import MainChart from 'components/forecast/forecastCharts/MainChart';
import * as htmlToImage from 'html-to-image';
import {getCurrentYear, getLocalDateAndTime} from 'lib/date-time-utils';
import Image = ReactPDF.Image;
import PDFViewer = ReactPDF.PDFViewer;

export function Printable() {
  const [chartPngStr, setChartPngStr] = useState<string | null>(null);
  const printIdStr = getQueryVariable('printId');
  if (!printIdStr)
    throw new Error('printId query variable not found');
  const printId = Number.parseInt(printIdStr);
  const finishRenderingPng = !!chartPngStr;

  useEffect(() => {
    if (!finishRenderingPng) {
      const chartContainerEl = document.getElementById('chart-container');
      if (!chartContainerEl)
        return;
      htmlToImage.toPng(chartContainerEl).then(png => {
        setChartPngStr(png);
      });
    }
  });

  const printData = getPrintView(printId);

  if (!printData?.chartInput)
    throw new Error('mainChart not found');
  const chartInput = printData.chartInput as ForecastChartsInput;
  chartInput.mainChart.isPrintView = true;
  chartInput.mainChart.axisFontSize = 24;

  const printInfo = chartInput.printInfo;

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#ffffff',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={ styles.page }>
        <View style={ styles.section }>
          <div id="printable-container" style={ {padding: '20px'} }>
            <div id="title-row">
              <div id="title-left">
                <div style={ {padding: '40px 0px'} }>
                  <Text style={ {textAlign: 'center'} }>{ chartInput.mainChart.chart?.chartName }</Text>
                </div>
              </div>
            </div>
            <div id="chart-container">
              {
                !!chartPngStr && <Image src={ chartPngStr }/>
              }
            </div>
            <div id="stats-container" style={ {display: 'flex', flexDirection: 'column', padding: '20px 0'} }>
              <div style={ {paddingBottom: '8px'} }><Text style={ {fontSize: '12pt'} }>{ printInfo.tenantName }</Text>
              </div>
              <div style={ {paddingBottom: '8px'} }><Text
                style={ {fontSize: '12pt'} }>Forecast: { printInfo.forecastName }</Text></div>
            </div>
            <div id="footer-row" style={ {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'} }>
              <div id="footer-left" style={ {display: 'flex', flexDirection: 'column', paddingTop: '8px'} }>
                <div style={ {paddingBottom: '4px'} }><Text style={ {fontSize: '8pt'} }>{ getLocalDateAndTime() }</Text>
                </div>
                <div><Text style={ {fontSize: '8pt'} }>Confidential&copy;{ getCurrentYear() } GovInvest, Inc.</Text>
                </div>
              </div>
              <div id="footer-right" style={ {display: 'flex', flexDirection: 'row', alignItems: 'center'} }>
                <div>
                  <Text style={ {fontSize: '8pt'} }>This report produced using</Text>
                </div>
                <Image
                  src="/govinvest_logo_no_subtitle_small.png"
                  style={ {width: '100px', padding: '10px'} }
                />
              </div>
            </div>
          </div>
        </View>
      </Page>
    </Document>
  );

  //we are rending the chart out of the view, grabbing the element, converting to PNG, and including the PNG in the PDF
  const height = window.innerHeight;
  return <>
    {
      !finishRenderingPng &&
      <div style={ {position: 'absolute', top: 0, right: -9999} }>
        <MainChart input={ chartInput }/>
      </div>
    }
    {
      finishRenderingPng &&
      <PDFViewer width={ '100%' } height={ height.toString() + 'px' }>
        <MyDocument/>
      </PDFViewer>
    }
  </>;
}

export default Printable;
