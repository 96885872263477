import React from 'react';
import './button.css';

function Button(props: any) {
  const {
    small,
    error,
    color,
    xsmall,
    primary,
    success,
    disabled,
    children,
    secondary,
    danger,
    transparent,
    onClick,
  } = props;

  const styles = `my-button
      ${ small ? 'small' : '' }
      ${ error ? 'error' : '' }
      ${ xsmall ? 'xsmall' : '' }
      ${ success ? 'success' : '' }
      ${ primary ? 'primary' : '' }
      ${ disabled ? 'disabled' : '' }
      ${ secondary ? 'secondary' : '' }
      ${ danger ? 'danger' : '' }
      ${ transparent ? 'transparent' : '' }`;

  return (
    <div style={ {padding: '3px'} }>
      <button className={ styles } disabled={ disabled } onClick={ onClick }>
        <div className="my-button-label" style={ {color: color ? color : ''} }>
          { children }
        </div>
      </button>
    </div>
  );
}

export default Button;