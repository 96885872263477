import React, {useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {colors} from 'style/colors';
import {styled} from '@mui/material/styles';

const classes = {
  root: 'Spinner-root',
  progress: 'Spinner-progress',
};

const Root = styled('div')(({theme: any}) => ({
  [`&.${ classes.progress }`]: {
    color: colors.themePrimary,
  },
  [`&.${ classes.root }`]: {
    color: 'red',
    display: 'flex',
    marginBottom: '10px',
    width: '100%',
    alignItems: 'center',
  },
}));

interface SpinnerProps {
  retryInterval?: number,
  retryAction?: (() => void),
  size?: number,
}

function Spinner(props: SpinnerProps) {
  useEffect(() => {
    if (!props.retryInterval || !props.retryAction)
      return;
    const interval = setInterval(props.retryAction, props.retryInterval);
    return () => clearInterval(interval);
  }, [props.retryAction, props.retryInterval]);

  return (
    <Root className={ classes.root }>
      <CircularProgress
        className={ classes.progress }
        size={ props.size ?? 60 }
        style={ {margin: 'auto', marginTop: '20%'} }
      />
    </Root>
  );
}

export default Spinner;
