import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Tab, Tabs} from '@mui/material';
import {PageTitle} from 'components/forecast/forecast-common-styles';
import {styled} from '@mui/material/styles';
import {DataHeaderButtonsContainer, HeaderContainer} from './forecast-data-header-styles';
import * as routeBuilder from 'lib/route-builder';
import {colors} from 'style/colors';
import {fontFamily} from 'style/vars';

export interface ForecastDataHeaderProps {
  tenantId: string,
  forecastId: string,
}

export function ForecastDataHeader(props: ForecastDataHeaderProps) {
  const {tenantId, forecastId} = props;
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const getTabs = (tenantId: string, forecastId: string) => {
    return [
      {
        label: 'Chart of Accounts',
        route: routeBuilder.getDataChartOfAccounts(tenantId, forecastId),
        selected: pathName.includes('data/chart-of-accounts'),
      },
      /*
      {
        label: 'Fund List',
        route: routeBuilder.getDataFundList(tenantId, forecastId),
        selected: pathName.includes('data/fund-list'),
      },
      */
      {
        label: 'Account Detail',
        route: routeBuilder.getDataAccountDetail(tenantId, forecastId),
        selected: pathName.includes('data/account-detail'),
      },
      {
        label: 'Fund Balances',
        route: routeBuilder.getDataFundBalances(tenantId, forecastId),
        selected: pathName.includes('data/fund-balances'),
      },
      {
        label: 'Budgets',
        route: routeBuilder.getDataBudgets(tenantId, forecastId),
        selected: pathName.includes('data/budgets'),
      },
      /*
      {
        label: 'Vendor List',
        route: routeBuilder.getDataVendorList(tenantId, forecastId),
        selected: pathName.includes('data/vendor-list'),
      },
      */
    ];
  };

  const tabs = getTabs(tenantId, forecastId);
  const selectedTabIndex = tabs.findIndex(x => x.selected);
  if (selectedTabIndex === -1)
    return <></>;

  return <HeaderContainer>
    <PageTitle>Data Management</PageTitle>
    <DataHeaderButtonsContainer>
      <HeaderTabs
        value={ selectedTabIndex }
      >
        {
          tabs.map((t, i) =>
            <HeaderTab
              key={ i }
              label={ t.label }
              onClick={ () => navigate(t.route) }
            />)
        }
      </HeaderTabs>
    </DataHeaderButtonsContainer>
  </HeaderContainer>;
}

export default ForecastDataHeader;

const HeaderTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: colors.themePrimary,
  },
});

interface StyledTabProps {
  label: string;
  onClick: React.MouseEventHandler<HTMLElement>,
}

const HeaderTab = styled((props: StyledTabProps) => <Tab disableRipple { ...props } />)(
  ({theme}) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontFamily: fontFamily,
    '&:hover': {
      color: colors.themePrimary,
      opacity: 1,
    },
    '&.Mui-selected': {
      color: colors.themePrimary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);
