import {useAppSelector} from 'app/hooks';
import {apiSlice, useGetStepFiltersQuery} from 'apiSlices/forecasting-api-slice';
import React from 'react';
import * as s from './filter-styles';
import {Filter} from './Filter';
import {AddFilterButton} from './AddFilterButton';
import {AddRemoveFilterApiCommand, ChangeFilterApiCommand} from 'apiCommands/api-commands';
import {CommandErrorResult, usePostCommand} from 'lib/command-client';
import {useDispatch} from 'react-redux';
import {StepFilters} from 'apiSlices/api-types';

interface FilterBarParams {
  stepId: string | undefined;
  setSaving: (v: boolean) => void;
  saving: boolean;
  setErrorDialogState: (e: CommandErrorResult | undefined) => void;
}

export const FilterBar = (props: FilterBarParams) => {
  const {stepId, setSaving, saving, setErrorDialogState} = props;
  const dispatch = useDispatch();
  const postCommand = usePostCommand();
  const forecastId = useAppSelector(s => s.ui.activeForecastId);
  const scenarioId = useAppSelector(s => s.ui.activeScenarioId);
  const actScenarioId = useAppSelector(s => s.ui.activeScenario?.id);
  const stepExists = useAppSelector(s => s.ui.activeScenario?.steps?.find(s => s.id === stepId));

  const skip = !forecastId || !scenarioId || !stepId || !actScenarioId || actScenarioId !== scenarioId || !stepExists;

  const query = {forecastId: forecastId ?? '', scenarioId: scenarioId ?? '', stepId: stepId ?? ''};
  const getStepFilters = useGetStepFiltersQuery(query, {skip: skip});
  const stepFilters = getStepFilters.data as StepFilters;

  if (!stepFilters)
    return <></>;

  const added = (id: string) => stepFilters.selectedFilterIds.find(x => x === id);
  const selectedValues = (id: string) => {
    const index = stepFilters.selectedFilterIds.indexOf(id);
    if (index < 0)
      return [];
    return stepFilters.selectedFilterValues[index];
  };

  const filterDefinitions = stepFilters.filterIds.map((id, i) => ({
    id,
    name: stepFilters.filterNames[i],
    added: added(id),
    values: stepFilters.filterValues[i],
    selectedValues: selectedValues(id),
  })).filter(x => x.added);

  const handleRemoveFilter = async (id: string) => {
    if (saving)
      return;
    setSaving(true);
    const cmd: AddRemoveFilterApiCommand = {
      type: 'AddRemoveFilterApiCommand',
      forecastId: forecastId ?? '',
      scenarioId: scenarioId ?? '',
      stepId: stepId ?? '',
      filterId: id,
      addFilter: false,
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      dispatch(apiSlice.util.invalidateTags(['StepInput', 'StepFilters']));
    }
  };
  const handleAddFilter = async (id: string) => {
    if (saving)
      return;
    setSaving(true);
    const cmd: AddRemoveFilterApiCommand = {
      type: 'AddRemoveFilterApiCommand',
      forecastId: forecastId ?? '',
      scenarioId: scenarioId ?? '',
      stepId: stepId ?? '',
      filterId: id,
      addFilter: true,
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      dispatch(apiSlice.util.invalidateTags(['StepInput', 'StepFilters']));
    }
  };

  const handleFilterChanged = async (id: string, values: string[]) => {
    if (saving)
      return;
    setSaving(true);
    const cmd: ChangeFilterApiCommand = {
      type: 'ChangeFilterApiCommand',
      forecastId: forecastId ?? '',
      scenarioId: scenarioId ?? '',
      stepId: stepId ?? '',
      filterId: id,
      selectedValues: values,
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      dispatch(apiSlice.util.invalidateTags(['StepInput', 'StepFilters']));
    }
  };

  return <s.FilterBarBar>
    <AddFilterButton
      addedFilters={ stepFilters.selectedFilterIds }
      stepFilters={ stepFilters }
      addFilter={ handleAddFilter }/>

    <s.FiltersContainer>
      {
        filterDefinitions.map(f =>
          <Filter key={ f.id }
                  id={ f.id }
                  name={ f.name }
                  removeFilter={ handleRemoveFilter }
                  filterChanged={ handleFilterChanged }
                  values={ f.values }
                  selectedValues={ f.selectedValues }
                  disabled={ saving }
          />)
      }
    </s.FiltersContainer>
  </s.FilterBarBar>;
};