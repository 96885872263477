import {GridRenderCellParams} from '@mui/x-data-grid-premium';
import {Adjustment} from 'components/forecast/forecastBudgetBuilder/budget-builder-types';
import {GridColDef} from '@mui/x-data-grid';
import {accountFormatter, valueFormatter} from './formatters';
import {AdjustmentType, StepInput} from 'apiSlices/api-types';

type RenderFunc = (params: GridRenderCellParams<Adjustment>) => (JSX.Element);

export function buildGridData(adjustmentRenderer: RenderFunc, stepInputs: StepInput) {
  const stdCol: GridColDef = {type: 'string', field: '', headerClassName: 'table-header', headerAlign: 'left'};
  const valCol: GridColDef = {...stdCol, align: 'right', width: 150, valueFormatter};
  const cols: any[] = [
    {...stdCol, field: 'account', headerName: 'Account', flex: 1, valueFormatter: accountFormatter},
    {...stdCol, field: 'object', headerName: 'Object' },
    {...stdCol, field: 'fund', headerName: 'Fund' },
    {...stdCol, field: 'department', headerName: 'Department' },
    {...stdCol, field: 'division', headerName: 'Division' },
    {...valCol, field: 'baseVal', headerName: 'Base Value', headerAlign: 'right'},
    {...valCol, field: 'beforeVal', headerName: 'Before Value', headerAlign: 'right'},
    {
      ...stdCol,
      field: 'adj',
      headerName: 'Adjustment',
      headerAlign: 'center',
      width: 250,
      align: 'center',
      valueFormatter: undefined,
      renderCell: adjustmentRenderer,
    },
    {...valCol, field: 'afterVal', headerName: 'After Value', headerAlign: 'right'},
  ];

  const rows: any[] = stepInputs.accounts.map((a, i) => ({
    account: a,
    object: stepInputs.objects[i],
    fund: stepInputs.funds[i],
    department: stepInputs.departments[i],
    division: stepInputs.divisions[i],
    baseVal: stepInputs.baseValues[i],
    beforeVal: stepInputs.beforeValues[i],
    afterVal: stepInputs.afterValues[i],
    adj: stepInputs.adjustType[i] === AdjustmentType.Blank
      ? undefined
      : {type: stepInputs.adjustType[i], value: stepInputs.adjustValues[i]},
  }));

  rows.unshift({
    account: undefined,
    baseVal: 0.0,
    beforeVal: 0.0,
    afterVal: 0.0,
    adj: stepInputs.allAdjustType === AdjustmentType.Blank
      ? undefined
      : {type: stepInputs.allAdjustType, value: stepInputs.allAdjustValue},
  });
  return {cols, rows};
}