import React, {ReactNode, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import AppButton from 'components/appButton/AppButton';

export interface ConfirmDialogState {
  title: string,
  message: string,
  confirmTitle: string,
}

export interface ConfirmDialogProps {
  open: boolean,
  onClose: Function,
  onConfirm: Function,
  confirmState: ConfirmDialogState,
  confirmAction?: () => Promise<void>,
  confirmIcon?: ReactNode,
}

function ConfirmDialog(props: ConfirmDialogProps) {
  const {open, onClose, confirmState, onConfirm} = props;
  const [performingConfirmAction, setPerformingConfirmAction] = useState(false);

  const handleConfirm = async () => {
    setPerformingConfirmAction(true);
    if (!!props.confirmAction)
      await props.confirmAction();
    setPerformingConfirmAction(false);
    onConfirm();
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOnClose = () => {
    if (performingConfirmAction)
      return;
    onClose();
  };

  return (
    <Dialog open={ open } onClose={ handleOnClose }>
      <div style={ {padding: '15px'} }>
        <div style={ {padding: '10px', fontSize: 'large', marginRight: '200px'} }>{ confirmState.title }</div>
        <div style={ {display: 'flex', fontSize: 'small', padding: '5px', marginBottom: '10px'} }>
          <div style={ {padding: '5px'} }>{ confirmState.message }</div>
        </div>
        {
          performingConfirmAction &&
          <div style={ {display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium', gap: '15px'} }>
            <AppButton variant="disabledSpinner" text={ confirmState.confirmTitle } onClick={ handleConfirm }/>
            <AppButton variant="disabledOutline" text="Cancel" onClick={ handleCancel }/>
          </div>
        }
        {
          !performingConfirmAction &&
          <div style={ {display: 'flex', flexDirection: 'row-reverse', fontSize: 'medium', gap: '15px'} }>
            <AppButton variant="filledRed" text={ confirmState.confirmTitle } icon={ props.confirmIcon }
                       onClick={ handleConfirm }/>
            <AppButton variant="outlinedInfo" text="Cancel" onClick={ handleCancel }/>
          </div>
        }
      </div>
    </Dialog>
  );
}

export default ConfirmDialog;
