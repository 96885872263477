const reporter = (store: any) => (next: any) => (action: any) => {
  let suffix = '';
  let isApi = action.type.startsWith('api/');
  if (action.type.startsWith('api/executeQuery/')) {
    let arg = action.meta.arg;
    let endpointName = arg?.endpointName;
    let args = arg?.originalArgs;
    if (args !== undefined)
      args = JSON.stringify(args);
    else
      args = '';
    suffix = ` ${ endpointName }(${ args })`;
  } else if (isApi && action.payload?.queryCacheKey) {
    suffix = ` ${ (action.payload?.queryCacheKey) }`;
  }
  if (isApi)
    console.groupCollapsed(action.type + suffix);
  else
    console.group(action.type + suffix);
  if (!isApi)
    console.log('%c prev state', 'color: gray', store.getState());
  console.log('%c action', 'color: blue', action);
  const returnValue = next(action);
  if (!isApi)
    console.log('%c next state', 'color: green', store.getState());
  console.groupEnd();
  return returnValue;
};

export default reporter;
