import React from 'react';
import {Link} from 'react-router-dom';
import {colors} from 'style/colors';

export function AppLogo(props: { style: React.CSSProperties }) {
  return <>
    <div style={ props.style }>
      <img style={ {height: '40px'} } src="/govinvest_logo_no_subtitle_small.png" alt="GovInvest, Inc. Logo"/>
    </div>
    <div style={ props.style }>
      <div style={ {fontWeight: 1.2, fontSize: '24px', marginLeft: '10px'} }>
        <Link to="/app">
          <div style={ {color: colors.black, paddingBottom: '3px'} }>
            Forecasting
          </div>
        </Link>
      </div>
    </div>
  </>;
}