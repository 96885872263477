import React from 'react';
import {fontSizes} from 'style/vars';
import Spinner from 'components/Spinner';

function AppInfo() {
  const appInfo = (window as any).env;

  if (!appInfo) {
    return <Spinner/>;
  }

  return (
    <div style={ {padding: '15px', display: 'flex', flexDirection: 'column'} }>
      <div style={ {fontSize: fontSizes.l, marginRight: '30px', padding: '10px'} }>
        App Info
      </div>
      <div style={ {marginLeft: '10px', marginTop: '5px'} }>
        <div style={ {fontSize: fontSizes.m, marginRight: '30px', padding: '10px'} }>
          Config
        </div>
        <div style={ {marginLeft: '10px'} }>
          {
            Object.keys(appInfo).map(k =>
              (<div key={ k } style={ {padding: '5px', fontFamily: 'monospace'} }>{ k }: { appInfo[k] }</div>),
            )
          }
        </div>
      </div>
    </div>
  );
}

export default AppInfo;