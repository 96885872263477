import React from 'react';
import {useParams} from 'react-router-dom';
import * as s from './forecast-settings-styles';
import * as cs from 'components/forecast/forecast-common-styles';
import {useGetForecastQuery} from 'apiSlices/forecasting-api-slice';
import Spinner from 'components/Spinner';
import NameSetting from './NameSetting';
import DescriptionSetting from './DescriptionSetting';
import CyStartDateSetting from './CyStartDateSetting';

export const ForecastSettings = () => {
  const params = useParams();
  const forecastId = params.forecastId ?? '';
  const forecastQuery = useGetForecastQuery(forecastId, {refetchOnMountOrArgChange: true});
  const {data: forecast} = forecastQuery;
  if (!forecast)
    return <div style={ {margin: 'auto'} }><Spinner/></div>;

  return <s.SettingsContainer>
    <s.TitleSection>
      <cs.PageTitle>Settings</cs.PageTitle>
      <s.SettingsBlurb>
        Use this page to manage the settings of your forecast.
      </s.SettingsBlurb>
    </s.TitleSection>
    <s.SettingsSection>
      <cs.SectionHeading>General</cs.SectionHeading>

      <s.SectionInputs>
        <NameSetting forecastId={ forecastId } tenantId={ forecast.tenantId }/>
        <DescriptionSetting forecastId={ forecastId }/>
        <CyStartDateSetting forecastId={ forecastId }/>
      </s.SectionInputs>

    </s.SettingsSection>
  </s.SettingsContainer>;
};
export default ForecastSettings;
