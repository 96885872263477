import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import ErrorDialog from 'components/ErrorDialog';
import {validateName} from 'lib/validators';
import {fontSizes} from 'style/vars';
import CustomInput from 'components/customInput/CustomInput';
import {CommandErrorResult, pollForCommandStatus, postAsyncCommand} from 'lib/command-client';
import {useGetAllForecastsByTenantIdQuery} from 'apiSlices/forecasting-api-slice';
import {DuplicateForecastApiCommand} from 'apiCommands/api-commands';
import AppButton from 'components/appButton/AppButton';
import {Forecast} from 'apiSlices/api-types';

export interface DuplicateForecastDialogProps {
  tenantId: string,
  forecast: Forecast | null,
  open: boolean,
  onClose: (event?: object, reason?: string) => void;
}

function DuplicateForecastDialog(props: DuplicateForecastDialogProps) {
  const {open, onClose, tenantId, forecast} = props;
  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | null>(null);
  const forecasts = useGetAllForecastsByTenantIdQuery(tenantId);

  const handleNameChange = (e: any) => {
    setName(e.target.value);
  };

  const handleDuplicateClicked = async () => {
    if (!forecast)
      return;
    setSaving(true);
    const cmd: DuplicateForecastApiCommand = {
      type: 'DuplicateForecast',
      tenantId: tenantId,
      forecastId: forecast.id,
      name: name,
    };
    const result = await postAsyncCommand(cmd);
    if (result.error) {
      setSaving(false);
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      if (!result.commandId)
        throw new Error('Null command Id');
      const status = await pollForCommandStatus(result.commandId);
      if (status.status === 'Error' || !!status.error) {
        setSaving(false);
        setErrorDialogState({error: status.error, errorDescription: status.errorDescription});
        return;
      }

      setSaving(false);
      setName('');
      onClose();
    }
  };

  const handleCancelClicked = () => {
    setName('');
    onClose();
  };

  let nameValid = validateName(name);

  if (!!(forecasts.currentData) && forecasts.currentData.find(x => x.name === name)) {
    nameValid = false;
  }

  if (name === '') {
    nameValid = true; //done to handle the starting case
  }

  const enableSave = nameValid
    && name !== ''
    && !saving;

  return (
    <Dialog open={ open } onClose={ onClose }>
      <div style={ {padding: '15px'} }>
        <div style={ {padding: '10px', fontSize: fontSizes.l, width: '300px', marginRight: '600px'} }>Duplicate
          Forecast
        </div>

        <div style={ {padding: '15px'} }>
          <CustomInput
            value={ name }
            label="New Forecast Name"
            error={ !nameValid }
            onChange={ handleNameChange }
            disabled={ saving }
          />
        </div>

        <div style={ {display: 'flex', flexDirection: 'row-reverse', gap: '20px', paddingRight: '15px'} }>
          <AppButton
            text="Duplicate Forecast"
            variant="filledPrimary"
            onClick={ handleDuplicateClicked }
            disabled={ !enableSave }
          />
          <AppButton
            text="Cancel"
            variant="outlinedSecondary"
            onClick={ handleCancelClicked }
            disabled={ saving }
          />
        </div>
      </div>
      { (!!errorDialogState) && <ErrorDialog dialogState={ errorDialogState } open={ !!errorDialogState }
                                             onClose={ () => setErrorDialogState(null) }/> }
    </Dialog>
  );
}

export default DuplicateForecastDialog;