import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {colors} from 'style/colors';
import TenantImage from 'components/tenantImage/TenantImage';
import {selectProfile} from 'storeSlices/profile-slice';
import Auth from 'lib/auth';
import * as icons from 'style/Icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {ListItemIcon, ListItemText} from '@mui/material';

interface HeaderProfileProps {
  auth: Auth;
  currentTenantId?: string;
}

function HeaderProfile(props: HeaderProfileProps) {
  const profile = useSelector(selectProfile);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const showMenu = Boolean(anchorEl);

  if (!profile) {
    return (<></>);
  }
  let name = profile ? profile.name : '';
  if (name === '__no_name__')
    name = profile.email;

  const databaseUser = profile.userDatabaseConnection;
  const enableLogoutSso = !profile.ssoLogoutDisabled;

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const tenantIdForIcon = props.currentTenantId ?? profile.tenantId;

  return (
    <>
      <div style={ {display: 'flex', cursor: 'pointer'} } onClick={ handleMenuClick }>
        <div style={ {padding: '5px', fontSize: 'small', display: 'flex', alignItems: 'center'} }>
          { name }
        </div>
        <div style={ {padding: '5px', fontSize: 'small', color: colors.green} }>
          <TenantImage tenantId={ tenantIdForIcon } width="32px"/>
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ showMenu }
        onClose={ handleMenuClose }
        style={ {zIndex: 3000} }
        MenuListProps={ {
          'aria-labelledby': 'basic-appButton',
        } }
      >
        {
          databaseUser &&
          <MenuItem onClick={ () => props.auth.changePassword(false) }>
            <ListItemIcon>
              <icons.Key fontSize="small"/>
            </ListItemIcon>
            <ListItemText>
              Change Password
            </ListItemText>
          </MenuItem>
        }
        {
          databaseUser &&
          <MenuItem onClick={ () => props.auth.logout() }>
            <ListItemIcon>
              <icons.Logout fontSize="small"/>
            </ListItemIcon>
            <ListItemText>
              Logout
            </ListItemText>
          </MenuItem>
        }
        {
          !databaseUser &&
          <MenuItem onClick={ () => props.auth.switchUser() }>
            <ListItemIcon>
              <icons.Logout fontSize="small"/>
            </ListItemIcon>
            <ListItemText>
              Logout
            </ListItemText>
          </MenuItem>
        }
        {
          (!databaseUser && enableLogoutSso) &&
          <MenuItem onClick={ () => props.auth.logout() }>
            <ListItemIcon>
              <icons.Logout fontSize="small"/>
            </ListItemIcon>
            <ListItemText>
              Logout SSO
            </ListItemText>
          </MenuItem>
        }
      </Menu>
    </>
  );
}

export default HeaderProfile;
