import React, {ChangeEvent, useState} from 'react';
import * as s from './forecast-settings-styles';
import AppButton from 'components/appButton/AppButton';
import CustomInput from 'components/customInput/CustomInput';
import {useGetAllForecastsByTenantIdQuery, useGetForecastQuery} from 'apiSlices/forecasting-api-slice';
import {validateName} from 'lib/validators';
import {RenameForecastApiCommand} from 'apiCommands/api-commands';
import {CommandErrorResult, usePostCommand} from 'lib/command-client';
import ErrorDialog from 'components/ErrorDialog';
import {triggerInvalidate} from 'apiCommands/tag-invalidation';
import {useDispatch} from 'react-redux';

export const NameSetting = (props: { forecastId?: string, tenantId?: string }) => {
  const dispatch = useDispatch();
  const postCommand = usePostCommand();
  const forecastId = props.forecastId ?? '';
  const tenantId = props.tenantId ?? '';
  const forecastsQuery = useGetAllForecastsByTenantIdQuery(tenantId, {refetchOnMountOrArgChange: true});
  const forecastQuery = useGetForecastQuery(forecastId, {refetchOnMountOrArgChange: true});
  const {data: forecast, isLoading} = forecastQuery;
  const [newName, setNewName] = useState(forecast?.name ?? null);
  const [nameValid, setNameValid] = useState(true);
  const [saving, setSaving] = useState(false);
  const [trailingText, setTrailingText] = useState('Saved');
  const [showTrailingText, setShowTrailingText] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState<CommandErrorResult | null>(null);
  if (!forecast)
    return <></>;
  if (newName === null)
    return <></>;
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = validateName(e.target.value);
    const duplicate = !!(forecastsQuery.currentData) && forecastsQuery.currentData.find(x => x.id !== forecastId && x.name === e.target.value);
    setNameValid(isValid && !duplicate);
    setNewName(e.target.value);
    if (duplicate) {
      setShowTrailingText(true);
      setTrailingText('Duplicate name');
    } else {
      setShowTrailingText(false);
      setTrailingText('Saved');
    }
  };
  const handleCancelClicked = (e: React.MouseEvent<HTMLElement>) => {
    reset();
  };
  const handleRenameClicked = async (e: React.MouseEvent<HTMLElement>) => {
    if (!forecast)
      return;
    setSaving(true);
    const cmd: RenameForecastApiCommand = {
      type: 'RenameForecast',
      tenantId: forecast.tenantId,
      forecastId: forecast.id,
      name: newName,
    };
    const result = await postCommand(cmd);
    if (result.error) {
      setSaving(false);
      //forecastQuery.refetch();
      setErrorDialogState({error: result.error, errorDescription: result.errorDescription});
    } else {
      setSaving(false);
      //forecastQuery.refetch();
    }
    triggerInvalidate(cmd, dispatch);
  };
  const reset = () => {
    setNewName(forecast.name);
    setNameValid(true);
    setShowTrailingText(false);
  };
  const handleErrorDialogClose = () => {
    setErrorDialogState(null);
    setNewName(forecast.name);
  };
  const handleOnKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      reset();
    }
  };
  const saveEnabled = nameValid
    && forecast.name !== newName
    && !saving
    && !forecastQuery.isFetching;
  const cancelEnabled = forecast.name !== newName
    && !saving
    && !forecastQuery.isFetching;
  const showButtons = newName !== forecast.name;
  const renameText = saving ? 'Saving...' : 'Rename';


  return <s.InputSettingsSection>
    <s.InputLabel>Name</s.InputLabel>
    <s.NameInputSection>
      <div style={ {width: '400px'} }>
        <CustomInput
          value={ newName }
          onChange={ handleNameChange }
          onKeyUp={ handleOnKeyUp }
          disabled={ isLoading }
          error={ !nameValid }
        />
      </div>
      {
        showButtons && <>
          <AppButton
            text={ renameText }
            variant="filledPrimary"
            onClick={ handleRenameClicked }
            disabled={ !saveEnabled }
          />
          <AppButton
            text="Cancel"
            variant="outlinedSecondary"
            onClick={ handleCancelClicked }
            disabled={ !cancelEnabled }
          />
        </>
      }
      {
        showTrailingText &&
        <div style={ {margin: 'auto 0px', fontStyle: 'italic'} }>{ trailingText }</div>
      }
    </s.NameInputSection>
    { (!!errorDialogState) &&
      <ErrorDialog dialogState={ errorDialogState } open={ !!errorDialogState } onClose={ handleErrorDialogClose }/> }
  </s.InputSettingsSection>;
};
export default NameSetting;
