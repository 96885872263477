import styled from 'styled-components';

export const BudgetExplorerContainer = styled.div`
  padding: 10px 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;
export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TitleSectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const TitleSectionRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const TitleButtonsContainer = styled.div`
  height: 40px;
  margin: auto 0;
`;
export const MainArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
`;

export const InnerMainArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

