import styled from 'styled-components';

export const ForecastChartsContainer = styled.div`
  padding: 10px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const TitleSectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const TitleSectionRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const TitleButtonsContainer = styled.div`
  height: 40px;
  margin: auto 0;
`;
export const SummaryCharts = styled.div<{ visible: boolean }>`
  visibility: ${ p => p.visible ? 'visible' : 'hidden' };
  height: ${ p => p.visible ? '130px' : 0 };
  opacity: ${ p => p.visible ? 1 : 0 };
  transition: all 0.5s linear;
`;
export const ChartMiddleRow = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ChartMiddleRowLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const ChartMiddleRowRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const MainChart = styled.div<{ summaryVisible: boolean }>`
  height: ${ p => p.summaryVisible ? 'calc(100vh - 360px)' : 'calc(100vh - 130px)' };
`;
