import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 900px;
  margin: 0 auto;
  gap: 20px;
`;
export const HeaderSection = styled.div`
  flex-direction: row;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
`;
export const HeaderLeft = styled.div`
  flex-direction: row;
  display: flex;
  gap: 15px;
  align-items: center;
`;
export const HeaderRight = styled.div`
  flex-direction: row;
  display: flex;
  gap: 15px;
  align-items: center;
`;
export const Title = styled.div`
  font-size: var(--font-xl);
  font-weight: 300;
  padding: 15px 0px;
  padding-bottom: 20px;
`;
export const RecycleList = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 20px;
`;
export const RecycleListItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;
