import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRoutes from './AppRoutes';
import './index.css';
import Printable from './components/printable/Printable';
import { LicenseInfo } from '@mui/x-license-pro';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

LicenseInfo.setLicenseKey('a36ac36968fd8767882e308f4386b892Tz03MzU0NyxFPTE3MjQ3MDIxNjcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

const loc = window.location;
if (loc.pathname.includes('printable')) {
  root.render(
    <React.StrictMode>
      <Printable/>
    </React.StrictMode>,
  );
} else {
  root.render(
    <React.StrictMode>
      <AppRoutes/>
    </React.StrictMode>,
  );
}
