import {ChartDto, DimensionColumn, DimensionValues} from 'apiSlices/chart-types';
import {ChartFiltersState} from './charts-slice';
import {GridColDef} from '@mui/x-data-grid';

function getMatchingRowSelector(chart: ChartDto, filters: ChartFiltersState): (i: number) => boolean {
  type FilterSelectionInfo = [c: DimensionColumn, dv: DimensionValues, values: string[]];
  const {filterDimensionValues, columnSet} = chart;

  let filterSelections: FilterSelectionInfo[] = [];
  for (let f of filters.filters) {
    // not added === select all
    if (!f.added)
      continue;

    // no selection === select all.
    if (f.values.every(v => !v.enabled))
      continue;

    // all selected === select all
    if (f.values.every(v => v.enabled))
      continue;

    // otherwise, add this filter definition
    let c = columnSet.dimensions[f.dimension.id];
    let dv = filterDimensionValues.find(v => v.id === f.dimension.id);
    if (!c || !dv)
      throw Error(`Missing dimension with id ${ f.dimension.id } in either columnSet dimensions or filterDimensionValues`);

    filterSelections.push([c, dv, f.values.filter(x => x.enabled).map(x => x.name)]);
  }

  if (filterSelections.length === 0)
    return _ => true;

  return i => filterSelections.every(s => {
    let [c, dv, values] = s;
    let columnValue = dv.values[c.valueIndices[i]];
    return !values.every(v => v !== columnValue);
  });
}

export function buildChartData(chart: ChartDto, filters: ChartFiltersState) {
  let {xAxisId, xAxisValues, xAxisOpacity, columnSet, dataSeries} = chart;
  const filterFunction = getMatchingRowSelector(chart, filters);
  let data: { [id: string]: number | string }[] = []; // data structure for chart (id is xAxisId or fillOpacity, or dsId)

  // process x-axis stuff
  for (let i = 0; i < xAxisValues.length; i++) {
    const entry = {[xAxisId]: xAxisValues[i]};
    data.push(entry);
    if (!!xAxisOpacity)
      entry.fillOpacity = xAxisOpacity[i];
  }

  // sum data values into appropriate xAxis index / data series index
  let xColumn = columnSet.dimensions[xAxisId];
  let dataColumns = dataSeries.map(s => columnSet.measures[s.id]);
  let sums = dataSeries.map(_ => Array<number>(xAxisValues.length).fill(0));
  for (let i = 0; i < columnSet.columnLength; i++) {
    if (!filterFunction(i))
      continue;

    let xIndex = xColumn.valueIndices[i];
    dataColumns.forEach((c, j) => sums[j][xIndex] += c.values[i]);
  }

  // map back to the data structure the chart wants (points with dictionary for each point)
  for (let i = 0; i < xAxisValues.length; i++)
    for (let j = 0; j < dataSeries.length; j++)
      data[i][dataSeries[j].id] = sums[j][i];

  return data;
}

interface TableData {
  columns: GridColDef[],
  rows: { [x: string]: number | string }[],
  gridRowId: string,
}

export function buildTableData(chart: ChartDto, chartData: { [id: string]: number | string }[]): TableData {
  const {dataSeries, xAxisId, xAxisLabel} = chart;

  const stdColumn: GridColDef = {type: 'string', field: '', headerClassName: 'table-header', headerAlign: 'left'};
  const columns: GridColDef[] = [
    {
      ...stdColumn,
      field: xAxisId,
      headerName: xAxisLabel,
      flex: 1,
    },
  ];

  const valueColumn: GridColDef = {...stdColumn, align: 'right', width: 150};
  for (let ds of dataSeries)
    columns.push({...valueColumn, field: ds.id, headerName: ds.name});

  return {columns, rows: chartData, gridRowId: xAxisId};
}
