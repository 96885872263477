import {useState} from 'react';

const useConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  return {isOpen, open, handleClose};
};

export default useConfirmDialog;
