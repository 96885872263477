import styled from 'styled-components';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-right: 1px solid lightgray;
  height: calc(100vh - 77px);
  z-index: 900;
  overflow: clip;
  padding: 8px 0px;
  width: 68px;
  min-width: 68px;
`;

