import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import styled from 'styled-components';
import ForecastSidebar from './forecastSidebar/ForecastSidebar';
import ForecastCharts from './forecastCharts/ForecastCharts';
import ForecastSettings from './forecastSettings/ForecastSettings';
import ForecastWelcome from './forecastWelcome/ForecastWelcome';
import ForecastValidation from './forecastValidation/ForecastValidation';
import ForecastDataIndex from './forecastData/ForecastDataIndex';
import ForecastBudgetExplorer from './forecastBudgetExplorer/ForecastBudgetExplorer';
import ForecastTransactions from './forecastTransactions/ForecastTransactions';
import BudgetBuilder from './forecastBudgetBuilder/BudgetBuilder';
import ForecastReports from './forecastReports/ForecastReports';
import {useAppSelector} from 'app/hooks';
import Spinner from 'components/Spinner';

export function Forecast() {

  const forecastTenantId = useAppSelector(s => s.ui.activeForecast?.tenantId);
  const activeTenantId = useAppSelector(s => s.ui.activeTenantId);
  const forecastLoading = useAppSelector(s => s.ui.activeForecast === undefined);

  if (forecastLoading)
    return <Spinner/>;

  if (activeTenantId !== forecastTenantId)
    return <>Invalid Forecast / Tenant ID</>;

  return <div>
    <ForecastContainer>
      <Routes>
        <Route index element={ <Navigate to="welcome" replace={ true }/> }/>
        <Route path="*" element={ <ForecastSidebar/> }/>
      </Routes>
      <ForecastMainViewContainer>
        <ForecastMarginContainer>
          <Routes>
            <Route path="welcome" element={ <ForecastWelcome/> }/>
            <Route path="charts/:scenarioId" element={ <ForecastCharts/> }/>
            <Route path="budget-explorer/:scenarioId" element={ <ForecastBudgetExplorer/> }/>
            { false && <Route path="transactions" element={ <ForecastTransactions/> }/> }
            <Route path="budget-builder/:scenarioId" element={ <BudgetBuilder/> }/>
            <Route path="reports/:scenarioId" element={ <ForecastReports/> }/>
            <Route path="settings" element={ <ForecastSettings/> }/>
            <Route path="validation" element={ <ForecastValidation/> }/>
            <Route path="data/*" element={ <ForecastDataIndex/> }/>
          </Routes>
        </ForecastMarginContainer>
      </ForecastMainViewContainer>
    </ForecastContainer>
  </div>;
}

const ForecastContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 78px);
`;

const ForecastMainViewContainer = styled.div`
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - 78px);
  height: calc(100vh - 78px);
`;

const ForecastMarginContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  min-width: 1100px;
  width: 100%;
  height: calc(100vh - 78px);
`;

export default Forecast;
