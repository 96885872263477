import {Adjustment} from 'components/forecast/forecastBudgetBuilder/budget-builder-types';
import {formatPercent, formatValue} from 'lib/value-formatter';
import {GridValueFormatterParams} from '@mui/x-data-grid-premium';
import {AdjustmentType} from 'apiSlices/api-types';

export const valueFormatter = (params: GridValueFormatterParams<number>) => {
  if (params.id === '_all')
    return '';
  if (params.value == null) {
    return '0';
  }
  return formatValue(params.value, 2);
};
export const accountFormatter = (params: GridValueFormatterParams<string | undefined>) => {
  if (params.id === '_all')
    return '(all)';
  return params.value ?? '';
};
export const formatAdjustment = (adjustment: Adjustment) => {
  switch (adjustment.type) {
    case AdjustmentType.Blank:
      return '(error)';
    case AdjustmentType.Zero:
      return '(zero)';
    case AdjustmentType.DollarAmount:
      return 'change to ' + formatValue(adjustment.value ?? 0, 2);
    case AdjustmentType.DollarChange:
      return 'change by ' + formatValue(adjustment.value ?? 0, 2);
    case AdjustmentType.PercentChange:
      return 'change by ' + formatPercent(adjustment.value ?? 0);
    case AdjustmentType.TempDollarAmount:
      return 'temp change to ' + formatValue(adjustment.value ?? 0, 2);
    case AdjustmentType.TempDollarChange:
      return 'temp change by ' + formatValue(adjustment.value ?? 0, 2);
    case AdjustmentType.TempPercentChange:
      return 'temp change by ' + formatPercent(adjustment.value ?? 0);
    case AdjustmentType.BaseDollarChange:
      return 'change (base) by ' + formatValue(adjustment.value ?? 0, 2);
    case AdjustmentType.BasePercentChange:
      return 'change (base) by ' + formatPercent(adjustment.value ?? 0);
    default:
      return '(error)';
  }
};