import styled from 'styled-components';

export const ValidationContainer = styled.div`
  padding: 10px 30px;
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ValidationSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const ValidationBlurb = styled.div`
`;
export const ValidationMessageList = styled.div`
  display: flex;
  flex-direction: column;
  gap:0px;
  border: 0.5px lightgray solid;
  border-bottom: 0;
`;
export const ValidationMessage = styled.div`
  font-size: 10pt;
  display: flex;
  flex-direction: row;
  gap: 5px;
  border-bottom: 0.5px lightgray solid;
`;

export const ErrorMessageIcon = styled.div`
  color: var(--color-theme-error);
  padding: 0 3px;
`;
export const WarningMessageIcon = styled.div`
  color: var(--color-theme-warning);
  padding: 0 3px;
`;
export const InfoMessageIcon = styled.div`
  color: var(--color-secondary);
  padding: 0 3px;
`;

export const ErrorLabel = styled.div`
  color: var(--color-theme-error);
  width: 70px;
  min-width: 70px;
  padding: 3px;
`;
export const WarningLabel = styled.div`
  color: var(--color-theme-warning);
  width: 70px;
  min-width: 70px;
  padding: 3px;
`;
export const InfoLabel = styled.div`
  color: var(--color-theme-info);
  width: 70px;
  min-width: 70px;
  padding: 3px;
`;

export const ValidationMessageText = styled.div`
  width: 200px;
  min-width: 200px;
  padding: 3px;
`;
export const ValidationMessageDetail = styled.div`
  padding: 3px;
`;
