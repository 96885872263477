import React, {useState} from 'react';
import {Menu, MenuItem} from '@mui/material';
import AppButton from 'components/appButton/AppButton';
import * as icons from 'style/Icons';
import ConfirmDialog from 'components/confirmDialog/ConfirmDialog';
import useConfirmDialog from 'components/confirmDialog/ConfirmDialogHook';
import {
  AddStepApiCommand,
  DeleteStepApiCommand,
  RenameStepApiCommand,
  ReorderStepApiCommand,
} from 'apiCommands/api-commands';
import {usePostCommand, usePostIdCommand} from 'lib/command-client';
import * as s from './steps-list-styles';
import MoveStepDialog from './MoveStepDialog';
import {useAppSelector} from 'app/hooks';
import {Step} from 'apiSlices/api-types';
import {SingleInputDialog} from 'components/SingleInputDialog';
import {validateName} from 'lib/validators';

export interface StepsListProps {
  step: string | undefined,
  setStep: (step: string | undefined) => void,
}

export const StepsList = (props: StepsListProps) => {
  const postCommand = usePostCommand();
  const postIdCommand = usePostIdCommand();
  const forecastId = useAppSelector(s => s.ui.activeForecastId);
  const activeForecast = useAppSelector(s => s.ui.activeForecast);

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const showMenu = Boolean(anchorEl);

  const [showMoveStepDialog, setShowMoveStepDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showRenameDialog, setShowRenameDialog] = useState(false);

  const confirmDeleteDialog = useConfirmDialog();
  const [menuStep, setMenuStep] = useState<Step | undefined>(undefined);

  const scenarioModel = useAppSelector(s => s.ui.activeScenario);

  const handleSelectStep = (step: Step) => (_: React.MouseEvent<HTMLElement>) => {
    props.setStep(step.id);
    setAnchorEl(undefined);
  };

  const handleStepMoreClick = (step: Step) => (e: React.MouseEvent<HTMLElement>) => {
    setMenuStep(step);
    setAnchorEl(e.currentTarget);
  };
  const handleStepMenuClose = () => {
    setAnchorEl(undefined);
    setMenuStep(undefined);
  };
  const handleAddStepClicked = () => {
    if (!activeForecast)
      return;
    setAnchorEl(undefined);
    setShowAddDialog(true);
  };

  function handleRenameStepClicked() {
    if (!activeForecast)
      return;
    setAnchorEl(undefined);
    setShowRenameDialog(true);
  }

  const handleDeleteStepClicked = () => {
    if (!activeForecast)
      return;
    setAnchorEl(undefined);
    confirmDeleteDialog.open();
  };
  const handleMoveStepClicked = () => {
    if (!activeForecast)
      return;
    setAnchorEl(undefined);
    setShowMoveStepDialog(true);
  };
  const handleMoveToEnd = async () => {
    if (!scenarioModel || !menuStep || !forecastId)
      return;
    setAnchorEl(undefined);
    const cmd: ReorderStepApiCommand = {
      type: 'ReorderStepApiCommand',
      forecastId: forecastId,
      scenarioId: scenarioModel.id,
      stepId: menuStep.id,
      order: -1,
    };
    await postCommand(cmd);
  };
  const handleMoveToBeginning = async () => {
    if (!forecastId || !scenarioModel || !menuStep)
      return;
    setAnchorEl(undefined);
    const cmd: ReorderStepApiCommand = {
      type: 'ReorderStepApiCommand',
      forecastId: forecastId,
      scenarioId: scenarioModel.id,
      stepId: menuStep.id,
      order: 0,
    };
    await postCommand(cmd);
  };
  const handleAddStepClosed = (id: string | undefined) => {
    setShowAddDialog(false);
    if (id) props.setStep(id);
  };
  const handleMoveStepClosed = () => {
    setShowMoveStepDialog(false);
  };

  function handleRenameStepClosed() {
    setShowRenameDialog(false);
  }

  const deleteStepConfirmAction = async () => {
    if (!forecastId || !scenarioModel || !menuStep)
      return;
    const cmd: DeleteStepApiCommand = {
      type: 'DeleteStepApiCommand',
      scenarioId: scenarioModel.id,
      forecastId: forecastId,
      stepId: menuStep.id,
    };
    await postCommand(cmd);
    props.setStep(steps.filter(x => x.id !== menuStep.id)[0].id);
  };

  if (!forecastId || !scenarioModel)
    return <></>;

  const steps = scenarioModel.steps;

  const moreThanOneStep = steps.length > 1;

  if (!props.step && steps.length > 0)
    setTimeout(() => props.setStep(steps[0].id), 0);

  function buildAddStepCommand(stepName: string): AddStepApiCommand | undefined {
    if (!forecastId || !scenarioModel?.id)
      return undefined;
    return {
      type: 'AddStepApiCommand',
      forecastId: forecastId,
      scenarioId: scenarioModel.id,
      name: stepName,
    };
  }

  function buildRenameStepCommand(newName: string): RenameStepApiCommand | undefined {
    if (!forecastId || !scenarioModel?.id || !menuStep?.id)
      return undefined;
    return {
      type: 'RenameStepApiCommand',
      forecastId: forecastId,
      scenarioId: scenarioModel.id,
      stepId: menuStep.id,
      name: newName,
    };
  }

  return <s.StepListContainer>
    <s.TitleRow>
      <s.TitleRowLeft>
        <s.Title>
          Steps
        </s.Title>
      </s.TitleRowLeft>
      <s.TitleRowRight>
        <AppButton
          icon={ <icons.Add/> }
          onClick={ handleAddStepClicked }
          size="small"
        />
      </s.TitleRowRight>
    </s.TitleRow>

    <s.StepsContainer>
      {
        steps.map((step, n) => <s.Step key={ n } active={ step.id === props.step }>
            <s.StepLeft>
              <AppButton
                text={ step.name }
                onClick={ handleSelectStep(step) }
              />
            </s.StepLeft>
            <s.StepRight>
              <AppButton
                icon={ <icons.MoreHoriz/> }
                onClick={ handleStepMoreClick(step) }
                size="small"
              />
            </s.StepRight>
          </s.Step>,
        )
      }
      <Menu
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ showMenu }
        onClose={ handleStepMenuClose }
        MenuListProps={ {
          'aria-labelledby': 'basic-appButton',
        } }
      >
        <MenuItem key={ '1' } value={ '1' } onClick={ handleDeleteStepClicked }
                  disabled={ !moreThanOneStep }>Delete</MenuItem>
        <MenuItem key={ '2' } value={ '2' } onClick={ handleMoveStepClicked }
                  disabled={ !moreThanOneStep }>Move</MenuItem>
        <MenuItem key={ '3' } value={ '3' } onClick={ handleMoveToBeginning } disabled={ !moreThanOneStep }>Move to
          Beginning</MenuItem>
        <MenuItem key={ '4' } value={ '4' } onClick={ handleMoveToEnd } disabled={ !moreThanOneStep }>Move to
          End</MenuItem>
        <MenuItem key={ '5' } value={ '5' } onClick={ handleRenameStepClicked }>Rename...</MenuItem>
      </Menu>
    </s.StepsContainer>
    {
      showAddDialog &&
      <SingleInputDialog<AddStepApiCommand> open={ showAddDialog }
                                            onClose={ handleAddStepClosed }
                                            buildCommand={ buildAddStepCommand }
                                            postCommand={ postIdCommand }
                                            title="Add Step"
                                            buttonText="Add"
                                            textInputLabel="Step Name"
                                            validateName={ validateName }
      />
    }
    {
      showRenameDialog &&
      <SingleInputDialog<RenameStepApiCommand> open={ showRenameDialog }
                                               onClose={ handleRenameStepClosed }
                                               buildCommand={ buildRenameStepCommand }
                                               postCommand={ postCommand }
                                               title="Rename Step"
                                               buttonText="Rename"
                                               textInputLabel="Rename Step"
                                               validateName={ validateName }/>
    }
    {
      showMoveStepDialog &&
      <MoveStepDialog forecastId={ forecastId } open={ showMoveStepDialog }
                      onClose={ handleMoveStepClosed } scenarioId={ scenarioModel.id }
                      stepId={ menuStep?.id ?? '' } steps={ steps.filter(x => x.id !== menuStep?.id) }/>
    }
    {
      confirmDeleteDialog.isOpen &&
      <ConfirmDialog
        open={ confirmDeleteDialog.isOpen }
        confirmState={ {
          confirmTitle: 'DELETE',
          title: 'Confirm Delete',
          message: 'Are you sure you want to PERMANENTLY delete step ' + menuStep?.name + '?',
        } }
        onConfirm={ confirmDeleteDialog.handleClose }
        onClose={ confirmDeleteDialog.handleClose }
        confirmAction={ deleteStepConfirmAction }
        confirmIcon={ <icons.Trash/> }
      />
    }
  </s.StepListContainer>;
};

export default StepsList;

