import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DataHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DataHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
