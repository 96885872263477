import {createSelector, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from 'store';
import {DateTime} from 'luxon';
import {Forecast, ForecastStatusFetched, ForecastValidationMessage, Scenario, Tenant} from 'apiSlices/api-types';

interface UiState {
  activeTenantId: string | undefined,
  activeForecastId: string | undefined,
  activeScenarioId: string | undefined,
  activeTenant: Tenant | undefined,
  activeForecast: Forecast | undefined,
  activeScenario: Scenario | undefined,
  allTenants: Tenant[] | undefined,
  activeTenantInvalid: boolean,
  activeForecastStatus: ForecastStatusFetched | undefined,
  validationState: ValidationState | undefined,
  activeForecastInvalid: boolean,
}

const initialState: UiState = {
  activeTenantId: undefined,
  activeForecastId: undefined,
  activeScenarioId: undefined,
  activeTenant: undefined,
  activeForecast: undefined,
  activeScenario: undefined,
  allTenants: undefined,
  activeTenantInvalid: false,
  activeForecastStatus: undefined,

  validationState: undefined,
  activeForecastInvalid: false,
};

interface ValidationState {
  errorMessages: ForecastValidationMessage[],
  warningMessages: ForecastValidationMessage[],
  infoMessages: ForecastValidationMessage[],
  lastValidationId: string,
  lastValidationUnixTime: undefined | number,
}

interface ViewingForecast {
  tenantId: string,
  forecastId: string,
}

interface ViewingScenario {
  tenantId: string,
  forecastId: string,
  scenarioId: string,
}

function setTenant(state: Draft<UiState>, tenantId: string | undefined): void {
  state.activeTenantId = tenantId;
  if (state.activeTenantId !== undefined) {
    if (state.allTenants !== undefined) {
      state.activeTenant = state.allTenants.find(t => t.id === state.activeTenantId);
      state.activeTenantInvalid = state.activeTenant === undefined;
    } else {
      state.activeTenant = undefined;
      state.activeTenantInvalid = false;
    }
  } else {
    state.activeTenant = undefined;
    state.activeTenantInvalid = false;
  }
}

function setForecast(state: Draft<UiState>, forecastId: string | undefined): void {
  if (forecastId === state.activeForecastId)
    return;
  state.activeForecastId = forecastId;
  state.activeForecast = undefined;
  state.activeForecastStatus = undefined;
  state.validationState = undefined;
  state.activeScenarioId = undefined;
  state.activeScenario = undefined;
  state.activeForecastInvalid = false;
}

function setScenario(state: Draft<UiState>, scenarioId: string | undefined): void {
  state.activeScenarioId = scenarioId;
  state.activeScenario = state.activeForecast?.scenarios.find(s => s.id === scenarioId);
}

function mapForecastStatusToValidationState(forecastStatus: ForecastStatusFetched) {
  const lastValidationTime = forecastStatus.lastValidationUnixTime;
  return {
    lastValidationId: forecastStatus.lastValidationId,
    lastValidationUnixTime: lastValidationTime < 0 ? undefined : lastValidationTime,
    errorMessages: forecastStatus.validationMessages.filter(m => m.level === 'Error'),
    warningMessages: forecastStatus.validationMessages.filter(m => m.level === 'Warning'),
    infoMessages: forecastStatus.validationMessages.filter(m => m.level === 'Info'),
  };
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    tenantsFetched: (state, action: PayloadAction<Tenant[]>) => {
      state.allTenants = action.payload;
      setTenant(state, state.activeTenantId);
    },
    notViewingTenant: (state) => {
      setTenant(state, undefined);
      setForecast(state, undefined);
    },
    viewingTenant: (state, action: PayloadAction<string>) => {
      setTenant(state, action.payload);
      setForecast(state, undefined);
    },
    viewingForecast: (state, action: PayloadAction<ViewingForecast>) => {
      setTenant(state, action.payload.tenantId);
      setForecast(state, action.payload.forecastId);
    },
    viewingScenario: (state, action: PayloadAction<ViewingScenario>) => {
      setTenant(state, action.payload.tenantId);
      setForecast(state, action.payload.forecastId);
      setScenario(state, action.payload.scenarioId);
    },
    forecastFetched: (state, action: PayloadAction<Forecast>) => {
      const forecast = action.payload;
      if (forecast && forecast.id === state.activeForecastId) {
        state.activeForecast = forecast;
        const activeScenario = forecast.scenarios.find(x => x.id === state.activeScenarioId);
        if (activeScenario)
          setScenario(state, activeScenario.id);
        else if (forecast.scenarios?.length > 0)
          setScenario(state, forecast.scenarios[0].id);
        else
          setScenario(state, undefined);
        state.activeForecastInvalid = false;
      }
    },
    forecastStatusFetched: (state, action: PayloadAction<ForecastStatusFetched>) => {
      let forecastStatus = action.payload;
      if (forecastStatus && forecastStatus.forecastId === state.activeForecastId) {
        state.activeForecastStatus = forecastStatus;
        if (state.validationState?.lastValidationId !== forecastStatus.lastValidationId) {
          state.validationState = mapForecastStatusToValidationState(forecastStatus);
        }
      }
    },
    forecastInvalid: (state, action: PayloadAction<string>) => {
      if (state.activeForecastId === action.payload)
        state.activeForecastInvalid = true;
    },
  },
});

export const {
  tenantsFetched,
  notViewingTenant,
  viewingTenant,
  viewingForecast,
  viewingScenario,
  forecastFetched,
  forecastStatusFetched,
  forecastInvalid,
} = uiSlice.actions;

export const selectIsInvalidTenant = (state: RootState) =>
  state.ui.activeTenantId !== undefined
  && state.ui.allTenants !== undefined
  && !state.ui.allTenants.find(t => t.id === state.ui.activeTenantId);

export const selectForecastName = (state: RootState) => state.ui.activeForecast?.name;
export const selectLastValidationTime = (state: RootState) => {
  const lastValidationTime = state.ui.validationState?.lastValidationUnixTime;
  if (!lastValidationTime || lastValidationTime <= 0)
    return undefined;
  const date = DateTime.fromSeconds(lastValidationTime);
  return date.toISO();
};

export const selectCalculationInProgress = (state: RootState) => state.ui.activeForecastStatus?.lastCalculationState !== 'Complete';
export const selectValidationErrors = (state: RootState) => state.ui.validationState?.errorMessages ?? [];
export const selectValidationWarnings = (state: RootState) => state.ui.validationState?.warningMessages ?? [];
export const selectValidationInfo = (state: RootState) => state.ui.validationState?.infoMessages ?? [];
export const selectLatestCalcId = (state: RootState) => state.ui.activeForecastStatus?.lastCalculationId;

export const selectErrorCount = createSelector(selectValidationErrors, errors => errors.length);
export const selectWarningCount = createSelector(selectValidationWarnings, warnings => warnings.length);

export const selectScenarioList = createSelector((s: RootState) => s.ui.activeForecast?.scenarios?.map(s => s.id) ?? [], s => s);
export const selectScenarioName = (id: string | undefined) => (state: RootState) => state.ui.activeForecast?.scenarios?.find(s => s.id === id)?.name ?? '(not found)';

export const selectActiveScenario = (state: RootState) => state.ui.activeScenario;

export default uiSlice.reducer;
