import _ from 'lodash';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

export const uuid = () => uuidv4();

export const renderIf = (test, component) => (test ? component : undefined);

export const isPopulated = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return true;
  }
  return false;
};

export const classToggler = options =>
  Object.keys(options)
    .filter(key => !!options[key])
    .join(' ');

export const log = (...args) =>
  window.env.NODE_ENV ? console.log(...args) : undefined;

export const logError = (...args) =>
  window.env.NODE_ENV ? console.error(...args) : undefined;

export const numberLength = (number, size) =>
  (`${ number ? number.toString() : '0.00' }`.length - 1) * (size ? size : 12);

export const sortAlphabeticallyByNumeric = (array, field) => {
  return array.sort((a, b) => {
    const aName = a[field];
    const bName = b[field]
    return aName < bName ? -1 : aName > bName ? 1 : 0;
  });
};

export const sortAlphabeticallyByField = (array, field) => {
  return array.sort((a, b) => {
    const aName = a[field]
        .split(' ')
        .join('')
        .toLowerCase(),
      bName = b[field]
        .split(' ')
        .join('')
        .toLowerCase();
    return aName < bName ? -1 : aName > bName ? 1 : 0;
  });
};

export const arrayReorder = (arr, old_index, new_index) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const isObject = value => {
  return value && typeof value === 'object' && value.constructor === Object;
};

export const isNumber = value => +value === +value; // eslint-disable-line

export default class Logger {
  constructor() {
    this.logVerbose = true;
    this.verbose = this.verbose.bind(this);
    this.debug = this.debug.bind(this);
    this.warn = this.warn.bind(this);
    this.error = this.error.bind(this);
  }

  verbose = (...args) =>
    this.logVerbose && window.env.NODE_ENV ? console.log(...args) : undefined;
  debug = (...args) => (window.env.NODE_ENV ? console.log(...args) : undefined);
  warn = (...args) => console.error(...args);
  error = (...args) => console.error(...args);
}

export const fillRight = array => {
  if (array[0] !== 0 && !array[0]) {
    return array;
  } else {
    return array.map(i => (array[i] = array[0]));
  }
};

export const delay = delayMs => new Promise(resolve => setTimeout(resolve, delayMs));

export const arraysAreEqualShallow = (x, y) => {
  if (x.length !== y.length) {
    return false;
  }
  return _.isEqual(x, y);
}

export const arraysAreEqualDeep = (x, y) => {
  if (x.length !== y.length) {
    return false;
  }
  return _(x).differenceWith(y, _.isEqual).isEmpty();
}

export const arrayContains = (arr, val) => {
  return arr.indexOf(val) !== -1;
}

export const convertErrorToTitle = (str) => {
  return str.replaceAll('_', ' ').replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
};

export const formatUnixTimeSeconds = (input) => {
  const date = DateTime.fromSeconds(input);
  return date.toLocaleString(DateTime.DATETIME_SHORT);
};

export const formatUnixTimeAgo = (input) => {
  const date = DateTime.fromSeconds(input);
  return date.toRelative();
};

