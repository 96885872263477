import {ChartDto, Metric} from 'apiSlices/chart-types';
import {ChartFiltersState, ChartState} from 'storeSlices/charts-slice';

export interface ForecastChartsInput {
  chartsAvailable: boolean,
  mainChart: ChartInput,
  allCharts?: ChartDto[],
  metrics?: Metric[],
  mainChartId?: string,
  mainChartType?: string,
  mainChartFilters: ChartFiltersState,
  isPrintView: boolean,
  printInfo: PrintInfo,
  isCalcPending: boolean,
}

export interface ChartInput {
  chart?: ChartDto,
  data?: any,
  tableData?: any,
  isPrintView: boolean,
  axisFontSize: number,
}

export interface PrintInfo {
  forecastName?: string,
  tenantName?: string,
}

export function buildChartInput(charts: ChartState, forecastName: string, tenantName: string): ForecastChartsInput {
  return {
    metrics: charts.mainChartMetrics,
    mainChartId: charts.mainChartId,
    mainChartType: charts.mainChartType,
    chartsAvailable: charts.chartsAvailable,
    mainChartFilters: charts.mainChartFilters,
    mainChart: {
      chart: charts.mainChart,
      data: charts.mainChartData,
      tableData: charts.mainTableData,
      isPrintView: false,
      axisFontSize: 12,
    },
    isPrintView: false,
    isCalcPending: charts.mainChart?.calcNeeded ?? true,
    printInfo: {
      forecastName: forecastName,
      tenantName: tenantName,
    },
  };
}
