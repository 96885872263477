import {colors} from './colors';
import {
  Add as AddIcon,
  AdminPanelSettingsOutlined,
  Apartment,
  AssignmentLateOutlined,
  AssignmentTurnedInOutlined,
  BarChart as MuiBarChart,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  ClearOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ContentCopy,
  DeleteOutline as DeleteIcon,
  DescriptionOutlined,
  DownloadOutlined,
  DriveFileRenameOutline,
  ErrorOutline,
  ExploreOutlined,
  FileOpenOutlined as FileOpenIcon,
  FilterAltOutlined,
  GridOnOutlined,
  HelpOutlineOutlined,
  HomeOutlined,
  InfoOutlined,
  InsertDriveFileOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyOutlined,
  LogoutOutlined,
  MoreHoriz as MoreHorizIcon,
  PictureAsPdfOutlined,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
  ReceiptLong,
  RefreshOutlined,
  SettingsApplicationsOutlined,
  SsidChart,
  StackedBarChartOutlined,
  TuneOutlined,
  WarningAmberOutlined,
  WidgetsOutlined,
} from '@mui/icons-material';

export const Add = AddIcon;
export const Copy = ContentCopy;
export const FileOpen = FileOpenIcon;
export const MoreHoriz = MoreHorizIcon;
export const Upload = CloudUploadOutlined;
export const CloudDownload = CloudDownloadOutlined;
export const Download = DownloadOutlined;
export const Rename = DriveFileRenameOutline;
export const Building = Apartment;
export const Trash = DeleteIcon;
export const Home = HomeOutlined;
export const Logout = LogoutOutlined;
export const Key = KeyOutlined;
export const Admin = AdminPanelSettingsOutlined;
export const Info = InfoOutlined;
export const Chart = StackedBarChartOutlined;
export const StackedBarChart = StackedBarChartOutlined;
export const Gear = SettingsApplicationsOutlined;
export const Setting = TuneOutlined;
export const Explore = ExploreOutlined;
export const Build = WidgetsOutlined;
export const Report = DescriptionOutlined;
export const Help = HelpOutlineOutlined;
export const Tasks = AssignmentLateOutlined;
export const CompletedTask = AssignmentTurnedInOutlined;
export const Receipt = ReceiptLong;
export const File = InsertDriveFileOutlined;
export const Error = ErrorOutline;
export const Warning = WarningAmberOutlined;
export const DownChevron = KeyboardArrowDown;
export const UpChevron = KeyboardArrowUp;
export const BarChart = MuiBarChart;
export const LineChart = SsidChart;
export const Filter = FilterAltOutlined;
export const X = ClearOutlined;
export const Checked = CheckBoxOutlined;
export const Unchecked = CheckBoxOutlineBlank;
export const SingleChecked = RadioButtonCheckedOutlined;
export const SingleUnchecked = RadioButtonUncheckedOutlined;
export const Pdf = PictureAsPdfOutlined;
export const Table = GridOnOutlined;
export const Refresh = RefreshOutlined;

export const Area = (props: { selected?: boolean }) => <svg
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  viewBox="0 0 150 150"
  style={ {height: 23, width: 22, fill: props.selected ? colors.themePrimary : colors.secondary} }
>
  <path className="st0"
        d="M111.5,111.5H16.5V86.2l22,9.7l29.2-36.5h33.8l10-7.8V111.5z M128,0L94.2,32H58.9L33.3,64L0,48v80h128V0L128,0z"
  />
</svg>;