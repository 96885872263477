import React from 'react';
import styled from 'styled-components';
import AppLineChart from './charts/AppLineChart';
import {ForecastChartsInput} from './chart-input-selector';
import AppBarChart from './charts/AppBarChart';
import AppStackedBarChart from './charts/AppStackedBarChart';
import AppStackedAreaChart from './charts/AppStackedAreaChart';
import AppVerticalBarChart from './charts/AppVerticalBarChart';
import AppTableChart from './charts/AppTableChart';

export function MainChart(props: { input: ForecastChartsInput }) {
  const chartType = props.input.mainChartType;
  const Chart = () => {
    switch (chartType) {
      case 'line':
        return <AppLineChart input={ props.input.mainChart }/>;
      case 'bar':
        return <AppBarChart input={ props.input.mainChart }/>;
      case 'stacked-bar':
        return <AppStackedBarChart input={ props.input.mainChart }/>;
      case 'stacked-area':
        return <AppStackedAreaChart input={ props.input.mainChart }/>;
      case 'vertical-bar':
        return <AppVerticalBarChart input={ props.input.mainChart }/>;
      case 'table':
        return <AppTableChart input={ props.input.mainChart }/>;
      default:
        return <></>;
    }
  };

  if (props.input.mainChart.isPrintView) {
    return <div id="chart-container" style={ {width: '1200px', height: '500px'} }>
      <Chart/>
    </div>;
  }

  return <ChartContainer id="chart-container">
    <Chart/>
  </ChartContainer>;
}

export default MainChart;

const ChartContainer = styled.div`
  display: flex;
  min-height: 200px;
  height: 100%;
`;
