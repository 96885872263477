interface Config {
  baseUrl: string;
  iumBaseUrl: string;
  homeUrl: string;
}

const readConfig = (): Config => {
  const env = (window as any).env;
  return {
    baseUrl: env.baseUrl as string,
    iumBaseUrl: env.iumBaseUrl as string,
    homeUrl: 'https://home.apps.govinvest.com',
  };
};
const config = readConfig();
export default config;