import React, {useEffect} from 'react';
import Button from 'components/appButton/AppButton';
import {getTenantForecastsRoute} from 'lib/route-builder';
import {fontSizes} from 'style/vars';
import InlineSpinner from 'components/InlineSpinner';
import {useAppSelector} from 'app/hooks';
import {forecastFetched, forecastInvalid, forecastStatusFetched} from 'storeSlices/ui-slice';
import {useGetForecastQuery, useGetForecastStatusQuery} from 'apiSlices/forecasting-api-slice';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

export function ForecastsButton(props: { style: React.CSSProperties }) {
  const dispatch = useDispatch();
  const params = useParams();

  const tenantId = params.tenantId;
  const forecastId = params.forecastId;

  let getForecastQuery = useGetForecastQuery(forecastId || '', {skip: forecastId === undefined});
  let getForecastStatusQuery = useGetForecastStatusQuery(forecastId || '', {
    skip: forecastId === undefined,
    pollingInterval: 15000,
  });

  const forecastName = useAppSelector(s => s.ui.activeForecast?.name);
  const forecastNameLoading = getForecastQuery.isLoading;

  const activeForecastId = useAppSelector(s => s.ui.activeForecastId);

  useEffect(() => {
    if (getForecastQuery.isSuccess)
      dispatch(forecastFetched(getForecastQuery.data || {}));
    else if (getForecastQuery.isError)
      dispatch(forecastInvalid(forecastId || ''));
  }, [
    dispatch, getForecastQuery.isSuccess, getForecastQuery.isError, getForecastQuery.data, forecastId, activeForecastId,
  ]);

  useEffect(() => {
    if (getForecastStatusQuery.isSuccess)
      dispatch(forecastStatusFetched(getForecastStatusQuery.data || {}));
  }, [
    dispatch, getForecastStatusQuery.isSuccess, getForecastStatusQuery.data, getForecastStatusQuery.fulfilledTimeStamp,
    forecastId, activeForecastId,
  ]);

  return <>
    {
      forecastId && tenantId &&
      <div style={ {...props.style, paddingTop: '3px'} }>
        <Button
          linkTo={ getTenantForecastsRoute(tenantId) }
          text="Forecasts"
        />
      </div>
    }
    {
      forecastName &&
      <div style={ {
        ...props.style,
        paddingLeft: '15px',
        paddingTop: '5px',
        fontSize: fontSizes.s,
        fontStyle: 'italic',
      } }>
        { forecastName }
      </div>
    }
    {
      forecastNameLoading &&
      <InlineSpinner/>
    }
  </>;
}